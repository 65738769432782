import { cn } from "@/lib/utils";
import { CustomModel } from "@/types/custom-model.ts";
import { Button } from "@components/ui/button.tsx";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@components/ui/card.tsx";
import OvalLoader from "@components/ui/oval-loader.tsx";
import CustomModelSvgInjector from "@custom-model-editor/components/custom-model-svg-injector.tsx";
import React from "react";
import { useTranslation } from "react-i18next";

type SavedCustomizationCardProps = React.ComponentPropsWithoutRef<"div"> & {
  customModel: CustomModel;
  onResume: () => void;
  onCancel: () => void;
};

export default function SavedCustomizationCard({
  className,
  customModel,
  onResume,
  onCancel,
  ...props
}: SavedCustomizationCardProps) {
  const { t } = useTranslation("saved-customization-card");

  return (
    <Card
      className={cn(
        "flex flex-col gap-2 lg:gap-6",
        "max-h-[90dvh] w-[90%] lg:w-[680px]",
        className,
      )}
      {...props}
    >
      <CardHeader>
        <CardTitle>{t("title")}</CardTitle>
      </CardHeader>

      <CardContent className="h-[calc(100dvh_-_100px)] max-h-[400px] overflow-hidden pb-0">
        <CustomModelSvgInjector
          customModel={customModel}
          interactive={false}
          Loader={() => (
            <div className="grid h-full w-full place-items-center">
              <OvalLoader width={100} height={100} />
            </div>
          )}
        />
      </CardContent>

      <CardFooter className="justify-end gap-2">
        <Button
          variant="outline"
          className="border-2 border-primary text-primary hover:text-primary"
          onClick={onCancel}
        >
          {t("new-configuration-btn")}
        </Button>
        <Button variant="primary" onClick={onResume}>
          {t("continue-btn")}
        </Button>
      </CardFooter>
    </Card>
  );
}
