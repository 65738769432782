import SidebarLogo from "@components/layout/sidebar-logo.tsx";
import { cn } from "@lib/utils.ts";
import React from "react";

type SidebarProps = React.ComponentPropsWithoutRef<"div">;

export default function Sidebar({
  className,
  children,
  ...props
}: SidebarProps) {
  return (
    <div
      className={cn(
        "fixed bottom-0 left-0 top-0 lg:bottom-3 lg:left-3 lg:top-3 xl:bottom-6 xl:left-6 xl:top-6",
        "flex flex-col",
        "w-[225px] lg:w-[250px] xl:w-[300px]",
        "bg-[#323232] shadow-[0_0_15px_0px_#121212] lg:rounded-lg",
        "z-20",
        className,
      )}
      {...props}
    >
      {/* header */}
      <div className="hidden border-[#626262] px-3 pt-2 lg:block xl:pt-4">
        <SidebarLogo />
        <hr className="mb-0 mt-2 border-[1px] xl:mt-4" />
      </div>
      {/* content */}
      <div className="flex h-full flex-col overflow-hidden px-1 pt-2 lg:p-2">
        {children}
      </div>
    </div>
  );
}
