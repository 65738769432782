import {
  ColorElementUpdate,
  ColorLayerUpdate,
} from "@/types/color-elements.ts";
import {
  CustomElement,
  CustomElementUpdate,
  CustomNumbers,
  CustomNumbersUpdate,
  CustomText,
  CustomTextUpdate,
  Logo,
} from "@/types/custom-element.ts";
import { StepBarItem } from "@/types/custom-model-editor.ts";
import { CustomModel, CustomModelUpdate } from "@/types/custom-model.ts";
import {
  ColorLayerConfigUpdate,
  LocalizedNotice,
  ModelConfig,
} from "@/types/model.ts";
import { CustomModelEditorState } from "@custom-model-editor/custom-model-editor-context.tsx";
import {
  addCustomNumbers,
  addCustomText,
  addLogo,
  buildStepBar,
  duplicateElement,
  updateColorElement,
  updateColorLayerConfig,
  updateCustomNumbers,
  updateCustomText,
  updateElement,
  updateModelConfig,
} from "@custom-model-editor/lib/custom-model-editor.ts";
import {
  applyCustomizationToSvg,
  findColorElementById,
} from "@custom-model-editor/lib/custom-model.ts";
import {
  drawSelectionBox,
  removeElement,
  removeSelectionBox,
} from "@custom-model-editor/lib/svg.ts";
import { api } from "@lib/api.ts";
import { localStorageService } from "@lib/local-storage.ts";

export type CustomModelEditorAction =
  | { type: "set_custom_model"; payload: CustomModel }
  | { type: "update_custom_model"; payload: CustomModelUpdate }
  | { type: "set_step_bar_items"; payload: StepBarItem[] }
  | { type: "set_current_step_bar_item"; payload: StepBarItem | undefined }
  | { type: "select_element"; payload: string | undefined }
  | { type: "select_color_element"; payload: string | undefined }
  | {
      type: "update_color_element";
      payload: {
        colorElementId: string;
        updates: ColorElementUpdate;
      };
    }
  | { type: "add_logo"; payload: Logo }
  | { type: "add_custom_numbers"; payload: CustomNumbers }
  | { type: "add_custom_text"; payload: CustomText }
  | {
      type: "update_element";
      payload: { elementId: string; updates: CustomElementUpdate };
    }
  | { type: "remove_element"; payload: string }
  | { type: "duplicate_element"; payload: string }
  | {
      type: "update_custom_numbers";
      payload: {
        customNumbersId: string;
        updates: CustomNumbersUpdate;
      };
    }
  | {
      type: "update_custom_text";
      payload: {
        customTextId: string;
        updates: CustomTextUpdate;
      };
    }
  | {
      type: "update_color_layer";
      payload: { colorLayerId: string; updates: ColorLayerUpdate };
    }
  | {
      type: "update_model_config";
      payload: ModelConfig;
    }
  | {
      type: "update_color_layer_config";
      payload: {
        colorLayerId: string;
        updates: ColorLayerConfigUpdate;
      };
    }
  | {
      type: "set_notice_to_show";
      payload: LocalizedNotice | undefined;
    }
  | {
      type: "set_notice_viewed";
      payload: LocalizedNotice;
    }
  | { type: "switch_mode" };

/**
 * Actions that can be performed in read-only mode
 */
const READ_ONLY_ACTIONS = [
  "set_custom_model",
  "set_step_bar_items",
  "set_current_step_bar_item",
  "select_element",
  "set_notice_to_show",
  "set_notice_viewed",
  "switch_mode",
];

export function customModelEditorReducer(
  state: CustomModelEditorState,
  action: CustomModelEditorAction,
): CustomModelEditorState {
  if (action.type === "set_custom_model") {
    return {
      ...state,
      customModel: action.payload,
      stepBarItems: buildStepBar(action.payload),
    };
  }

  // other actions require a custom model
  if (!state.customModel) return state;

  // read-only mode actions
  if (state.mode === "read-only" && !READ_ONLY_ACTIONS.includes(action.type))
    return state;

  switch (action.type) {
    case "update_custom_model": {
      api.updateCustomModel(state.customModel.id, action.payload);
      return {
        ...state,
        customModel: { ...state.customModel, ...action.payload },
        // if the color layers are updated, we need to rebuild the step bar
        stepBarItems: action.payload.colorLayers
          ? buildStepBar({ ...state.customModel, ...action.payload })
          : state.stepBarItems,
      };
    }
    case "set_step_bar_items": {
      return {
        ...state,
        stepBarItems: action.payload,
      };
    }
    case "set_current_step_bar_item": {
      // we also unselect the element when changing step
      removeSelectionBox(state.customModel.id);
      // if the notice of the step is not viewed, we show it
      const { localizedNotice } = action.payload || {};

      return {
        ...state,
        currentStepBarItem: action.payload,
        noticeToShow:
          localizedNotice && localStorageService.isNoticeViewed(localizedNotice)
            ? undefined
            : action.payload?.localizedNotice,
        elementSelected: undefined,
        colorElementSelected: undefined,
      };
    }
    case "select_element": {
      const elementSelected = action.payload
        ? state.customModel.elements.find((el) => el.id === action.payload)
        : undefined;

      if (elementSelected) {
        drawSelectionBox(state.customModel.id, elementSelected.id);
      } else {
        removeSelectionBox(state.customModel.id);
      }

      return {
        ...state,
        elementSelected,
      };
    }
    case "select_color_element": {
      const colorElementSelected = action.payload
        ? findColorElementById(state.customModel, action.payload)
        : undefined;

      return {
        ...state,
        // force to re trigger effects even if the color element is the same
        colorElementSelected: colorElementSelected
          ? { ...colorElementSelected }
          : undefined,
      };
    }
    case "update_color_element": {
      const customModelUpdated = updateColorElement(
        state.customModel,
        action.payload.colorElementId,
        action.payload.updates,
      );

      api.updateCustomModel(state.customModel.id, customModelUpdated);
      return { ...state, customModel: customModelUpdated };
    }
    case "add_logo": {
      const { customModel: updatedCustomModel, logoElement } = addLogo(
        state.customModel,
        action.payload,
      );

      api.updateCustomModel(state.customModel.id, updatedCustomModel);
      return {
        ...state,
        elementSelected: logoElement,
        customModel: updatedCustomModel,
      };
    }
    case "add_custom_numbers": {
      const { customModel: customModelUpdated, customNumbersElement } =
        addCustomNumbers(state.customModel, action.payload);

      api.updateCustomModel(state.customModel.id, customModelUpdated);
      return {
        ...state,
        elementSelected: customNumbersElement,
        customModel: customModelUpdated,
      };
    }
    case "add_custom_text": {
      const { customModel: customModelUpdated, customTextElement } =
        addCustomText(state.customModel, action.payload);

      api.updateCustomModel(state.customModel.id, customModelUpdated);
      return {
        ...state,
        elementSelected: customTextElement,
        customModel: customModelUpdated,
      };
    }
    case "update_element": {
      const customModelUpdated = updateElement(
        state.customModel,
        action.payload.elementId,
        action.payload.updates,
      );

      api.updateCustomModel(state.customModel.id, customModelUpdated);

      if (state.elementSelected?.id === action.payload.elementId) {
        const customElement = state.customModel.elements.find(
          (el) => el.id === action.payload.elementId,
        ) as CustomElement;
        return {
          ...state,
          customModel: customModelUpdated,
          elementSelected: { ...customElement },
        };
      }
      return { ...state, customModel: customModelUpdated };
    }
    case "remove_element": {
      removeElement(state.customModel.id, action.payload);
      removeSelectionBox(state.customModel.id);

      const updatedCustomModel = {
        ...state.customModel,
        elements: [
          ...state.customModel.elements.filter(
            (el) => el.id !== action.payload,
          ),
        ],
      };
      api.updateCustomModel(state.customModel.id, updatedCustomModel);
      return {
        ...state,
        elementSelected: undefined,
        customModel: updatedCustomModel,
      };
    }
    case "duplicate_element": {
      const { customModel: customModelUpdated, duplicate } = duplicateElement(
        state.customModel,
        action.payload,
      );

      api.updateCustomModel(state.customModel.id, customModelUpdated);
      return {
        ...state,
        elementSelected: duplicate,
        customModel: customModelUpdated,
      };
    }
    case "update_custom_numbers": {
      const customModelUpdated = updateCustomNumbers(
        state.customModel,
        action.payload.customNumbersId,
        action.payload.updates,
      );

      api.updateCustomModel(state.customModel.id, customModelUpdated);

      if (state.elementSelected?.id === action.payload.customNumbersId) {
        const customNumbersElement = state.customModel.elements.find(
          (el) => el.id === action.payload.customNumbersId,
        ) as CustomElement;
        return {
          ...state,
          customModel: customModelUpdated,
          elementSelected: { ...customNumbersElement },
        };
      }
      return { ...state, customModel: customModelUpdated };
    }
    case "update_custom_text": {
      const customModelUpdated = updateCustomText(
        state.customModel,
        action.payload.customTextId,
        action.payload.updates,
      );

      api.updateCustomModel(state.customModel.id, customModelUpdated);

      if (state.elementSelected?.id === action.payload.customTextId) {
        const customTextElement = state.customModel.elements.find(
          (el) => el.id === action.payload.customTextId,
        ) as CustomElement;
        return {
          ...state,
          customModel: customModelUpdated,
          elementSelected: { ...customTextElement },
        };
      }
      return {
        ...state,
        customModel: customModelUpdated,
      };
    }
    case "update_color_layer": {
      const colorLayer = state.customModel.colorLayers.find(
        (cl) => cl.id === action.payload.colorLayerId,
      );
      if (!colorLayer) return state;

      colorLayer.activeColorMode = action.payload.updates.activeColorMode;
      applyCustomizationToSvg(state.customModel);
      api.updateCustomModel(state.customModel.id, { ...state.customModel });
      return { ...state };
    }
    case "update_model_config": {
      const customModelUpdated = updateModelConfig(
        state.customModel,
        action.payload,
      );

      api.updateCustomModel(state.customModel.id, customModelUpdated);
      return {
        ...state,
        stepBarItems: buildStepBar(customModelUpdated),
        customModel: customModelUpdated,
      };
    }
    case "update_color_layer_config": {
      const customModelUpdated = updateColorLayerConfig(
        state.customModel,
        action.payload.colorLayerId,
        action.payload.updates,
      );

      api.updateCustomModel(state.customModel.id, customModelUpdated);
      return { ...state, customModel: customModelUpdated };
    }
    case "set_notice_to_show": {
      return { ...state, noticeToShow: action.payload };
    }
    case "set_notice_viewed": {
      localStorageService.setNoticeViewed(action.payload);
      return { ...state, noticeToShow: undefined };
    }
    case "switch_mode": {
      return { ...state, mode: state.mode === "edit" ? "read-only" : "edit" };
    }
    default:
      throw new Error("Unknown action");
  }
}
