import { Separator } from "@components/ui/separator.tsx";
import AdminGuard from "@custom-model-editor/components/admin/admin-guard.tsx";
import CustomModelEditorModeSwitch from "@custom-model-editor/components/admin/custom-model-editor-mode-switch.tsx";
import DlCustomizationDropdown from "@custom-model-editor/components/admin/dl-customization-dropdown.tsx";
import ModelConfigPopover from "@custom-model-editor/components/models/model-config-popover.tsx";
import ModelExportDialog from "@custom-model-editor/components/models/model-export-dialog.tsx";
import { useCustomModelEditorContext } from "@custom-model-editor/hooks/use-custom-model-editor-context.ts";
import { cn } from "@lib/utils.ts";
import React from "react";

type AdminToolbarProps = React.ComponentPropsWithoutRef<"div">;

export default function AdminToolbar({
  className,
  ...props
}: AdminToolbarProps) {
  const {
    state: { customModel },
  } = useCustomModelEditorContext();

  return customModel ? (
    <AdminGuard>
      <div
        className={cn(
          "absolute right-2 top-2 lg:right-6 lg:top-4 xl:top-6",
          "flex items-center gap-4",
          "rounded-md bg-admin/90 py-2 pl-2 pr-3",
          className,
        )}
        {...props}
      >
        <CustomModelEditorModeSwitch className="-mr-1 px-2 py-1" />

        <Separator orientation="vertical" className="h-6" />

        <ModelConfigPopover />

        <DlCustomizationDropdown className="mr-1" customModel={customModel} />

        <ModelExportDialog customModel={customModel} />
      </div>
    </AdminGuard>
  ) : null;
}
