import { CustomModel } from "@/types/custom-model.ts";
import { Model } from "@/types/model.ts";
import Sidebar from "@components/layout/sidebar.tsx";
import CustomModelEditor from "@custom-model-editor/components/custom-model-editor.tsx";
import ModelImport from "@custom-model-editor/components/models/model-import.tsx";
import { useState } from "react";

export default function AdminNewModelRoute() {
  const [customModel, setCustomModel] = useState<CustomModel>();

  function handleModelImport(model: Model) {
    setCustomModel({
      id: `model-${model.id}-preview-import`,
      model,
      colorLayers: [],
      elements: [],
      importedLogos: [],
    });
  }

  return (
    <>
      {customModel ? (
        <CustomModelEditor customModel={customModel} />
      ) : (
        <>
          <Sidebar>
            <div className="p-2">
              <div className="mb-4 text-center text-xl font-black uppercase">
                Crée un nouveau modèle
              </div>
              <div className="text-center">
                Importe un fichier svg pour générer un modèle
              </div>
            </div>
          </Sidebar>

          <div className="grid place-items-center">
            <ModelImport onModelImport={handleModelImport} />
          </div>
        </>
      )}
    </>
  );
}
