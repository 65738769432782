import * as Sentry from "@sentry/browser";
import html2canvas from "html2canvas";
import { isSafari } from "react-device-detect";

type FontFaceDataUrl = {
  url: string;
  base64: string;
};

export async function svgToPng(
  svgId: string,
  querySelector: string,
): Promise<HTMLCanvasElement> {
  // prepare svg embed images and fonts (convert them to base64)
  await applySvgStyles(svgId);

  const svgWrapper = document.querySelector(querySelector) as HTMLElement;

  // little trick to prevent Safari to fail the first screenshot
  if (isSafari) {
    await html2canvas(svgWrapper, {
      allowTaint: true,
      backgroundColor: null,
    });
  }

  return html2canvas(svgWrapper, {
    allowTaint: true,
    backgroundColor: null,
  });
}

export function downloadCanvasAsPng(
  canvas: HTMLCanvasElement,
  filename: string,
) {
  const image = canvas.toDataURL();
  const link = document.createElement("a");
  link.download = filename;
  link.href = image;
  link.click();
  link.remove();
}

/**
 * Make the modifications to the svg to apply the styles and images
 * This allow to have the images and correct fonts in the generated png
 * @param svgId
 */
const applySvgStyles = async (svgId: string) => {
  const svg = document.querySelector(`#${svgId}`) as HTMLElement;

  // convert all logos to base64
  const logosGroup = document.querySelector(`#${svgId} #logos`) as HTMLElement;
  const logos = logosGroup.getElementsByTagName("image");
  for (let i = 0; i < logos.length; i++) {
    const logoImg = logos[i];
    const url = logoImg.getAttribute("href");
    const base64 = await convertImageUrlToBase64(url as string);
    logoImg.setAttribute("href", base64 as string);
  }

  const patternsGroup = document.querySelector(
    `#${svgId} #patterns`,
  ) as HTMLElement;
  const patterns = patternsGroup.getElementsByTagName("image");
  for (let i = 0; i < patterns.length; i++) {
    const patternImg = patterns[i];
    const url = patternImg.getAttribute("href") as string;
    let base64;
    try {
      base64 = await convertImageUrlToBase64(url);
    } catch (err) {
      console.log("Error converting image to base64");
      console.error(err);
      base64 = url;
    }
    patternImg.setAttribute("href", base64);
  }

  // retrieve all font-face rules from all stylesheets
  const fontFaceRules = Array.from(document.styleSheets)
    .flatMap((sheet) =>
      isSheetAccessible(sheet) ? Array.from(sheet.cssRules) : [],
    )
    .filter((rule) => rule instanceof CSSFontFaceRule)
    .map((rule) => rule.cssText)
    .join("\n");

  const fontsFaces = fontFaceRules
    ?.match(/@font-face {[^}]*}/g)
    ?.map((fontFace) => {
      const urls = fontFace.match(/(?<=url\(")(.*?)(?="\))/g);
      return fontFace
        .match(/(?<=font-family: "?)([^"]*?)(?="?;)/g)
        ?.map((fontFamily) => ({
          family: fontFamily,
          urls,
        }));
    })
    .flat();

  let fontFaceCss = fontFaceRules;
  // convert font urls to base64
  if (fontsFaces?.length) {
    const urlsToConvert = fontsFaces.flatMap((font) => font?.urls);
    const conversions = await convertFontsToDataUrls(urlsToConvert as string[]);

    fontFaceCss = conversions.reduce(
      (css, { base64, url }) => css.replace(url, base64),
      fontFaceCss,
    );
  }

  // add the new base64 font-face rules to the svg
  const style = document.createElement("style");
  style.appendChild(document.createTextNode(fontFaceCss));
  svg.appendChild(style);
};

const convertImageUrlToBase64 = async (url: string): Promise<string> => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data as string);
    };
    reader.onerror = reject;
  });
};

const convertFontsToDataUrls = async (
  urls: string[],
): Promise<FontFaceDataUrl[]> => {
  const fontFetches = urls.map(async (url) => {
    const response = await fetch(url);

    const blob = await response.blob();

    return {
      url,
      base64: await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      }),
    };
  });

  const responses = await Promise.allSettled(fontFetches);

  return responses
    .filter((response) => response.status === "fulfilled")
    .map(
      (response) => response.status === "fulfilled" && response.value,
    ) as FontFaceDataUrl[];
};

const isSheetAccessible = (sheet: CSSStyleSheet) => {
  if (sheet.href && !sheet.href.startsWith(location.origin)) return false;

  try {
    sheet.cssRules;
    // We have access
    return true;
  } catch (err) {
    console.log("Error accessing stylesheet", sheet);
    console.log(document.styleSheets);
    Sentry.withScope((scope) => {
      scope.setExtra("sheet", sheet);
      Sentry.captureException(err);
    });
    // We don't have access
    return false;
  }
};
