import { Button } from "@/components/ui/button";
import { Model } from "@/types/model.ts";
import { useAdminContext } from "@admin/hooks/use-admin-context.ts";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "@components/ui/command.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/ui/popover.tsx";
import { cn, getModelTitle } from "@lib/utils.ts";
import { PopoverContentProps } from "@radix-ui/react-popover";
import { Check, ChevronsUpDown, TriangleAlert } from "lucide-react";
import { useMemo, useState } from "react";

type ModelsComboboxProps = {
  onSelect(model?: Model): void;
  side?: PopoverContentProps["side"];
};

export default function ModelsCombobox({
  onSelect,
  side,
}: ModelsComboboxProps) {
  const [value, setValue] = useState<string>();
  const [open, setOpen] = useState(false);

  const {
    state: { models },
  } = useAdminContext();

  const model = useMemo(
    () => models.find((m) => m.id === value),
    [value, models],
  );
  const modelsSorted = useMemo(
    () => models.sort((a, b) => (getModelTitle(a) < getModelTitle(b) ? -1 : 1)),
    [models],
  );

  function handleSelect(id: string) {
    if (value === id) {
      setValue(undefined);
      onSelect(undefined);
    } else {
      setValue(id);
      const model = models.find((m) => m.id === id);
      onSelect(model);
    }
    setOpen(false);
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="min-w-[200px] justify-between gap-2 normal-case"
        >
          {model ? getModelTitle(model) : "Modèles de Custom Lab..."}
          <ChevronsUpDown />
        </Button>
      </PopoverTrigger>

      <PopoverContent className="p-0" side={side}>
        <Command>
          <CommandInput placeholder="Recherche un modèle" />
          <CommandEmpty>Aucun modèle trouvé</CommandEmpty>
          <CommandList>
            {modelsSorted.map((model) => (
              <CommandItem
                key={model.id}
                value={getModelTitle(model)}
                onSelect={() => handleSelect(model.id)}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value === model.id ? "opacity-100" : "opacity-0",
                  )}
                />

                {model.shopifyProduct ? (
                  <span className="flex items-center text-orange-600">
                    <TriangleAlert className="mr-1 h-3.5 w-3.5" />
                    {getModelTitle(model)}
                  </span>
                ) : (
                  getModelTitle(model)
                )}
              </CommandItem>
            ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
