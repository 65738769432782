import {
  Color,
  ColorElement,
  ColorLayer,
  ColorMode,
  Pattern,
} from "@/types/color-elements.ts";
import ToggleButton from "@components/ui/toggle-button.tsx";
import AdminGuard from "@custom-model-editor/components/admin/admin-guard.tsx";
import ColorElementList from "@custom-model-editor/components/color-elements/color-element-list.tsx";
import ColorLayerConfigColorModePicker from "@custom-model-editor/components/color-layers/color-layer-config-color-mode-picker.tsx";
import ConfigureColorLayerPopover from "@custom-model-editor/components/color-layers/configure-color-layer-popover.tsx";
import ColorGroupList from "@custom-model-editor/components/colors-groups/color-group-list.tsx";
import { useCustomModelEditorContext } from "@custom-model-editor/hooks/use-custom-model-editor-context.ts";
import {
  focusColorLayer,
  makeColorLayerInteractive,
  makeColorLayerNonInteractive,
  unfocusColorLayer,
} from "@custom-model-editor/lib/custom-model.ts";
import {
  makeElementClickable,
  setColorElementColor,
} from "@custom-model-editor/lib/svg.ts";
import { cn, normalizeStr } from "@lib/utils.ts";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type StepsMenuColorLayerProps = React.ComponentPropsWithoutRef<"div"> & {
  colorLayer: ColorLayer;
};

export default function StepsMenuColorLayer({
  className,
  colorLayer,
  ...props
}: StepsMenuColorLayerProps) {
  const [focus, setFocus] = useState<boolean>(false);

  const {
    state: { customModel },
    dispatch,
  } = useCustomModelEditorContext();

  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();

  /**
   * Focus the color layer is it is focusable when the component mounts
   * and unfocus it when the component unmounts
   *
   * Make the color layer interactive when the component mounts
   * and remove the interactivity when the component unmounts
   */
  useEffect(() => {
    // TODO: Move this logic to the customModelEditorContext
    if (colorLayer.focusText) {
      setFocus(true);
      focusColorLayer(customModel, colorLayer.id);
    }
    makeColorLayerInteractive(customModel, colorLayer.id, (colorElement) =>
      dispatch({ type: "select_color_element", payload: colorElement.id }),
    );
    makeElementClickable(customModel.id, "logos", false);
    makeElementClickable(customModel.id, "custom-texts", false);
    makeElementClickable(customModel.id, "custom-numbers", false);

    return () => {
      setFocus(false);
      unfocusColorLayer(customModel);
      makeColorLayerNonInteractive(customModel.id, colorLayer);
    };
  }, [colorLayer]);

  function handleFocusChange(_focus: boolean) {
    setFocus(_focus);
    if (_focus) {
      focusColorLayer(customModel, colorLayer.id);
    } else {
      unfocusColorLayer(customModel);
    }
  }

  function handleColorClick(colorElement: ColorElement, color: Color) {
    dispatch({
      type: "update_color_element",
      payload: {
        colorElementId: colorElement.id,
        updates: { color, pattern: colorElement.pattern },
      },
    });
  }

  function handleColorOver(colorElement: ColorElement, color: Color) {
    setColorElementColor(
      customModel.id,
      colorElement.id,
      {
        ...colorElement,
        color,
      },
      colorLayer.activeColorMode,
    );
  }

  function handleColorOut(colorElement: ColorElement) {
    setColorElementColor(
      customModel.id,
      colorElement.id,
      colorElement,
      colorLayer.activeColorMode,
    );
  }

  function handlePatternClick(
    colorElement: ColorElement,
    pattern: Pattern | undefined,
  ) {
    const update: Pattern | undefined =
      colorElement.pattern?.url === pattern?.url ? undefined : pattern;

    dispatch({
      type: "update_color_element",
      payload: {
        colorElementId: colorElement.id,
        updates: { color: colorElement.color, pattern: update },
      },
    });
  }

  function handlePatternOver(colorElement: ColorElement, pattern: Pattern) {
    setColorElementColor(
      customModel.id,
      colorElement.id,
      {
        ...colorElement,
        pattern,
      },
      colorLayer.activeColorMode,
    );
  }

  function handlePatternOut(colorElement: ColorElement) {
    setColorElementColor(
      customModel.id,
      colorElement.id,
      colorElement,
      colorLayer.activeColorMode,
    );
  }

  function handleColorModeChange(colorMode: ColorMode) {
    dispatch({
      type: "update_color_layer",
      payload: {
        colorLayerId: colorLayer.id,
        updates: { activeColorMode: colorMode },
      },
    });
  }

  return (
    <div
      className={cn(
        "fancy-scrollbar h-full overflow-y-auto overflow-x-hidden px-1 pb-1",
        className,
      )}
      {...props}
    >
      <AdminGuard>
        <ConfigureColorLayerPopover
          className="mb-5 w-full"
          colorLayer={colorLayer}
        />
      </AdminGuard>

      {colorLayer.menuText ? (
        <p className="mb-2 text-center font-semibold leading-5">
          {resolvedLanguage === "fr"
            ? colorLayer.menuText
            : "Configure the motorcycle as yours! Plastics not included with the graphics kit."}
        </p>
      ) : null}

      {colorLayer.focusText ? (
        <div className="mb-4 mt-3 flex flex-col gap-1 pl-1">
          <div className="text-lg font-semibold">
            {t(normalizeStr(colorLayer.focusText), colorLayer.focusText, {
              ns: "focus-texts",
            })}
          </div>
          <ToggleButton
            toggled={!focus}
            onChange={(value) => handleFocusChange(!value)}
          />
        </div>
      ) : null}

      {colorLayer.colorModes?.length ? (
        <ColorLayerConfigColorModePicker
          className="mb-2 px-2 xl:mb-3"
          colorModes={colorLayer.colorModes}
          value={colorLayer.activeColorMode}
          onChange={handleColorModeChange}
        />
      ) : null}

      {colorLayer.level === "one-level" ? (
        <ColorElementList
          colorElements={colorLayer.colorElements}
          colorMode={colorLayer.activeColorMode}
          colorList={colorLayer.colorList}
          onColorClick={handleColorClick}
          onColorOver={handleColorOver}
          onColorOut={handleColorOut}
          patternList={colorLayer.patterns ?? []}
          onPatternClick={handlePatternClick}
          onPatternOver={handlePatternOver}
          onPatternOut={handlePatternOut}
        />
      ) : colorLayer.level === "two-level" ? (
        <ColorGroupList
          colorGroups={colorLayer.colorGroups}
          colorMode={colorLayer.activeColorMode}
          colorList={colorLayer.colorList}
          onColorClick={handleColorClick}
          onColorOver={handleColorOver}
          onColorOut={handleColorOut}
          patternList={colorLayer.patterns ?? []}
          onPatternClick={handlePatternClick}
          onPatternOver={handlePatternOver}
          onPatternOut={handlePatternOut}
        />
      ) : null}
    </div>
  );
}
