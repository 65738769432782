import { cn } from "@lib/utils.ts";
import React from "react";

type ToggleButtonProps = Omit<
  React.ComponentPropsWithoutRef<"div">,
  "onChange"
> & {
  toggled: boolean;
  onChange: (toggle: boolean) => void;
};

export default function ToggleButton({
  className,
  toggled,
  onChange,
  ...props
}: ToggleButtonProps) {
  return (
    <div
      className={cn(
        "relative",
        "h-[30px] w-[60px]",
        "rounded-full bg-[#999]",
        "cursor-pointer",
        { "bg-primary": toggled },
        className,
      )}
      onClick={() => onChange(!toggled)}
      {...props}
    >
      <div
        className={cn(
          "absolute left-[3px] top-1/2 -translate-y-1/2",
          "h-[25px] w-[25px] rounded-full bg-white",
          "duration-200",
          { "translate-x-[29px]": toggled },
        )}
      />
    </div>
  );
}
