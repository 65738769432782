import { CUSTOM_LAB_COLORS } from "@/config.ts";
import {
  Color,
  ColorElement,
  ColorGroup,
  ColorLayer,
  ColorLayerLevelType,
  ColorMode,
} from "@/types/color-elements.ts";
import { CustomElement } from "@/types/custom-element.ts";
import { CustomModel } from "@/types/custom-model.ts";
import { Model } from "@/types/model.ts";
import {
  drawCustomTextElement,
  drawLogoElement,
  hideElement,
  highlightElement,
  makeElementClickable,
  setColorElementColor,
  setColorLayerColorElementsColor,
  showElement,
} from "@custom-model-editor/lib/svg.ts";
import { normalizeStr } from "@lib/utils.ts";
import { Element, SVG, Svg } from "@svgdotjs/svg.js";

/**
 * Apply all the customization of a custom model to a svg
 * set the color of the color elements
 * draw the logos, custom-texts and custom-numbers
 */
export function applyCustomizationToSvg(customModel: CustomModel) {
  const svg = SVG(`#${customModel.id}`) as Svg;
  if (!svg) {
    throw new Error(
      `Cannot apply customization: svg with id ${customModel.id} not found`,
    );
  }

  // set the color of the color elements
  customModel.colorLayers.forEach((colorLayer) => {
    setColorLayerColorElementsColor(customModel.id, colorLayer);
  });

  // we apply the color mode of the first color layer that has color modes to the custom elements
  const colorMode = getCustomElementsColorMode(customModel);

  // draw the logos, texts and numbers
  customModel.elements?.forEach((element) => {
    drawCustomElement(customModel.id, element, colorMode);
  });
}

/**
 * Make a color layer interactive
 * -> add interactive event listeners to all its color elements
 * -> make all other color layers non-clickable
 */
export function makeColorLayerInteractive(
  customModel: CustomModel,
  colorLayerId: string,
  onClickCallback: (colorElement: ColorElement) => void,
) {
  customModel.colorLayers.forEach((colorLayer) => {
    // make the color layer interactive
    if (colorLayer.id === colorLayerId) {
      const colorElements =
        colorLayer.level === "two-level"
          ? colorLayer.colorGroups.flatMap((cg) => cg.colorElements)
          : colorLayer.colorElements;

      colorElements.forEach((colorElement) =>
        makeColorElementInteractive(
          customModel.id,
          colorElement,
          colorLayer.activeColorMode,
          onClickCallback,
        ),
      );
      makeElementClickable(customModel.id, colorLayer.id, true);
    }
    // make the color layer non-clickable
    else {
      makeElementClickable(customModel.id, colorLayer.id, false);
    }
  });
}

/**
 * Make a color element interactive
 * highlight its color when hovered
 * allow to select it when clicked
 */
function makeColorElementInteractive(
  svgId: string,
  colorElement: ColorElement,
  colorMode: ColorMode,
  onClickCallback: (colorElement: ColorElement) => void,
) {
  const element = SVG(`#${svgId} #${colorElement.id}`);
  if (!element) return;

  element.off("mouseenter");
  element.off("mouseleave");
  element.off("mousedown");
  element.off("mouseup");
  element.off("touchstart");
  element.off("touchmove");
  element.off("touchend");

  element.css("cursor", "pointer");

  element.on("mouseenter", () => {
    highlightElement(svgId, colorElement.id, colorElement.color.color);
  });
  element.on("mouseleave", () => {
    setColorElementColor(svgId, colorElement.id, colorElement, colorMode);
  });

  let mouseDownEvent: MouseEvent;
  element.on("mousedown", (e) => {
    mouseDownEvent = e as MouseEvent;
  });
  element.on("mouseup", (e) => {
    const mouseUpEvent = e as MouseEvent;
    if (
      mouseDownEvent?.x === mouseUpEvent.x &&
      mouseDownEvent?.y === mouseUpEvent.y
    ) {
      onClickCallback(colorElement);
    }
  });

  let dragging = false;
  element.on("touchstart", () => {
    dragging = false;
  });
  element.on("touchmove", () => {
    dragging = true;
  });
  element.on("touchend", () => {
    if (!dragging) {
      onClickCallback(colorElement);
    }
  });
}

/**
 * Disable the interactivity of a color layer (i.e. remove all event listeners of its color elements)
 */
export function makeColorLayerNonInteractive(
  svgId: string,
  colorLayer: ColorLayer,
) {
  const colorElements =
    colorLayer.level === "two-level"
      ? colorLayer.colorGroups.flatMap((cg) => cg.colorElements)
      : colorLayer.colorElements;
  colorElements.forEach((colorElement) => {
    const element = SVG(`#${svgId} #${colorElement.id}`);
    if (!element) return;

    element.off("mouseenter");
    element.off("mouseleave");
    element.off("click");
    element.off("touchstart");
    element.off("touchmove");
    element.off("touchend");

    element.css("cursor", "default");
  });
}

/**
 * Draw a custom element on the svg according to its type
 */
export function drawCustomElement(
  svgId: string,
  element: CustomElement,
  colorMode: ColorMode,
) {
  let gradient = false;

  switch (element.type) {
    case "logo":
      drawLogoElement(svgId, element);
      break;
    case "custom-numbers":
      gradient = shouldGradient(element.customNumbers.color, colorMode);
      drawCustomTextElement(svgId, element, gradient);
      break;
    case "custom-text":
      gradient = shouldGradient(element.customText.color, colorMode);
      drawCustomTextElement(svgId, element, gradient);
      break;
  }
}

/**
 * Extracts the color layers from a Svg and builds the color layers with the model config (focusable, additional text, color list etc...)
 * iterate through the "custom" group
 */
export function getColorLayersFromSvg(svg: Svg, model: Model): ColorLayer[] {
  const customGroup = Array.from(svg.children()).find(
    (child) => child.id() === "custom",
  );

  if (!customGroup) {
    throw new Error("Custom group not found in SVG");
  }

  return Array.from(
    customGroup.children().map((colorLayer) => {
      const level = getColorLayerLevel(colorLayer);
      const id = colorLayer.id();

      const name = colorLayer.id().replaceAll("_", " ");

      const colorLayerConfig = model.colorLayersConfig.find(
        (clc) => normalizeStr(clc.colorLayerId) === normalizeStr(id),
      );

      const { colorList, patterns, colorModes, focusText, menuText } =
        colorLayerConfig ?? {};

      // shared properties between one-level and two-level color layers
      const sharedProps = {
        id,
        name,
        colorList: colorList ?? CUSTOM_LAB_COLORS,
        menuText,
        focusText,
        patterns,
        colorModes,
        activeColorMode: colorModes?.[0] ?? "flat",
      };

      return level === "two-level"
        ? {
            ...sharedProps,
            level: "two-level",
            colorGroups: Array.from(colorLayer.children())
              .map((el) =>
                elementToColorGroup(el, colorList ?? CUSTOM_LAB_COLORS),
              )
              .filter((cg) => !!cg) as ColorGroup[],
          }
        : {
            ...sharedProps,
            level: "one-level",
            colorElements: Array.from(colorLayer.children())
              .map((el) =>
                elementToColorElement(el, colorList ?? CUSTOM_LAB_COLORS),
              )
              .filter((ce) => !!ce) as ColorElement[],
          };
    }),
  );
}

function getColorLayerLevel(element: Element): ColorLayerLevelType {
  // if the first child of the first group has a color, then the element is a one-level color layer
  // #000000 => also means no color (from adobe export)
  const color = element.children().at(0)?.children().at(0)?.attr("fill");
  const hasColorElements =
    color === undefined || color === "none" || color === "#000000";

  return hasColorElements ? "two-level" : "one-level";
}

/**
 * Transform a Svg element into a color group
 * @param element
 * @param colorList
 */
export function elementToColorGroup(
  element: Element,
  colorList: Color[],
): ColorGroup | undefined {
  // color groups must be svg groups with at least one child
  if (element.type !== "g" || element.children().length === 0) return;

  const nameArray = element.id().replaceAll("_", " ").split("-");

  const nameStr =
    nameArray.length > 1 ? nameArray.slice(0, -1).join(" ") : nameArray[0];

  return {
    id: element.id(),
    name: nameStr,
    colorElements: Array.from(element.children())
      .map((child) => elementToColorElement(child, colorList))
      .filter((ce) => !!ce) as ColorElement[],
  };
}

/**
 * Transform a Svg element into a color element
 * @param element
 * @param colorList
 */
export function elementToColorElement(
  element: Element,
  colorList: Color[],
): ColorElement | undefined {
  // color elements must be svg groups with at least one child
  if (element.type !== "g" || element.children().length === 0) return;

  const colorStr = element.children().at(0)?.attr("fill");
  const color: Color = colorList.find((c) => c.color === colorStr) ?? {
    name: "NO_NAME",
    color: colorStr,
  };

  const nameArray = element.id().replaceAll("_", " ").split("-");

  const nameStr =
    nameArray.length > 1 ? nameArray.slice(0, -1).join(" ") : nameArray[0];

  return {
    id: element.id(),
    name: nameStr,
    color,
  };
}

/**
 * Focus a color layer by hiding the other color layers and custom elements
 */
export function focusColorLayer(
  customModel: CustomModel,
  colorLayerId: string,
) {
  customModel.colorLayers
    .filter((cl) => cl.id !== colorLayerId)
    .forEach((cl) => hideElement(customModel.id, cl.id));
  hideElement(customModel.id, "logos");
  hideElement(customModel.id, "custom-texts");
  hideElement(customModel.id, "custom-numbers");
  hideElement(customModel.id, "patterns");
}

/**
 * Unfocus a color layer by showing all color layers and custom elements
 */
export function unfocusColorLayer(customModel: CustomModel) {
  customModel.colorLayers.forEach((cl) => showElement(customModel.id, cl.id));
  showElement(customModel.id, "logos");
  showElement(customModel.id, "custom-texts");
  showElement(customModel.id, "custom-numbers");
  showElement(customModel.id, "patterns");
}

/**
 * Find a color element by its id
 * @param customModel
 * @param colorElementId
 */
export function findColorElementById(
  customModel: CustomModel,
  colorElementId: string,
): ColorElement | undefined {
  // flat all color elements then just find it
  return customModel.colorLayers
    .flatMap((cl) =>
      cl.level === "one-level"
        ? cl.colorElements
        : cl.level === "two-level"
          ? cl.colorGroups.flatMap((cg) => cg.colorElements)
          : [],
    )
    .find((ce) => ce.id === colorElementId);
}

export function findColorLayerByColorElementId(
  customModel: CustomModel,
  colorElementId: string,
): ColorLayer | undefined {
  return customModel.colorLayers.find((cl) =>
    cl.level === "one-level"
      ? cl.colorElements.some((ce) => ce.id === colorElementId)
      : cl.level === "two-level"
        ? cl.colorGroups.some((cg) =>
            cg.colorElements.some((ce) => ce.id === colorElementId),
          )
        : false,
  );
}

/**
 * If there is at least one color layer with an active color a mode (e.g. chromium)
 * use it also for the custom elements
 */
export function getCustomElementsColorMode(customModel: CustomModel) {
  return (
    customModel.colorLayers.find((cl) => cl.colorModes?.length ?? 0 > 0)
      ?.activeColorMode ?? "flat"
  );
}

export function shouldGradient(color: Color, colorMode: ColorMode): boolean {
  return colorMode === "chromium"
    ? true
    : colorMode === "chromium-white" && color.name !== "WHITE";
}
