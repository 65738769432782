import AskLandscapeIcon from "@components/icons/ask-landscape-icon.tsx";
import { cn } from "@lib/utils.ts";
import { ArrowRight, ArrowUp } from "lucide-react";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

type AskLandscapeProps = React.ComponentPropsWithoutRef<"div">;

export default function AskLandscape({
  className,
  children,
  ...props
}: AskLandscapeProps) {
  const [orientation, setOrientation] = useState(
    window.screen.orientation.type,
  );

  const { t } = useTranslation("ask-landscape");

  useEffect(() => {
    function handleOrientationChange() {
      setOrientation(window.screen.orientation.type);
    }

    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  return (
    <>
      {isMobile &&
      (orientation === "portrait-primary" ||
        orientation === "portrait-secondary") ? (
        <div
          className={cn(
            "fixed bottom-0 left-0 right-0 top-0",
            "z-50 bg-transparent/75",
            className,
          )}
          {...props}
        >
          <div
            className={cn("fixed left-0 right-0 top-3", "text-center text-lg")}
          >
            <span>{t("open-from-instagram")}</span>
            <br />
            <div className="flex items-center justify-center">
              <ArrowRight className="mr-2" size={20} />
              <span className="font-semibold">
                {t("open-in-external-browser")}
              </span>
            </div>
          </div>

          <div className="fixed right-3 top-3 animate-bounce">
            <ArrowUp></ArrowUp>
          </div>

          <div className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <AskLandscapeIcon
              width={250}
              height={250}
              className="rotate-180 animate-ask-landscape"
            />
          </div>
        </div>
      ) : null}

      {children}
    </>
  );
}
