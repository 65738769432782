import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@components/ui/alert-dialog.tsx";
import { Button } from "@components/ui/button.tsx";
import { AlertDialogProps } from "@radix-ui/react-alert-dialog";

type ConfirmResetCustomizationAlertDialogProps = AlertDialogProps & {
  onConfirm: () => void;
};

export default function ConfirmResetCustomizationAlertDialog({
  onConfirm,
  ...props
}: ConfirmResetCustomizationAlertDialogProps) {
  return (
    <AlertDialog {...props}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Réinitialiser la configuration ?</AlertDialogTitle>
          <AlertDialogDescription>
            Ta configuration sera remise à zéro. Cette action ne pas peut être
            annulée.
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel>Annuler</AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button variant="destructive" size="sm" onClick={onConfirm}>
              Réinitialiser
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
