import {
  CUSTOM_NUMBERS_DEFAULT_NOTICE,
  CUSTOM_NUMBERS_MAX_FONT_SIZE,
  CUSTOM_NUMBERS_MAX_SPACING,
  CUSTOM_NUMBERS_MIN_FONT_SIZE,
  CUSTOM_NUMBERS_MIN_SPACING,
  CUSTOM_NUMBERS_STROKE_MAX_WIDTH,
  CUSTOM_TEXT_MAX_FONT_SIZE,
  CUSTOM_TEXT_MAX_SPACING,
  CUSTOM_TEXT_MIN_FONT_SIZE,
  CUSTOM_TEXT_MIN_SPACING,
  CUSTOM_TEXT_STROKE_MAX_WIDTH,
  CUSTOM_TEXTS_DEFAULT_NOTICE,
  LOGO_DEFAULT_HEIGHT,
  LOGO_DEFAULT_WIDTH,
  LOGO_MAX_HEIGHT,
  LOGO_MAX_WIDTH,
  LOGO_MIN_HEIGHT,
  LOGO_MIN_WIDTH,
  LOGOS_DEFAULT_NOTICE,
} from "@/config.ts";
import { ColorElementUpdate, ColorLayer } from "@/types/color-elements.ts";
import {
  CustomElement,
  CustomElementUpdate,
  CustomNumbers,
  CustomNumbersUpdate,
  CustomText,
  CustomTextUpdate,
  Logo,
} from "@/types/custom-element.ts";
import { StepBarItem } from "@/types/custom-model-editor.ts";
import { CustomModel } from "@/types/custom-model.ts";
import { ColorLayerConfigUpdate, ModelConfig } from "@/types/model.ts";
import StepsMenuColorLayer from "@custom-model-editor/components/steps-menus/steps-menu-color-layer.tsx";
import StepsMenuCustomNumbers from "@custom-model-editor/components/steps-menus/steps-menu-custom-numbers.tsx";
import StepsMenuCustomTexts from "@custom-model-editor/components/steps-menus/steps-menu-custom-texts.tsx";
import StepsMenuLogos from "@custom-model-editor/components/steps-menus/steps-menu-logos.tsx";
import {
  drawCustomElement,
  findColorElementById,
  findColorLayerByColorElementId,
  getCustomElementsColorMode,
  shouldGradient,
} from "@custom-model-editor/lib/custom-model.ts";
import {
  drawCustomTextElement,
  drawLogoElement,
  drawSelectionBox,
  getSvgCenterPos,
  setColorElementColor,
} from "@custom-model-editor/lib/svg.ts";
import { makeId, normalizeStr } from "@lib/utils.ts";

export function buildStepBar(customModel: CustomModel): StepBarItem[] {
  const colorLayerMenus: StepBarItem[] = customModel.colorLayers.map(
    (colorLayer) => ({
      title: normalizeStr(colorLayer.name),
      component: StepsMenuColorLayer,
      componentProps: { colorLayer },
      localizedNotice: colorLayer.localizedNotice,
    }),
  );

  let stepBarItems = [...colorLayerMenus];

  const {
    model: { modelConfig },
  } = customModel;

  if (modelConfig.logos) {
    stepBarItems = [
      ...stepBarItems,
      {
        title: "logos-step",
        component: StepsMenuLogos,
        localizedNotice: LOGOS_DEFAULT_NOTICE,
      },
    ];
  }
  if (modelConfig.customNumbers) {
    stepBarItems = [
      ...stepBarItems,
      {
        title: "numbers-step",
        component: StepsMenuCustomNumbers,
        localizedNotice: CUSTOM_NUMBERS_DEFAULT_NOTICE,
      },
    ];
  }
  if (modelConfig.customTexts) {
    stepBarItems = [
      ...stepBarItems,
      {
        title: "texts-step",
        component: StepsMenuCustomTexts,
        localizedNotice: CUSTOM_TEXTS_DEFAULT_NOTICE,
      },
    ];
  }

  return stepBarItems;
}

export function updateColorElement(
  customModel: CustomModel,
  colorElementId: string,
  updates: ColorElementUpdate,
): CustomModel {
  const colorElement = findColorElementById(customModel, colorElementId);

  // retrieve the color mode from the color layer
  const { activeColorMode: colorMode } = findColorLayerByColorElementId(
    customModel,
    colorElementId,
  ) as ColorLayer;

  if (colorElement) {
    colorElement.color = updates.color;
    colorElement.pattern = updates.pattern;
    setColorElementColor(
      customModel.id,
      colorElement.id,
      colorElement,
      colorMode || "flat",
    );
  }

  return { ...customModel };
}

export function addLogo(
  customModel: CustomModel,
  logo: Logo,
): { customModel: CustomModel; logoElement?: CustomElement } {
  // center the logo in the svg
  const svgCenterPos = getSvgCenterPos(customModel.id);
  const x = svgCenterPos.x - LOGO_DEFAULT_WIDTH / 2;
  const y = svgCenterPos.y - LOGO_DEFAULT_HEIGHT / 2;

  let logoElement: CustomElement = {
    type: "logo",
    id: makeId(12),
    logo: {
      ...logo,
      url: new URL(logo.url, import.meta.url).href,
    },
    width: LOGO_DEFAULT_WIDTH,
    height: LOGO_DEFAULT_HEIGHT,
    x,
    y,
    a: 0,
  };

  logoElement = drawLogoElement(customModel.id, logoElement) as CustomElement;
  logoElement.width =
    logoElement.width > LOGO_MAX_WIDTH ? LOGO_MAX_WIDTH : logoElement.width;
  logoElement.height =
    logoElement.height > LOGO_MAX_HEIGHT ? LOGO_MAX_HEIGHT : logoElement.height;
  drawLogoElement(customModel.id, logoElement);
  drawSelectionBox(customModel.id, logoElement.id);

  return {
    customModel: {
      ...customModel,
      elements: [...customModel.elements, logoElement],
    },
    logoElement,
  };
}

export function addCustomNumbers(
  customModel: CustomModel,
  customNumbers: CustomNumbers,
): { customModel: CustomModel; customNumbersElement?: CustomElement } {
  // center the element in the svg
  const svgCenterPos = getSvgCenterPos(customModel.id);
  const x = svgCenterPos.x;
  const y = svgCenterPos.y;

  const customNumbersElement: CustomElement = {
    type: "custom-numbers",
    id: makeId(12),
    x,
    y,
    width: 0,
    height: 0,
    a: 0,
    customNumbers,
  };

  const colorMode = getCustomElementsColorMode(customModel);
  const gradient = shouldGradient(
    customNumbersElement.customNumbers.color,
    colorMode,
  );
  drawCustomTextElement(customModel.id, customNumbersElement, gradient);
  drawSelectionBox(customModel.id, customNumbersElement.id);

  return {
    customModel: {
      ...customModel,
      elements: [...customModel.elements, customNumbersElement],
    },
    customNumbersElement,
  };
}

export function addCustomText(
  customModel: CustomModel,
  customText: CustomText,
): { customModel: CustomModel; customTextElement?: CustomElement } {
  // center the element in the svg
  const svgCenterPos = getSvgCenterPos(customModel.id);
  const x = svgCenterPos.x;
  const y = svgCenterPos.y;

  const customTextElement: CustomElement = {
    type: "custom-text",
    id: makeId(12),
    x,
    y,
    width: 0,
    height: 0,
    a: 0,
    customText,
  };

  const colorMode = getCustomElementsColorMode(customModel);
  const gradient = shouldGradient(
    customTextElement.customText.color,
    colorMode,
  );
  drawCustomTextElement(customModel.id, customTextElement, gradient);
  drawSelectionBox(customModel.id, customTextElement.id);

  return {
    customModel: {
      ...customModel,
      elements: [...customModel.elements, customTextElement],
    },
    customTextElement,
  };
}

export function updateElement(
  customModel: CustomModel,
  elementId: string,
  updates: CustomElementUpdate,
): CustomModel {
  const element = customModel.elements.find((el) => el.id === elementId);
  if (!element) return customModel;

  element.width = updates.width ?? element.width;
  element.height = updates.height ?? element.height;
  element.a = updates.a ?? element.a;

  // check max and min width and height for logos
  if (element.type === "logo") {
    if (
      !(
        element.width > LOGO_MAX_WIDTH ||
        element.width < LOGO_MIN_WIDTH ||
        element.height > LOGO_MAX_HEIGHT ||
        element.height < LOGO_MIN_HEIGHT
      )
    ) {
      element.x = updates.x ?? element.x;
      element.y = updates.y ?? element.y;
    }

    element.width =
      element.width > LOGO_MAX_WIDTH ? LOGO_MAX_WIDTH : element.width;
    element.width =
      element.width < LOGO_MIN_WIDTH ? LOGO_MIN_WIDTH : element.width;
    element.height =
      element.height > LOGO_MAX_HEIGHT ? LOGO_MAX_HEIGHT : element.height;
    element.height =
      element.height < LOGO_MIN_HEIGHT ? LOGO_MIN_HEIGHT : element.height;
  } else {
    element.x = updates.x ?? element.x;
    element.y = updates.y ?? element.y;
  }

  const colorMode = getCustomElementsColorMode(customModel);
  drawCustomElement(customModel.id, element, colorMode);
  drawSelectionBox(customModel.id, element.id);

  return { ...customModel };
}

export function duplicateElement(
  customModel: CustomModel,
  elementId: string,
): { customModel: CustomModel; duplicate?: CustomElement } {
  const toDuplicate = JSON.parse(
    JSON.stringify(customModel.elements.find((el) => el.id === elementId)),
  );
  if (!toDuplicate) return { customModel };

  const xOffset =
    toDuplicate.type === "logo"
      ? toDuplicate.width / 2
      : toDuplicate.type === "custom-text"
        ? toDuplicate.customText.fontSize
        : toDuplicate.customNumbers.fontSize;
  const yOffset =
    toDuplicate.type === "logo"
      ? toDuplicate.height / 2
      : toDuplicate.type === "custom-text"
        ? toDuplicate.customText.fontSize
        : toDuplicate.customNumbers.fontSize;

  const newElement: CustomElement = {
    ...toDuplicate,
    id: makeId(12),
    x: toDuplicate.x + xOffset,
    y: toDuplicate.y + yOffset,
  };

  const colorMode = getCustomElementsColorMode(customModel);
  drawCustomElement(customModel.id, newElement, colorMode);
  drawSelectionBox(customModel.id, newElement.id);

  return {
    customModel: {
      ...customModel,
      elements: [...customModel.elements, newElement],
    },
    duplicate: newElement,
  };
}

export function updateCustomNumbers(
  customModel: CustomModel,
  customNumbersId: string,
  updates: CustomNumbersUpdate,
): CustomModel {
  const customNumbersElement = customModel.elements.find(
    (el) => el.id === customNumbersId,
  );

  if (!customNumbersElement) return customModel;
  if (customNumbersElement.type !== "custom-numbers") {
    throw new Error(
      "Cannot update custom numbers: element is not a custom numbers element",
    );
  }

  const { customNumbers } = customNumbersElement;

  customNumbers.text = updates.text ?? customNumbers.text;
  customNumbers.font = updates.font ?? customNumbers.font;

  customNumbers.fontSize = updates.fontSize ?? customNumbers.fontSize;
  // check max and min font size
  customNumbers.fontSize =
    customNumbers.fontSize > CUSTOM_NUMBERS_MAX_FONT_SIZE
      ? CUSTOM_NUMBERS_MAX_FONT_SIZE
      : customNumbers.fontSize;
  customNumbers.fontSize =
    customNumbers.fontSize < CUSTOM_NUMBERS_MIN_FONT_SIZE
      ? CUSTOM_NUMBERS_MIN_FONT_SIZE
      : customNumbers.fontSize;

  customNumbers.color = updates.color ?? customNumbers.color;

  customNumbers.stroke = {
    ...customNumbers.stroke,
    ...updates.stroke,
  };
  // check max stroke width
  customNumbers.stroke.width =
    customNumbers.stroke.width > CUSTOM_NUMBERS_STROKE_MAX_WIDTH
      ? CUSTOM_NUMBERS_STROKE_MAX_WIDTH
      : customNumbers.stroke.width;

  customNumbers.spacing = updates.spacing ?? customNumbers.spacing;
  // check max and min spacing
  customNumbers.spacing =
    customNumbers.spacing > CUSTOM_NUMBERS_MAX_SPACING
      ? CUSTOM_NUMBERS_MAX_SPACING
      : customNumbers.spacing;
  customNumbers.spacing =
    customNumbers.spacing < CUSTOM_NUMBERS_MIN_SPACING
      ? CUSTOM_NUMBERS_MIN_SPACING
      : customNumbers.spacing;

  const colorMode = getCustomElementsColorMode(customModel);
  const gradient = shouldGradient(customNumbers.color, colorMode);

  drawCustomTextElement(customModel.id, customNumbersElement, gradient);
  drawSelectionBox(customModel.id, customNumbersElement.id);

  return { ...customModel };
}

export function updateCustomText(
  customModel: CustomModel,
  customTextId: string,
  updates: CustomTextUpdate,
): CustomModel {
  const customTextElement = customModel.elements.find(
    (el) => el.id === customTextId,
  );

  if (!customTextElement) return customModel;
  if (customTextElement.type !== "custom-text") {
    throw new Error(
      "Cannot update custom text: element is not a custom text element",
    );
  }

  const { customText } = customTextElement;

  customText.text = updates.text ?? customText.text;
  customText.font = updates.font ?? customText.font;

  customText.fontSize = updates.fontSize ?? customText.fontSize;
  // check max and min font size
  customText.fontSize =
    customText.fontSize > CUSTOM_TEXT_MAX_FONT_SIZE
      ? CUSTOM_TEXT_MAX_FONT_SIZE
      : customText.fontSize;
  customText.fontSize =
    customText.fontSize < CUSTOM_TEXT_MIN_FONT_SIZE
      ? CUSTOM_TEXT_MIN_FONT_SIZE
      : customText.fontSize;

  customText.color = updates.color ?? customText.color;

  customText.stroke = {
    ...customText.stroke,
    ...updates.stroke,
  };
  // check max stroke width
  customText.stroke.width =
    customText.stroke.width > CUSTOM_TEXT_STROKE_MAX_WIDTH
      ? CUSTOM_TEXT_STROKE_MAX_WIDTH
      : customText.stroke.width;

  customText.spacing = updates.spacing ?? customText.spacing;
  // check max and min spacing
  customText.spacing =
    customText.spacing > CUSTOM_TEXT_MAX_SPACING
      ? CUSTOM_TEXT_MAX_SPACING
      : customText.spacing;
  customText.spacing =
    customText.spacing < CUSTOM_TEXT_MIN_SPACING
      ? CUSTOM_TEXT_MIN_SPACING
      : customText.spacing;

  const colorMode = getCustomElementsColorMode(customModel);
  const gradient = shouldGradient(customText.color, colorMode);

  drawCustomTextElement(customModel.id, customTextElement, gradient);
  drawSelectionBox(customModel.id, customTextElement.id);

  return { ...customModel };
}

export function updateModelConfig(
  customModel: CustomModel,
  modelConfig: ModelConfig,
): CustomModel {
  return {
    ...customModel,
    model: {
      ...customModel.model,
      modelConfig,
    },
  };
}

export function updateColorLayerConfig(
  customModel: CustomModel,
  colorLayerId: string,
  updates: ColorLayerConfigUpdate,
): CustomModel {
  const colorLayer = customModel.colorLayers.find(
    (cl) => cl.id === colorLayerId,
  );
  if (!colorLayer) return customModel;

  let colorLayerConfig = customModel.model.colorLayersConfig.find(
    (clg) => clg.colorLayerId === colorLayerId,
  );
  if (!colorLayerConfig) {
    customModel.model.colorLayersConfig = [
      ...customModel.model.colorLayersConfig,
      {
        colorLayerId: colorLayerId,
        ...updates,
      },
    ];
  } else {
    colorLayerConfig = {
      colorLayerId: colorLayerId,
      ...updates,
    };
  }

  colorLayer.focusText = updates.focusText;
  colorLayer.menuText = updates.menuText;
  colorLayer.colorModes = updates.colorModes;
  colorLayer.colorList = updates.colorList;
  colorLayer.patterns = updates.patterns;

  return { ...customModel };
}
