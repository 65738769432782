import { Logo } from "@/types/custom-element.ts";
import { LogoFilters } from "@components/logos/logo-list-filters.tsx";
import { api } from "@lib/api.ts";
import { filterLogos } from "@lib/logos.ts";
import { useEffect, useState } from "react";

export default function useLogos() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [allLogos, setAllLogos] = useState<Logo[]>([]);
  const [filteredLogos, setFilteredLogos] = useState<Logo[]>([]);

  const [activeFilters, setActiveFilters] = useState<LogoFilters>({
    searchText: "",
    firstLetters: undefined,
  });

  const [selectMode, setSelectMode] = useState<"all" | "partial" | "none">(
    "none",
  );
  const [selectedLogos, setSelectedLogos] = useState<Logo[]>([]);

  useEffect(() => {
    setLoading(true);
    setError(false);

    api
      .getLogos()
      .then((logos) => {
        setAllLogos(logos);
        setFilteredLogos(logos);
      })
      .catch((err) => {
        console.log("Error while fetching logos");
        console.error(err);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function handleFilter(filters: LogoFilters) {
    setActiveFilters(filters);
    setSelectedLogos([]);
    setSelectMode("none");
    if (!filters.searchText && !filters.firstLetters) {
      setFilteredLogos(allLogos);
    } else {
      setFilteredLogos(filterLogos(allLogos, filters));
    }
  }

  function handleSelectLogos(logos: Logo[]) {
    setSelectedLogos(logos);
    setSelectMode(
      logos.length === filteredLogos.length
        ? "all"
        : logos.length === 0
          ? "none"
          : "partial",
    );
  }

  function resetFiltersAndSelection() {
    setActiveFilters({ searchText: "", firstLetters: undefined });
    setSelectedLogos([]);
    setSelectMode("none");
  }

  function addLogos(logos: Logo[]) {
    setAllLogos([...allLogos, ...logos]);
    setFilteredLogos([...allLogos, ...logos]);
    resetFiltersAndSelection();
  }

  function removeLogos(logos: Logo[]) {
    setAllLogos(allLogos.filter((logo) => !logos.includes(logo)));
    setFilteredLogos(allLogos.filter((logo) => !logos.includes(logo)));
    resetFiltersAndSelection();
  }

  return {
    allLogos,
    filteredLogos,
    activeFilters,
    loading,
    error,
    handleFilter,
    selectedLogos,
    selectMode,
    handleSelectLogos,
    addLogos,
    removeLogos,
  };
}
