import { Model } from "@/types/model.ts";
import { ShopifyProductLink } from "@/types/shopify.ts";
import ShopifyProductLinkModelPicker from "@admin/components/shopify/shopify-product-link/shopify-product-link-model-picker.tsx";
import ShopifyProductLinkModel from "@admin/components/shopify/shopify-product-link/shopify-product-link-model.tsx";
import ShopifyProductLinkProduct from "@admin/components/shopify/shopify-product-link/shopify-product-link-product.tsx";
import { Button } from "@components/ui/button.tsx";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@components/ui/dialog.tsx";
import { Separator } from "@components/ui/separator.tsx";
import { cn } from "@lib/utils.ts";
import { DialogProps } from "@radix-ui/react-dialog";
import { Link as LuLink, Unlink } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";

type ShopifyProductLinkDialogProps = DialogProps & {
  dialogContentClassName?: string;
  productLink: ShopifyProductLink;
  onLink: (productLink: ShopifyProductLink, modelToLink: Model) => void;
  onUnlink: (productLink: ShopifyProductLink) => void;
};

export default function ShopifyProductLinkDialog({
  dialogContentClassName,
  productLink: { product, model },
  onLink,
  onUnlink,
  ...props
}: ShopifyProductLinkDialogProps) {
  const [modelToLink, setModelToLink] = useState<Model>();

  function handleLinkClick() {
    if (!modelToLink) return;
    onLink({ product, model: modelToLink }, modelToLink);
    setModelToLink(undefined);
  }

  return (
    <Dialog {...props}>
      <DialogContent
        className={cn(
          "max-h-[95dvh] max-w-[95dvw] overflow-y-auto text-sm text-slate-700 xl:max-w-[1280px]",
          dialogContentClassName,
        )}
      >
        <DialogHeader>
          <DialogTitle className="mb-1">
            Associer <span className="font-black">{product.title}</span> à un
            modèle
          </DialogTitle>
          <DialogDescription>
            Lorsqu'un produit est associé à un modèle, un bouton CONFIGURER
            appraîtra automatiquement sur la page produit de la boutique en
            ligne.
          </DialogDescription>
        </DialogHeader>

        <div className="grid grid-cols-[1fr_5px_1fr] gap-8">
          <ShopifyProductLinkProduct product={product} />

          <Separator
            className="h-[97%] w-[2px] self-end"
            orientation="vertical"
          />

          <>
            {model ? (
              <ShopifyProductLinkModel model={model} />
            ) : (
              <ShopifyProductLinkModelPicker
                model={modelToLink}
                onModelSelect={setModelToLink}
              />
            )}
          </>
        </div>

        <DialogFooter className="items-center">
          {model ? (
            <>
              <Link
                to={product.onlineStorePreviewUrl}
                target="_blank"
                className="mr-6 underline underline-offset-2 after:content-['_↗'] hover:opacity-75"
              >
                Voir dans la boutique
              </Link>

              <Button
                variant="destructive"
                className="uppercase"
                onClick={() => onUnlink({ product, model })}
              >
                <Unlink className="mr-2 h-3.5 w-3.5" />
                Dé-associer
              </Button>
            </>
          ) : (
            <Button
              variant="primary"
              className="uppercase"
              disabled={!modelToLink || !!modelToLink.shopifyProduct}
              onClick={handleLinkClick}
            >
              <LuLink className="mr-2 h-3.5 w-3.5" />
              Associer
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
