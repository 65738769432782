import { ColorMode } from "@/types/color-elements.ts";
import printingBasePreview from "@assets/printing-base-preview.png";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/ui/popover.tsx";
import { cn } from "@lib/utils.ts";
import { CircleHelp } from "lucide-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

type ColorLayerConfigColorModePickerProps = Omit<
  React.ComponentPropsWithoutRef<"div">,
  "onChange"
> & {
  colorModes: ColorMode[];
  value: ColorMode;
  onChange: (value: ColorMode) => void;
};

export default function ColorLayerConfigColorModePicker({
  className,
  colorModes,
  value,
  onChange,
  ...props
}: ColorLayerConfigColorModePickerProps) {
  const { t } = useTranslation("steps-menu-color-layer");

  return (
    <div className={cn("", className)} {...props}>
      {/* color mode help popover */}
      <Popover>
        <PopoverTrigger className="group mb-1 cursor-pointer" asChild>
          <div className="flex items-center justify-between">
            <span className="text-lg font-semibold underline-offset-2 group-hover:underline">
              {t("printing-base")}
            </span>
            <CircleHelp className="group-hover:text-accent" />
          </div>
        </PopoverTrigger>
        <PopoverContent
          className="flex max-h-[95dvh] w-[600px] max-w-[calc(100dvw-265px)] flex-col gap-3 overflow-auto"
          side="right"
          align="start"
          sideOffset={40}
        >
          <div className="leading-5">
            <Trans ns="steps-menu-color-layer" i18nKey="printing-base-help">
              C'est juste un aperçu. <br /> Choisis ta base d'impression sur le
              site après avoir valider ta configuration :
            </Trans>
          </div>
          <div className="relative w-full object-contain">
            <img
              src={printingBasePreview}
              alt="Base d'impression disponibles sur le site"
              className="h-full w-full rounded border"
            />
          </div>
          <a
            href="https://www.mxlab.fr/pages/material"
            target="_blank"
            className="cursor-pointer self-end text-primary underline-offset-2 after:content-['_↗'] hover:underline"
          >
            {t("printing-base-examples-link")}
          </a>
        </PopoverContent>
      </Popover>

      <div className="flex flex-wrap items-center gap-x-3">
        {colorModes.map((colorMode) => (
          <label
            key={colorMode}
            className="flex cursor-pointer items-center gap-2 underline-offset-4 hover:underline"
          >
            <input
              type="radio"
              name="colorMode"
              value={colorMode}
              checked={value === colorMode}
              onChange={(e) => onChange(e.target.value as ColorMode)}
            />
            {t(colorMode, { ns: "color-modes" })}
          </label>
        ))}
      </div>
    </div>
  );
}
