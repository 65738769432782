import { CustomModel } from "@/types/custom-model.ts";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@components/ui/dialog.tsx";
import { Input } from "@components/ui/input.tsx";
import { Label } from "@components/ui/label.tsx";
import MxlabLoader from "@components/ui/mxlab-loader.tsx";
import MyButton from "@components/ui/my-button.tsx";
import OvalLoader from "@components/ui/oval-loader.tsx";
import CustomModelSvgInjector from "@custom-model-editor/components/custom-model-svg-injector.tsx";
import ConfirmExportExistingModelDialog from "@custom-model-editor/components/models/confirm-export-existing-model-dialog.tsx";
import { useExportModel } from "@custom-model-editor/hooks/use-export-model.ts";
import { cn } from "@lib/utils.ts";
import { DialogProps } from "@radix-ui/react-dialog";
import React, { useState } from "react";
import { FaFileExport } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

type ModelExportDialogProps = DialogProps & {
  className?: string;
  customModel: CustomModel;
};

export default function ModelExportDialog({
  className,
  customModel,
  ...props
}: ModelExportDialogProps) {
  const [open, setOpen] = useState<boolean>(false);

  const {
    formValues,
    setFormValues,
    loading,
    showConfirmExportDialog,
    setShowConfirmExportDialog,
    confirmExport,
    exportModel,
  } = useExportModel(customModel);

  const navigate = useNavigate();

  async function submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    // confirm export only when editing an existing model
    const confirmed = confirmExport();
    if (confirmed) {
      await handleConfirm();
    }
  }

  async function handleConfirm(exportAsNew = true) {
    await exportModel(exportAsNew);
    setOpen(false);
    navigate("/admin/models");
  }

  const SvgLoader = () => (
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
      <OvalLoader width={100} height={100} />
    </div>
  );

  return (
    <>
      <Dialog {...props} open={open} onOpenChange={(_open) => setOpen(_open)}>
        <DialogTrigger className={cn("hover:opacity-75", className)}>
          <FaFileExport className="h-5 w-5" />
        </DialogTrigger>

        <DialogContent
          className={cn("!max-w-[920px]", { "pointer-events-none": loading })}
        >
          {/* loading overlay */}
          {loading ? (
            <div
              className={cn(
                "absolute bottom-0 left-0 right-0 top-0",
                "grid place-items-center",
                "rounded-lg bg-transparent/50",
                "z-50",
              )}
            >
              <MxlabLoader color="white" text="Export du modèle en cours..." />
            </div>
          ) : null}

          <DialogHeader>
            <DialogTitle className="mb-2 text-xl">
              Exporter comme modèle
            </DialogTitle>
            <DialogDescription className="text-base">
              Créé un nouveau modèle à partir de ta customisation
            </DialogDescription>
          </DialogHeader>

          {/* model preview */}
          <CustomModelSvgInjector
            customModel={{
              ...customModel,
              id: `${customModel.id}-preview-export-2`,
            }}
            Loader={SvgLoader}
            interactive={false}
          />

          <form
            id="export-model-form"
            className="flex items-center gap-4"
            onSubmit={submitForm}
          >
            <Label>
              <span className="text-base">Nom du modèle</span>
              <Input
                className="mt-1 w-[200px]"
                placeholder="ex: KTM Lab 1"
                value={formValues.name}
                onChange={(e) =>
                  setFormValues({ ...formValues, name: e.target.value })
                }
                required
              />
            </Label>

            <Label className="flex flex-col gap-1">
              <span className="text-base">Marque (optionnel)</span>
              <Input
                className="mt-1 w-[200px]"
                placeholder="ex: KTM"
                value={formValues.brand}
                onChange={(e) =>
                  setFormValues({ ...formValues, brand: e.target.value })
                }
              />
            </Label>
          </form>

          <DialogFooter>
            <DialogClose asChild>
              <MyButton className="mr-2 bg-transparent uppercase text-slate-600">
                Annuler
              </MyButton>
            </DialogClose>

            <MyButton
              className="uppercase"
              type="submit"
              form="export-model-form"
            >
              Exporter
            </MyButton>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* confirm if we export as new model or replace existing one */}
      <ConfirmExportExistingModelDialog
        open={showConfirmExportDialog}
        onOpenChange={(open) => setShowConfirmExportDialog(open)}
        onExportAsNew={() => handleConfirm(true)}
        onReplaceExisting={() => handleConfirm(false)}
      />
    </>
  );
}
