import { cn } from "@/lib/utils";
import { Button } from "@components/ui/button.tsx";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@components/ui/command.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/ui/popover.tsx";
import SearchInput from "@components/ui/search-input.tsx";
import { Check, ChevronsUpDown, FilterX } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

export type ModelFilters = {
  searchText: string;
  brand?: string;
};

type ModelListFiltersProps = React.ComponentPropsWithoutRef<"div"> & {
  activeFilters: ModelFilters;
  onFilter: (filters: ModelFilters) => void;
  brandOptions: string[];
  inputClassName?: string;
};

export default function ModelListFilters({
  className,
  activeFilters,
  onFilter,
  brandOptions = [],
  inputClassName,
  ...props
}: ModelListFiltersProps) {
  const [openBrandsPopover, setOpenBrandsPopover] = useState<boolean>(false);

  const [searchTextValue, setSearchTextValue] = useState<string>(
    activeFilters.searchText,
  );
  const [debounceValue] = useDebounce(searchTextValue, 500);

  function handleSearchTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchTextValue(e.target.value);
  }

  function resetFilters() {
    onFilter({ searchText: "", brand: undefined });
    setSearchTextValue("");
  }

  useEffect(() => {
    onFilter({ ...activeFilters, searchText: debounceValue });
  }, [debounceValue]);

  return (
    <div className={cn("flex items-center gap-3", className)} {...props}>
      <Popover open={openBrandsPopover} onOpenChange={setOpenBrandsPopover}>
        <PopoverTrigger asChild>
          <Button
            className={cn(
              "min-w-[150px] justify-between text-sm normal-case text-slate-600",
            )}
            variant="outline"
            size="sm"
            role="combobox"
            aria-expanded={openBrandsPopover}
          >
            {activeFilters.brand || "Sélectionner une marque..."}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>

        <PopoverContent className="p-0">
          <Command>
            <CommandInput placeholder="Rechercher une marque" />
            <CommandEmpty>Aucune marque trouvée</CommandEmpty>
            <CommandList>
              <CommandGroup>
                {brandOptions
                  .sort((a, b) => a.localeCompare(b))
                  .map((brand) => {
                    return (
                      <CommandItem
                        key={brand}
                        value={brand}
                        onSelect={() => {
                          onFilter({
                            ...activeFilters,
                            brand:
                              activeFilters.brand === brand ? undefined : brand,
                          });
                          setOpenBrandsPopover(false);
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            activeFilters.brand === brand
                              ? "opacity-100"
                              : "opacity-0",
                          )}
                        />
                        {brand}
                      </CommandItem>
                    );
                  })}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>

      <SearchInput
        id="search-text-models"
        className={cn("w-[400px]", inputClassName)}
        placeholder="Rechercher par nom ou marque..."
        value={searchTextValue}
        onChange={handleSearchTextChange}
      />

      <Button
        className="border border-slate-600/25 bg-white hover:bg-slate-100"
        onClick={resetFilters}
        size="icon"
      >
        <FilterX className="h-4 w-4 stroke-slate-700" />
      </Button>
    </div>
  );
}
