import { Button } from "@components/ui/button.tsx";
import { firebaseService } from "@lib/firebase.ts";

export default function AdminSettingsRoute() {
  return (
    <div className="mx-auto max-w-[768px]">
      <div className="mb-2 text-xl font-black uppercase text-black">
        Paramètres
      </div>
      <div
        className="overflow-y-auto rounded-lg bg-white p-4 text-slate-950"
        style={{ height: `calc(100dvh - 5.5rem)` }}
      >
        <div className="flex h-full flex-col">
          <div className="grid grid-cols-2">
            <div>Version</div>
            <div>{import.meta.env.VITE_APP_VERSION}</div>
          </div>

          <Button
            className="mt-auto self-end uppercase"
            variant="destructive"
            size="sm"
            onClick={firebaseService.signOut}
          >
            Déconnexion
          </Button>
        </div>
      </div>
    </div>
  );
}
