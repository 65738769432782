import {
  Color,
  ColorElement,
  ColorGroup,
  ColorMode,
  Pattern,
} from "@/types/color-elements.ts";
import ColorAccordion from "@custom-model-editor/components/color-elements/color-accordion.tsx";
import ColorGroupLink from "@custom-model-editor/components/colors-groups/color-group-link.tsx";
import { useColorElementExpandedList } from "@custom-model-editor/hooks/use-color-element-expanded-list.ts";
import { useCustomModelEditorContext } from "@custom-model-editor/hooks/use-custom-model-editor-context.ts";
import {
  sortColorElements,
  sortColorGroups,
} from "@custom-model-editor/lib/color-group.ts";
import { cn, normalizeStr } from "@lib/utils.ts";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleLeft } from "react-icons/fa6";

type ColorGroupListProps = React.ComponentPropsWithoutRef<"div"> & {
  colorGroups: ColorGroup[];
  colorMode: ColorMode;

  colorList: Color[];
  onColorClick: (colorElement: ColorElement, color: Color) => void;
  onColorOver: (colorElement: ColorElement, color: Color) => void;
  onColorOut: (colorElement: ColorElement) => void;

  patternList: Pattern[];
  onPatternClick: (
    colorElement: ColorElement,
    pattern: Pattern | undefined,
  ) => void;
  onPatternOver: (colorElement: ColorElement, pattern: Pattern) => void;
  onPatternOut: (colorElement: ColorElement) => void;
};

export default function ColorGroupList({
  className,
  colorGroups,
  colorMode,
  colorList,
  onColorClick,
  onColorOver,
  onColorOut,
  patternList,
  onPatternClick,
  onPatternOver,
  onPatternOut,
  ...props
}: ColorGroupListProps) {
  const [colorGroupSelected, setColorGroupSelected] = useState<ColorGroup>();

  const {
    state: { colorElementSelected },
  } = useCustomModelEditorContext();

  const { expandedList, handleColorAccordionChange } =
    useColorElementExpandedList();

  const { t } = useTranslation("steps-menu-color-layer");

  useEffect(() => {
    setColorGroupSelected(
      colorGroups.find((cg) =>
        cg.colorElements.some((ce) => ce.id === colorElementSelected?.id),
      ),
    );
  }, [colorElementSelected, colorGroups]);

  function handleBackClick() {
    setColorGroupSelected(undefined);
  }

  return (
    <div
      className={cn(
        "grid w-[200%] grid-cols-2",
        "duration-200",
        { "-translate-x-1/2": !!colorGroupSelected },

        className,
      )}
      {...props}
    >
      {/* left part: color group links */}
      <div className="flex flex-col gap-1 pr-1">
        {colorGroups.sort(sortColorGroups).map((cg) => (
          <ColorGroupLink
            key={cg.id}
            colorGroup={cg}
            colorMode={colorMode}
            onClick={() => setColorGroupSelected(cg)}
          />
        ))}
      </div>

      {/* right part (translated): color group selected */}
      <div className="flex flex-col gap-1">
        <button
          className={cn(
            "flex items-center justify-center gap-1 self-start",
            "mb-2 ml-2 px-2 py-1",
            "rounded-full text-primary",
            "hover:bg-primary hover:text-white",
          )}
          onClick={handleBackClick}
        >
          <FaAngleLeft className="h-4 w-4" />
          {t("back")}
        </button>

        {colorGroupSelected?.colorElements
          .sort(sortColorElements)
          .map((colorElement) => {
            // set color element name as its parent if it's the only one
            const title =
              colorGroupSelected?.colorElements.length === 1
                ? t(
                    normalizeStr(colorGroupSelected?.name),
                    colorGroupSelected?.name,
                    {
                      ns: "color-group-elements",
                    },
                  )
                : normalizeStr(colorElement.name.split(" ")[0]) === "couleur"
                  ? t(
                      normalizeStr(colorElement.name.split(" ")[0]),
                      colorElement.name,
                      {
                        ns: "color-group-elements",
                        index: colorElement.name.split(" ")[1],
                      },
                    )
                  : t(normalizeStr(colorElement.name), colorElement.name, {
                      ns: "color-group-elements",
                    });

            return (
              <ColorAccordion
                key={colorElement.id}
                expanded={expandedList.includes(colorElement.id)}
                onChange={(expanded) =>
                  handleColorAccordionChange(colorElement.id, expanded)
                }
                title={title}
                activeColor={colorList.find(
                  (c) => c.color === colorElement.color.color,
                )}
                activePattern={colorElement.pattern}
                colorMode={colorMode}
                colorList={colorList}
                onColorClick={(color) => onColorClick(colorElement, color)}
                onColorOver={(color) => onColorOver(colorElement, color)}
                onColorOut={() => onColorOut(colorElement)}
                patternList={patternList}
                onPatternClick={(pattern) =>
                  onPatternClick(colorElement, pattern)
                }
                onPatternOver={(pattern) =>
                  onPatternOver(colorElement, pattern)
                }
                onPatternOut={() => onPatternOut(colorElement)}
              />
            );
          })}
      </div>
    </div>
  );
}
