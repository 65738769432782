import { Button } from "@components/ui/button.tsx";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@components/ui/card.tsx";
import { cn } from "@lib/utils.ts";
import { CircleX, RefreshCcw } from "lucide-react";
import React from "react";
import { useTranslation } from "react-i18next";

type CardErrorProps = React.ComponentPropsWithoutRef<"div">;

export default function CardError({
  className,
  children,
  ...props
}: CardErrorProps) {
  const { t } = useTranslation("card-error");

  return (
    <Card className={cn("w-[350px] text-center", className)} {...props}>
      <CardHeader>
        <CircleX className="mx-auto text-error" size="80" />
      </CardHeader>
      <CardContent>{children}</CardContent>
      <CardFooter>
        <Button
          className="mx-auto"
          variant="destructive"
          onClick={() => window.location.reload()}
        >
          <RefreshCcw className="mr-2" size="20" />
          {t("refresh-btn")}
        </Button>
      </CardFooter>
    </Card>
  );
}
