import { useAdminContext } from "@admin/hooks/use-admin-context.ts";
import React from "react";

type AdminGuardProps = {
  children: React.ReactNode;
};

/**
 * Prevent children from rendering if the user is not an admin
 */
export default function AdminGuard({ children }: AdminGuardProps) {
  const {
    state: { isLogged },
  } = useAdminContext();

  return <>{isLogged ? children : null}</>;
}
