import { CUSTOM_TEXT_FONT_SIZE_STEP } from "@/config.ts";
import { CustomElementUpdate } from "@/types/custom-element.ts";
import { Button } from "@components/ui/button.tsx";
import NumberInput from "@components/ui/number-input.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@components/ui/tooltip.tsx";
import { useCustomModelEditorContext } from "@custom-model-editor/hooks/use-custom-model-editor-context.ts";
import { cn } from "@lib/utils.ts";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import {
  AArrowUp,
  Copy,
  MoveHorizontal,
  MoveVertical,
  RotateCwSquare,
  Trash,
} from "lucide-react";
import React from "react";
import { useTranslation } from "react-i18next";

type CustomElementEditorProps = React.ComponentPropsWithoutRef<"div">;

export default function CustomElementEditor({
  className,
  ...props
}: CustomElementEditorProps) {
  const {
    state: { elementSelected },
    dispatch,
  } = useCustomModelEditorContext();

  const { t } = useTranslation("custom-element-editor");

  const fontSize =
    elementSelected?.type === "custom-text"
      ? elementSelected.customText.fontSize
      : elementSelected?.type === "custom-numbers"
        ? elementSelected.customNumbers.fontSize
        : undefined;

  function handleCustomElementChange(
    value: string,
    prop: keyof CustomElementUpdate,
  ) {
    if (!elementSelected) return;
    if (isNaN(Number(value))) return;

    dispatch({
      type: "update_element",
      payload: {
        elementId: elementSelected.id,
        updates: { [prop]: Number(value) },
      },
    });
  }

  function handleFontSizeChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!elementSelected) return;

    const value = Number(event.target.value);
    if (isNaN(value)) return;

    if (elementSelected.type === "custom-text") {
      dispatch({
        type: "update_custom_text",
        payload: {
          customTextId: elementSelected.id,
          updates: {
            fontSize: value,
          },
        },
      });
    } else if (elementSelected.type === "custom-numbers") {
      dispatch({
        type: "update_custom_numbers",
        payload: {
          customNumbersId: elementSelected.id,
          updates: {
            fontSize: value,
          },
        },
      });
    }
  }

  function handleRemove() {
    if (!elementSelected) return;
    dispatch({ type: "remove_element", payload: elementSelected.id });
  }

  function handleDuplicate() {
    if (!elementSelected) return;
    dispatch({ type: "duplicate_element", payload: elementSelected.id });
  }

  return elementSelected ? (
    <div
      className={cn(
        "absolute left-2 top-2 lg:left-4 lg:top-16 xl:left-6 xl:top-20",
        "max-h-[95dvh] w-fit animate-custom-element-editor",
        "rounded-lg bg-[#323232] text-sm lg:text-base",
        "z-10 overflow-auto",
        className,
      )}
      {...props}
    >
      <div className="grid grid-cols-[35px_auto] items-center gap-0 p-2 lg:gap-2 lg:px-3 lg:py-2 xl:px-4">
        <div className="col-span-2 font-semibold">{t("position")}</div>

        <div className="text-center font-bold uppercase">x</div>
        <NumberInput
          className="w-[75px] lg:w-[85px]"
          value={Math.round(elementSelected.x).toString()}
          onChange={(e) => handleCustomElementChange(e.target.value, "x")}
        />

        <div className="text-center font-bold uppercase">y</div>
        <NumberInput
          className="w-[75px] lg:w-[85px]"
          value={Math.round(elementSelected.y).toString()}
          onChange={(e) => handleCustomElementChange(e.target.value, "y")}
        />

        {elementSelected.type === "logo" ? (
          <>
            <div className="col-span-2 font-semibold">{t("logo-size")}</div>

            <MoveHorizontal className="justify-self-center" />
            <div className="relative">
              <NumberInput
                className="w-[75px] lg:w-[85px]"
                value={Math.round(elementSelected.width).toString()}
                onChange={(e) =>
                  handleCustomElementChange(e.target.value, "width")
                }
              />
              <span className="pointer-events-none absolute bottom-2 right-9 text-slate-950 lg:bottom-1 xl:-bottom-0.5 xl:right-0.5">
                px
              </span>
            </div>

            <MoveVertical className="justify-self-center" />
            <div className="relative">
              <NumberInput
                className="w-[75px] lg:w-[85px]"
                value={Math.round(elementSelected.height).toString()}
                onChange={(e) =>
                  handleCustomElementChange(e.target.value, "height")
                }
              />
              <span className="pointer-events-none absolute bottom-2 right-9 text-slate-950 lg:bottom-1 xl:-bottom-0.5 xl:right-0.5">
                px
              </span>
            </div>
          </>
        ) : null}

        <div className="col-span-2 mt-1 font-semibold">{t("rotation")}</div>

        <RotateCwSquare className="justify-self-center" />
        <div className="relative">
          <NumberInput
            className="w-[75px] lg:w-[85px]"
            value={Math.round(elementSelected.a).toString()}
            onChange={(e) => handleCustomElementChange(e.target.value, "a")}
          />
          <span className="pointer-events-none absolute right-8 top-2 text-4xl font-light text-slate-950 lg:top-1 xl:-top-0.5 xl:right-0">
            °
          </span>
        </div>

        {fontSize !== undefined ? (
          <>
            <div className="col-span-2 mt-1 font-semibold">
              {t("font-size")}
            </div>
            <AArrowUp className="justify-self-center" />
            <div className="relative">
              <NumberInput
                className="w-[75px] lg:w-[85px]"
                value={fontSize === 0 ? "" : fontSize.toString()}
                onChange={handleFontSizeChange}
                step={CUSTOM_TEXT_FONT_SIZE_STEP}
                min={0}
              />
              <span className="pointer-events-none absolute bottom-2 right-9 text-slate-950 lg:bottom-1 xl:-bottom-0.5 xl:right-0.5">
                px
              </span>
            </div>
          </>
        ) : null}
      </div>

      {/* actions (duplicate + remove) */}
      <div className="flex items-center justify-end gap-3 px-3 lg:py-1">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                className="px-0 hover:text-white hover:opacity-75"
                variant="ghost"
                onClick={handleDuplicate}
              >
                <Copy size="24" />
              </Button>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent className="uppercase">
                {t("duplicate-tooltip")}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </TooltipProvider>

        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                className="px-0 hover:text-white hover:opacity-75"
                variant="ghost"
                onClick={handleRemove}
              >
                <Trash size="24" />
              </Button>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent className="uppercase">
                {t("delete-tooltip")}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
  ) : null;
}
