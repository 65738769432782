import { CustomModel } from "@/types/custom-model.ts";
import { Model } from "@/types/model.ts";
import { useToast } from "@components/ui/use-toast.ts";
import { api } from "@lib/api.ts";
import { dataURLtoBlob, isValidUrl, makeId } from "@lib/utils.ts";
import { useState } from "react";
import { useLocation } from "react-router-dom";

type FormValues = {
  name: string;
  brand?: string;
};

export const useExportModel = (customModel: CustomModel) => {
  const [formValues, setFormValues] = useState<FormValues>({
    name: customModel.model.name || "",
    brand: customModel.model.brand || "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [showConfirmExportDialog, setShowConfirmExportDialog] =
    useState<boolean>(false);

  const location = useLocation();
  const { toast } = useToast();

  function confirmExport(): boolean {
    const isNew = location.pathname === "/admin/models/new";
    if (!isNew) {
      setShowConfirmExportDialog(true);
      return false;
    }
    return true;
  }

  async function exportModel(exportAsNew = true) {
    if (loading) return;
    setLoading(true);

    const { name, brand } = formValues;

    try {
      let svgSrc;

      if (!isValidUrl(customModel.model.svgSrc)) {
        svgSrc = await api.uploadModelSVG(
          name,
          dataURLtoBlob(customModel.model.svgSrc),
        );
      } else {
        svgSrc = customModel.model.svgSrc;
      }

      const newModel: Model = {
        id: exportAsNew ? makeId(20) : customModel.model.id,
        svgSrc,
        name,
        brand,
        modelConfig: customModel.model.modelConfig,
        colorLayersConfig: customModel.model.colorLayersConfig,
        defaultCustomization: {
          colorLayers: customModel.colorLayers,
          elements: customModel.elements,
        },
      };

      if (exportAsNew) {
        await api.createModel(newModel);
      } else {
        await api.updateModel(newModel);
      }

      toast({
        title: "Modèle exporté",
        description: exportAsNew
          ? `Le modèle ${newModel.name} a été exporté`
          : `Le modèle ${newModel.name} a été mis à jour`,
      });

      setFormValues({ name: "", brand: "" });
    } catch (err) {
      console.log("Error exporting model");
      console.error(err);

      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de l'export du modèle",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  }

  return {
    formValues,
    setFormValues,
    loading,
    showConfirmExportDialog,
    setShowConfirmExportDialog,
    confirmExport,
    exportModel,
  };
};
