import { CustomModelEditorMode } from "@/types/custom-model-editor.ts";
import { CustomModel } from "@/types/custom-model.ts";
import { Model } from "@/types/model.ts";
import Sidebar from "@components/layout/sidebar.tsx";
import MxlabLoader from "@components/ui/mxlab-loader.tsx";
import AskLandscape from "@custom-model-editor/components/ask-landscape.tsx";
import CardError from "@custom-model-editor/components/card-error.tsx";
import CustomModelEditor from "@custom-model-editor/components/custom-model-editor.tsx";
import SavedCustomizationCard from "@custom-model-editor/components/saved-customization-card.tsx";
import { useTitle } from "@hooks/use-title.ts";
import { api } from "@lib/api.ts";
import * as Sentry from "@sentry/browser";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams, useSearchParams } from "react-router-dom";

export default function CustomizationRoute() {
  const [customModelLoading, setCustomModelLoading] = useState<boolean>(false);
  const [fontsLoading, setFontsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [customModel, setCustomModel] = useState<CustomModel>();
  const [savedCustomModel, setSavedCustomModel] = useState<CustomModel>();
  const [notFound, setNotFound] = useState<boolean>(false);

  const { customModelId: customModelIdParam } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  useTitle(customModel?.model.name || "MXlab - Custom Lab");
  const { t } = useTranslation("customization-route");

  // TODO: default model id via settings
  const { modelIdParam, mode, skipSave } = useMemo(
    () => ({
      modelIdParam: searchParams.get("model"),
      mode: (searchParams.get("mode") || "edit") as CustomModelEditorMode, // read-only or edit
      skipSave: searchParams.get("skip_save") === "true",
    }),
    [searchParams],
  );

  useEffect(() => {
    loadFonts();

    setCustomModelLoading(true);
    setError(false);

    if (mode === "read-only") {
      fromCustomModelId(customModelIdParam as string);
      return;
    }

    if (!skipSave && modelIdParam) {
      // check if there is already a saved custom model
      const savedCustomModel = api.getSavedCustomModel(modelIdParam);
      if (savedCustomModel) {
        setSavedCustomModel(savedCustomModel);
        setCustomModelLoading(false);
        return;
      }
    }

    if (customModelIdParam) {
      fromCustomModelId(customModelIdParam);
    } else if (modelIdParam) {
      fromModelId(modelIdParam);
    } else {
      setCustomModelLoading(false);
      setNotFound(true);
    }
  }, []);

  /**
   * little trick used to wait for the fonts to be loaded before returning
   * or the text elements will not be correctly positioned
   * (we wait again in the custom model svg injector component after the svg is loaded)
   */
  function loadFonts() {
    setFontsLoading(true);
    document.fonts.ready.then(() => {
      setTimeout(() => setFontsLoading(false), 500);
    });
  }

  function fromCustomModelId(customModelId: string) {
    api
      .getCustomModelById(customModelId)
      .then((customModel) => {
        if (!customModel) setNotFound(true);
        setCustomModel(customModel);
      })
      .catch((err) => {
        console.log("Error loading custom model");
        console.error(err);
        setError(true);

        Sentry.withScope((scope) => {
          scope.setExtra("component", "CustomizationRoute");
          Sentry.captureException(error);
        });
      })
      .finally(() => {
        setCustomModelLoading(false);
      });
  }

  /**
   * Create a new customization based on the model id
   * @param modelId
   */
  function fromModelId(modelId: string) {
    api
      .getModelById(modelId)
      .then((model) => {
        if (!model) setNotFound(true);
        const customModel = api.createCustomModel(model as Model);
        setCustomModel(customModel);
      })
      .catch((err) => {
        console.log("Error loading model");
        console.error(err);
        setError(true);

        Sentry.withScope((scope) => {
          scope.setExtra("component", "CustomizationRoute");
          Sentry.captureException(error);
        });
      })
      .finally(() => {
        setCustomModelLoading(false);
      });
  }

  function handleResumeSavedCustomization() {
    setCustomModel(savedCustomModel);
    setSearchParams({ model: (savedCustomModel as CustomModel).model.id });
    setSavedCustomModel(undefined);
  }

  function handleCancelSavedCustomization() {
    api.deleteSavedCustomModel(modelIdParam as string);
    setSavedCustomModel(undefined);

    if (customModelIdParam) {
      fromCustomModelId(customModelIdParam);
    } else if (modelIdParam) {
      fromModelId(modelIdParam);
    } else {
      setNotFound(true);
    }
  }

  return fontsLoading || customModelLoading ? (
    <>
      <Sidebar>
        <div className="p-2 text-center">{t("loading-msg")}</div>
      </Sidebar>
      <div className="grid place-items-center">
        <MxlabLoader color="black" text={t("loading-model")} />
      </div>
    </>
  ) : error ? (
    <>
      <Sidebar>
        <div className="p-2 text-center">{t("error-sidebar")}</div>
      </Sidebar>
      <div className="grid place-items-center">
        <CardError>{t("error-msg")}</CardError>
      </div>
    </>
  ) : savedCustomModel ? (
    <AskLandscape>
      <Sidebar>
        <div className="p-2 text-center">{t("welcome-msg")}</div>
      </Sidebar>
      <div className="grid place-items-center">
        <SavedCustomizationCard
          customModel={savedCustomModel}
          onResume={handleResumeSavedCustomization}
          onCancel={handleCancelSavedCustomization}
        />
      </div>
    </AskLandscape>
  ) : customModel ? (
    <AskLandscape>
      <CustomModelEditor customModel={customModel} mode={mode} />
    </AskLandscape>
  ) : notFound ? (
    <Navigate to="/not-found" />
  ) : null;
}
