import { LocalizedNotice } from "@/types/model.ts";
import NoticeSummary from "@custom-model-editor/components/notices/notice-summary.tsx";
import { useCustomModelEditorContext } from "@custom-model-editor/hooks/use-custom-model-editor-context.ts";
import { cn } from "@lib/utils.ts";
import { BookOpen } from "lucide-react";
import React from "react";
import { useTranslation } from "react-i18next";

type StepsMenuNoticeProps = React.ComponentPropsWithoutRef<"div">;

export default function StepsMenuNotice({
  className,
  ...props
}: StepsMenuNoticeProps) {
  const {
    state: { stepBarItems, noticeToShow },
    dispatch,
  } = useCustomModelEditorContext();

  const { t } = useTranslation("steps-menu-notice");

  function handleShowNotice(localizedNotice?: LocalizedNotice) {
    dispatch({ type: "set_notice_to_show", payload: localizedNotice });
  }

  return (
    <div
      className={cn(
        "fancy-scrollbar flex h-full flex-col gap-3 overflow-y-auto px-2 xl:gap-4",
        className,
      )}
      {...props}
    >
      <div className="flex items-center justify-center gap-2">
        <BookOpen className="translate-y-[1px]" />
        <span className="text-2xl font-black uppercase">{t("title")}</span>
      </div>

      {stepBarItems.map((stepBarItem) =>
        stepBarItem.localizedNotice ? (
          <NoticeSummary
            key={stepBarItem.title}
            active={noticeToShow?.fr.id === stepBarItem.localizedNotice.fr.id}
            notice={stepBarItem.localizedNotice}
            onShowNotice={handleShowNotice}
          />
        ) : null,
      )}
    </div>
  );
}
