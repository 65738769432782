import {
  CUSTOM_LAB_COLORS,
  CUSTOM_TEXTS_FONTS,
  CUSTOM_TEXT_DEFAULT_COLOR,
  CUSTOM_TEXT_DEFAULT_FONT,
  CUSTOM_TEXT_DEFAULT_FONT_SIZE,
  CUSTOM_TEXT_STROKE_DEFAULT_COLOR,
} from "@/config.ts";
import { CustomText } from "@/types/custom-element.ts";
import { Button } from "@components/ui/button.tsx";
import TextEditor, {
  TextEditorValue,
} from "@custom-model-editor/components/text-editor/text-editor.tsx";
import { useCustomModelEditorContext } from "@custom-model-editor/hooks/use-custom-model-editor-context.ts";
import { getCustomElementsColorMode } from "@custom-model-editor/lib/custom-model.ts";
import {
  addClassToElement,
  makeElementClickable,
  makeElementInteractive,
  makeElementNonInteractive,
  removeClassFromElement,
} from "@custom-model-editor/lib/svg.ts";
import { cn } from "@lib/utils.ts";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type StepsMenuCustomTextsProps = React.ComponentPropsWithoutRef<"div">;

export default function StepsMenuCustomTexts({
  className,
  ...props
}: StepsMenuCustomTextsProps) {
  const [customText, setCustomText] =
    useState<Omit<CustomText, "fontSize">>(initCustomText());

  const { t } = useTranslation("steps-menu-custom-texts");

  function initCustomText(): Omit<CustomText, "fontSize"> {
    return {
      text: "",
      font: CUSTOM_TEXT_DEFAULT_FONT,
      color: CUSTOM_TEXT_DEFAULT_COLOR,
      stroke: {
        color: CUSTOM_TEXT_STROKE_DEFAULT_COLOR,
        width: 0,
      },
      spacing: 0,
    };
  }

  const {
    state: { elementSelected, customModel },
    dispatch,
  } = useCustomModelEditorContext();

  const colorMode = useMemo(
    () => getCustomElementsColorMode(customModel),
    [customModel],
  );

  /**
   * Make the custom text elements interactive when the component mount.
   * Remove the interactivity when the component unmount.
   */
  useEffect(() => {
    const customTextElements = customModel.elements.filter(
      (el) => el.type === "custom-text",
    );

    customTextElements.forEach((el) => {
      addClassToElement(customModel.id, el.id, "interactive");
      makeElementInteractive(customModel.id, el.id);
    });
    makeElementClickable(customModel.id, "custom-texts", true);

    return () => {
      customTextElements.forEach((el) => {
        removeClassFromElement(customModel.id, el.id, "interactive");
        makeElementNonInteractive(customModel.id, el.id);
      });
      makeElementClickable(customModel.id, "custom-texts", false);
    };
  }, [customModel.id, customModel.elements]);

  /**
   * Update the custom text when the element selected changes.
   */
  useEffect(() => {
    if (!elementSelected) {
      setCustomText(initCustomText());
      return;
    }
    if (elementSelected?.type !== "custom-text") return;
    setCustomText(elementSelected.customText);
  }, [elementSelected]);

  function handleAddCustomText() {
    dispatch({
      type: "add_custom_text",
      payload: { ...customText, fontSize: CUSTOM_TEXT_DEFAULT_FONT_SIZE },
    });
  }

  function handleChange(change: TextEditorValue) {
    setCustomText({
      ...change,
      text: change.text,
    });

    if (elementSelected) {
      dispatch({
        type: "update_custom_text",
        payload: {
          customTextId: elementSelected.id,
          updates: { ...change },
        },
      });
    }
  }

  return (
    <div
      className={cn(
        "fancy-scrollbar overflow-y-auto px-1 pb-1 xl:px-2",
        className,
      )}
      style={{ height: !elementSelected ? "calc(100% - 3.5rem)" : "100%" }}
      {...props}
    >
      <TextEditor
        title={elementSelected ? t("update-title") : t("add-title")}
        value={customText}
        onChange={handleChange}
        settings={{
          fontList: CUSTOM_TEXTS_FONTS,
          textColorList: CUSTOM_LAB_COLORS.filter(
            (color) => color.thumbnailUrl === undefined,
          ),
          strokeColorList: CUSTOM_LAB_COLORS.filter(
            (color) => color.thumbnailUrl === undefined,
          ),
          colorMode,
        }}
      />

      {!elementSelected ? (
        <div className={cn("absolute bottom-0 left-0 right-0 p-1.5 lg:p-3")}>
          <Button
            className="h-9 w-full font-semibold uppercase lg:h-10"
            variant="primary"
            onClick={handleAddCustomText}
          >
            {t("add-btn")}
          </Button>
        </div>
      ) : null}
    </div>
  );
}
