import { cn } from "@/lib/utils";
import React, { useMemo } from "react";
import { ErrorCode, FileError } from "react-dropzone";
import { useTranslation } from "react-i18next";

export type FileUploaded = {
  id: string;
  file: File;
  errors?: FileError[];
  imageUrl: string;
  size: number;
};

type FileUploadedCardProps = React.ComponentPropsWithoutRef<"div"> & {
  file: FileUploaded;
  onRemove: (file: FileUploaded) => void;
};

export default function FileUploadedCard({
  className,
  file,
  onRemove,
  ...props
}: FileUploadedCardProps) {
  const { t } = useTranslation("files-import-dialog");

  // get the first error message from the file error code
  const error = useMemo(() => {
    if (!file.errors) return;
    switch (file.errors[0].code) {
      case ErrorCode.FileInvalidType:
        return t("error-file-invalid-type");
      case ErrorCode.FileTooLarge:
        return t("error-file-too-large");
      case ErrorCode.FileTooSmall:
        return t("error-file-too-small");
      default:
        return file.errors[0].message;
    }
  }, [file.errors]);

  return (
    <div
      className={cn(
        "rounded bg-[#696969] p-1 text-sm lg:text-base xl:p-2",
        className,
      )}
      {...props}
    >
      <div className="flex gap-4">
        <img
          src={file.imageUrl}
          alt={file.file.name}
          className="h-[50px] w-[50px] object-contain lg:h-[75px] lg:w-[75px] xl:h-[100px] xl:w-[100px]"
        />
        <div className="flex flex-col overflow-hidden">
          <span
            className={cn("overflow-hidden text-ellipsis whitespace-nowrap", {
              "text-error": !!file.errors,
            })}
          >
            {file.file.name}
          </span>
          <span>{(file.file.size / 1024).toFixed(2)} KB</span>
          <span
            className="mt-auto cursor-pointer font-light underline-offset-2 hover:underline"
            onClick={() => onRemove(file)}
          >
            {t("remove-file")}
          </span>
        </div>
      </div>

      {error ? (
        <div className="mt-2 text-center text-error">{error}</div>
      ) : null}
    </div>
  );
}
