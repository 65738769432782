import { ShopifyProduct } from "@/types/shopify.ts";
import ShopifyProductStatusBadge from "@admin/components/shopify/shopify-product-status-badge.tsx";
import { Badge } from "@components/ui/badge.tsx";
import { cn } from "@lib/utils.ts";
import React from "react";

type ShopifyProductLinkProductProps = React.ComponentPropsWithoutRef<"div"> & {
  product: ShopifyProduct;
  imageClassName?: string;
};

export default function ShopifyProductLinkProduct({
  className,
  imageClassName,
  product,
  ...props
}: ShopifyProductLinkProductProps) {
  return (
    <div className={cn("flex flex-col gap-3", className)} {...props}>
      {product.featuredImage ? (
        <img
          src={product.featuredImage.url}
          alt="Image de l'article"
          className={cn(
            "max-h-[350px] w-auto rounded-lg border object-contain",
            imageClassName,
          )}
        />
      ) : (
        <div className="grid h-[300px] w-auto place-items-center rounded-lg border text-2xl">
          ?
        </div>
      )}

      <div className="mt-2 flex items-center gap-2">
        <span className="text-lg font-semibold text-slate-950">
          {product.title}
        </span>
        <ShopifyProductStatusBadge status={product.status} />
      </div>

      {product.description ? (
        <div className="max-h-[120px] overflow-auto rounded-lg border p-2">
          {product.description}
        </div>
      ) : null}

      {product.productType ? (
        <div>
          <span className="font-semibold">Type :</span> {product.productType}
        </div>
      ) : null}

      {product.tags?.length ? (
        <div className="flex items-center gap-2">
          <span className="font-semibold">Tags :</span>
          <div className="flex flex-wrap items-center gap-2">
            {product.tags.map((tag) => (
              <Badge key={tag}>{tag}</Badge>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}
