import { DEFAULT_MODEL_COLOR_LAYERS_CONFIG } from "@/config.ts";
import { Model } from "@/types/model.ts";
import { cn, makeId } from "@lib/utils.ts";
import React from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineCloudUpload } from "react-icons/ai";

type ModelImportProps = React.ComponentPropsWithoutRef<"div"> & {
  onModelImport: (model: Model) => void;
};

export default function ModelImport({
  className,
  onModelImport,
  ...props
}: ModelImportProps) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/svg+xml": [".svg"],
    },
    maxFiles: 1,
    multiple: false,
    onDropAccepted: handleDropAccepted,
  });

  async function handleDropAccepted(files: File[]) {
    const svg = files[0];

    const url = await new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(svg);
      reader.onload = () => resolve(reader.result as string);
    });

    const newModel: Model = {
      id: makeId(20),
      name: "",
      svgSrc: url,
      modelConfig: {
        logos: true,
        customNumbers: true,
        customTexts: true,
      },
      colorLayersConfig: DEFAULT_MODEL_COLOR_LAYERS_CONFIG,
    };

    onModelImport(newModel);
  }

  return (
    <div
      className={cn(
        "h-[300px] w-[400px]",
        "bg-[#f8fafcbb]",
        "rounded-lg border-4 border-dashed border-[#ccc]",
        "text-center text-[#555]",
        "cursor-pointer hover:opacity-75",
        className,
      )}
      {...props}
    >
      <div
        {...getRootProps({
          className: "dropzone w-full h-full grid place-items-center p-8",
        })}
      >
        <input {...getInputProps()} />
        <div className="flex flex-col items-center justify-center gap-2">
          <AiOutlineCloudUpload className="text-4xl" />
          <p>Clique ou dépose ton SVG ici</p>
        </div>
      </div>
    </div>
  );
}
