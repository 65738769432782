import { Logo } from "@/types/custom-element.ts";
import { Button } from "@components/ui/button.tsx";
import { Checkbox } from "@components/ui/checkbox.tsx";
import { cn } from "@lib/utils.ts";
import { Minus } from "lucide-react";
import React from "react";
import { FaRegTrashCan } from "react-icons/fa6";

type LogoListActionsProps = React.ComponentPropsWithoutRef<"div"> & {
  selectedLogos: Logo[];
  selectMode: "all" | "partial" | "none";
  onSelectAll: (select: boolean) => void;
  onDeleteClick: () => void;
};

export default function LogoListActions({
  className,
  selectedLogos,
  selectMode,
  onSelectAll,
  onDeleteClick,
  ...props
}: LogoListActionsProps) {
  return (
    <div
      className={cn("flex h-[45px] items-center gap-2", className)}
      {...props}
    >
      <Checkbox
        className={cn("h-5 w-5 -translate-y-[1px]")}
        checked={selectMode === "all" || selectMode === "partial"}
        indicator={
          selectMode === "partial" ? <Minus className="h-4 w-4" /> : undefined
        }
        onCheckedChange={(checked) => onSelectAll(!!checked)}
      />

      <span className="text-sm text-slate-950">
        ({selectedLogos.length}) sélectionné(s)
      </span>

      {selectedLogos.length >= 1 && (
        <Button
          className="ml-auto border border-slate-600/25 bg-white hover:bg-slate-100"
          onClick={onDeleteClick}
          size="icon"
        >
          <FaRegTrashCan className="fill-slate-700" />
        </Button>
      )}
    </div>
  );
}
