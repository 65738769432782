import { CustomModel } from "@/types/custom-model.ts";
import CustomModelPreview from "@components/custom-models/custom-model-preview.tsx";
import MxlabLoader from "@components/ui/mxlab-loader.tsx";
import { focusColorLayer } from "@custom-model-editor/lib/custom-model.ts";
import { downloadCanvasAsPng, svgToPng } from "@lib/svg-to-png.ts";
import { cn } from "@lib/utils.ts";
import * as Sentry from "@sentry/browser";
import React, { createContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

export type ScreenshotOptions = {
  colorLayerId?: string; // if defined, exclude all other layers from screenshot
  download?: boolean; // if true, download the screenshot
  callback?: (canvas: HTMLCanvasElement) => void; // callback after screenshot
};

export type ScreenshotContextType = {
  screenshot(customModel: CustomModel, options?: ScreenshotOptions): void;
};

export const ScreenshotContext = createContext({} as ScreenshotContextType);

export function ScreenshotProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [customModel, setCustomModel] = useState<CustomModel>();
  const [options, setOptions] = useState<ScreenshotOptions>();

  const { t } = useTranslation("screenshots");

  function screenshot(customModel: CustomModel, options: ScreenshotOptions) {
    setCustomModel(customModel);
    setOptions(options);
  }

  async function onLoad() {
    if (!customModel) return;

    setLoading(true);

    try {
      setLoading(true);
      // only screenshot the color layer id in options
      if (options?.colorLayerId) {
        focusColorLayer(
          { ...customModel, id: `${customModel.id}-preview` },
          options.colorLayerId,
        );
      }

      const canvas = await svgToPng(
        `${customModel.id}-preview`,
        "#screenshot .svg-wrapper",
      );

      if (options?.download) {
        downloadCanvasAsPng(canvas, `${customModel.id}.png`);
      }
      if (options?.callback) {
        options.callback(canvas);
      }
    } catch (err) {
      console.log("Error while screenshot");
      console.error(err);
      toast.error(t("error-toast-msg"), {
        duration: Number.POSITIVE_INFINITY,
        closeButton: true,
      });
      Sentry.captureException(err);
    } finally {
      setLoading(false);
      setCustomModel(undefined);
      setOptions(undefined);
    }
  }

  return (
    <ScreenshotContext.Provider value={{ screenshot }}>
      {children}

      {/* modal */}
      {customModel ? (
        <CustomModelPreview
          id="screenshot"
          customModel={customModel}
          onLoad={onLoad}
        />
      ) : null}

      {/* loading */}
      {customModel && loading ? (
        <>
          <div
            className={cn(
              "fixed bottom-0 left-0 right-0 top-0",
              "rounded bg-[#333] px-6 py-2 text-lg",
              "animate-myPulse",
              "z-50",
            )}
          ></div>

          <MxlabLoader
            className={cn(
              "fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
              "z-50",
            )}
            text={t("generating-img-msg")}
            textClassName="bg-[#323232]/75 px-2 rounded-sm"
          />
        </>
      ) : null}
    </ScreenshotContext.Provider>
  );
}
