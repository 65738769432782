import { StepBarItem } from "@/types/custom-model-editor.ts";
import StepBarArrow from "@custom-model-editor/components/step-bar/step-bar-arrow.tsx";
import StepBarNoticeBtn from "@custom-model-editor/components/step-bar/step-bar-notice-btn.tsx";
import StepBarStep from "@custom-model-editor/components/step-bar/step-bar-step.tsx";
import { useCustomModelEditorContext } from "@custom-model-editor/hooks/use-custom-model-editor-context.ts";
import { useForwardRef } from "@hooks/use-forward-ref.ts";
import { cn } from "@lib/utils.ts";
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";

type StepBarProps = React.ComponentPropsWithoutRef<"div">;
const StepBar = forwardRef<HTMLDivElement, StepBarProps>(
  ({ className, ...props }, ref) => {
    const stepBarRef = useForwardRef(ref, null);

    const {
      state: {
        stepBarItems,
        currentStepBarItem,
        customModel: {
          model: { modelConfig },
        },
      },
      dispatch,
    } = useCustomModelEditorContext();

    const { t } = useTranslation("step-bar");

    function handleStepClick(item: StepBarItem | undefined) {
      dispatch({ type: "set_current_step_bar_item", payload: item });
    }

    return (
      <div
        ref={stepBarRef}
        className={cn(
          "absolute bottom-2 left-0 right-0 lg:bottom-5 xl:bottom-8",
          className,
        )}
        {...props}
      >
        <div
          className={cn(
            "flex items-center justify-start gap-x-2 gap-y-1 overflow-x-auto lg:flex-wrap lg:justify-center xl:gap-y-2",
            "w-full bg-black p-1 px-2 xl:py-1.5",
          )}
        >
          {modelConfig.notice !== false ? (
            <>
              <StepBarNoticeBtn
                className="mr-1"
                active={!currentStepBarItem}
                onClick={() => handleStepClick(undefined)}
              />

              <StepBarArrow width="25" height="25" />
            </>
          ) : undefined}

          {stepBarItems.map((item, index) => (
            <React.Fragment key={`step-bar-step-${index}`}>
              <StepBarStep
                active={item.title === currentStepBarItem?.title}
                onClick={() => handleStepClick(item)}
              >
                {t(item.title)}
              </StepBarStep>

              {index < stepBarItems.length - 1 ? (
                <StepBarArrow width="25" height="25" />
              ) : null}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  },
);

export default StepBar;
