import {
  Color,
  ColorElement,
  ColorMode,
  Pattern,
} from "@/types/color-elements.ts";
import ColorAccordion from "@custom-model-editor/components/color-elements/color-accordion.tsx";
import { useColorElementExpandedList } from "@custom-model-editor/hooks/use-color-element-expanded-list.ts";
import { sortColorElements } from "@custom-model-editor/lib/color-group.ts";
import { cn, normalizeStr } from "@lib/utils.ts";
import React from "react";
import { useTranslation } from "react-i18next";

type ColorElementListProps = React.ComponentPropsWithoutRef<"div"> & {
  colorElements: ColorElement[];
  colorMode: ColorMode;

  colorList: Color[];
  onColorClick: (colorElement: ColorElement, color: Color) => void;
  onColorOver: (colorElement: ColorElement, color: Color) => void;
  onColorOut: (colorElement: ColorElement) => void;

  patternList: Pattern[];
  onPatternClick: (
    colorElement: ColorElement,
    pattern: Pattern | undefined,
  ) => void;
  onPatternOver: (colorElement: ColorElement, pattern: Pattern) => void;
  onPatternOut: (colorElement: ColorElement) => void;
};

export default function ColorElementList({
  className,
  colorElements,
  colorMode,
  colorList,
  onColorClick,
  onColorOver,
  onColorOut,
  patternList,
  onPatternClick,
  onPatternOver,
  onPatternOut,
  ...props
}: ColorElementListProps) {
  const { expandedList, handleColorAccordionChange } =
    useColorElementExpandedList();

  const { t } = useTranslation("color-group-elements");

  function getColorElementTitle(colorElement: ColorElement) {
    return normalizeStr(colorElement.name.split(" ")[0]) === "couleur"
      ? t(normalizeStr(colorElement.name.split(" ")[0]), colorElement.name, {
          index: colorElement.name.split(" ")[1],
        })
      : t(normalizeStr(colorElement.name), colorElement.name);
  }

  return (
    <div className={cn("flex flex-col gap-1", className)} {...props}>
      {colorElements.sort(sortColorElements).map((colorElement) => (
        <ColorAccordion
          key={colorElement.id}
          expanded={expandedList.includes(colorElement.id)}
          onChange={(expanded) =>
            handleColorAccordionChange(colorElement.id, expanded)
          }
          title={getColorElementTitle(colorElement)}
          activeColor={colorList.find(
            (c) => c.color === colorElement.color.color,
          )}
          activePattern={colorElement.pattern}
          colorMode={colorMode}
          colorList={colorList}
          onColorClick={(color) => onColorClick(colorElement, color)}
          onColorOver={(color) => onColorOver(colorElement, color)}
          onColorOut={() => onColorOut(colorElement)}
          patternList={patternList}
          onPatternClick={(pattern) => onPatternClick(colorElement, pattern)}
          onPatternOver={(pattern) => onPatternOver(colorElement, pattern)}
          onPatternOut={() => onPatternOut(colorElement)}
        />
      ))}
    </div>
  );
}
