import { Color, ColorMode } from "@/types/color-elements.ts";
import NumberInput from "@components/ui/number-input.tsx";
import ColorAccordion from "@custom-model-editor/components/color-elements/color-accordion.tsx";
import FontAccordion from "@custom-model-editor/components/text-editor/font-accordion.tsx";
import TextEditorPreview from "@custom-model-editor/components/text-editor/text-editor-preview.tsx";
import { shouldGradient } from "@custom-model-editor/lib/custom-model.ts";
import { cn } from "@lib/utils.ts";
import React from "react";
import { useTranslation } from "react-i18next";

type CustomTextEditorProps = Omit<
  React.ComponentPropsWithoutRef<"div">,
  "onChange"
> & {
  title?: string;
  placeholder?: string;
  value: TextEditorValue;
  onChange: (value: TextEditorValue) => void;
  settings: {
    fontList: string[];
    textColorList: Color[];
    strokeColorList: Color[];
    colorMode: ColorMode;
  };
};

export type TextEditorValue = {
  text: string;
  font: string;
  color: Color;
  stroke: {
    color: string;
    width: number;
  };
  spacing: number;
};

export default function TextEditor({
  className,
  title = "Texte personnalisé",
  placeholder,
  value,
  onChange,
  settings: { fontList, textColorList, strokeColorList, colorMode },
  ...props
}: CustomTextEditorProps) {
  const { t } = useTranslation("text-editor");

  function handleChange(
    property: keyof TextEditorValue,
    change: TextEditorValue[typeof property],
  ) {
    onChange({ ...value, [property]: change });
  }

  return (
    <div className={cn("", className)} {...props}>
      <div className="flex flex-col gap-2">
        <div className="text-center text-xl font-black uppercase">{title}</div>

        {/* text */}
        <input
          className={cn("w-full rounded", "text-slate-950")}
          type="text"
          value={value.text}
          placeholder={placeholder || t("text-placeholder")}
          onChange={(e) => handleChange("text", e.target.value)}
        />

        <div className="text-lg font-semibold">{t("preview")}</div>

        {/* text preview */}
        <div className="sticky top-0 z-40 -mt-2 border border-[#323232] bg-[#323232] pb-1 xl:pb-2">
          <TextEditorPreview
            className="mb-1"
            preview={value}
            gradient={shouldGradient(value.color, colorMode)}
          />

          <div className="flex items-center gap-3">
            {/* stroke width */}
            <label className="flex flex-col justify-center">
              {t("stroke")}
              <NumberInput
                className="max-w-[70px] lg:h-[35px]"
                min={0}
                value={value.stroke.width.toString()}
                onChange={(e) =>
                  handleChange("stroke", {
                    ...value.stroke,
                    width: Number(e.target.value),
                  })
                }
              />
            </label>

            {/* spacing */}
            <label className="flex flex-col justify-center">
              {t("spacing")}
              <NumberInput
                className="max-w-[70px] lg:h-[35px]"
                value={value.spacing.toString()}
                onChange={(e) =>
                  handleChange("spacing", Number(e.target.value))
                }
              />
            </label>
          </div>
        </div>

        <div className="flex flex-col gap-1">
          {/* font */}
          <FontAccordion
            title={value.font}
            fontList={fontList}
            activeFont={value.font}
            text={value.text}
            onFontClick={(font) => handleChange("font", font)}
          />

          {/* text color */}
          <ColorAccordion
            title={t("text-color")}
            activeColor={textColorList.find(
              (c) => c.color === value.color.color,
            )}
            colorMode={colorMode}
            colorList={textColorList}
            onColorClick={(color) => handleChange("color", color)}
          />

          {/* stroke color */}
          {value.stroke.width ? (
            <ColorAccordion
              title={t("stroke-color")}
              activeColor={textColorList.find(
                (c) => c.color === value.stroke.color,
              )}
              colorMode={colorMode}
              colorList={strokeColorList}
              onColorClick={(color) =>
                handleChange("stroke", { ...value.stroke, color: color.color })
              }
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
