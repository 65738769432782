import { CustomModel } from "@/types/custom-model.ts";
import ModelCardDropdownActions from "@admin/components/models/model-card-dropdown-actions.tsx";
import OvalLoader from "@components/ui/oval-loader.tsx";
import CustomModelSvgInjector from "@custom-model-editor/components/custom-model-svg-injector.tsx";
import { cn } from "@lib/utils.ts";
import React from "react";

export type CustomModelCardProps = Omit<
  React.ComponentPropsWithoutRef<"div">,
  "onClick"
> & {
  customModel: CustomModel;
  onClick: () => void;
  onDelete: (id: string) => Promise<void>;
};

export default function CustomModelCard({
  className,
  customModel,
  onClick,
  onDelete,
  ...props
}: CustomModelCardProps) {
  const SvgLoader = () => (
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
      <OvalLoader width={100} height={100} />
    </div>
  );

  return (
    <div
      className={cn("card relative", "overflow-hidden", className)}
      {...props}
    >
      <div className="group cursor-pointer" onClick={onClick}>
        <div className="flex items-center justify-between">
          {/* model name */}
          <div className="underline-offset-4 group-hover:underline">
            {customModel.model.name}
          </div>
          {/* model brand */}
          {customModel.model.brand ? (
            <div className="rounded-full bg-[#888] px-2 text-xs font-semibold">
              {customModel.model.brand}
            </div>
          ) : null}
        </div>

        <div className="py-2 duration-300 group-hover:scale-110">
          <CustomModelSvgInjector
            interactive={false}
            customModel={customModel}
            Loader={SvgLoader}
          />
        </div>
      </div>

      <ModelCardDropdownActions
        className={cn("absolute bottom-2 right-2")}
        id={customModel.model.id}
        model={customModel.model}
        onDelete={onDelete}
      />
    </div>
  );
}
