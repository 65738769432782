import { cn } from "@/lib/utils";
import { LocalizedNotice } from "@/types/model.ts";
import { Button } from "@components/ui/button.tsx";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

type NoticeCardProps = React.ComponentPropsWithoutRef<"div"> & {
  localizedNotice: LocalizedNotice;
  onClose: (notice: LocalizedNotice) => void;
};

export default function NoticeCard({
  className,
  localizedNotice,
  onClose,
  ...props
}: NoticeCardProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation("notice-card");

  const notice = useMemo(
    () => localizedNotice[resolvedLanguage as "fr" | "en"],
    [localizedNotice, resolvedLanguage],
  );

  return (
    <div
      className={cn(
        "p-4 pt-2",
        "rounded-lg bg-[#323232] shadow-[0_0_15px_0px_#121212]",
        className,
      )}
      {...props}
    >
      <div className="text-xl font-semibold uppercase">{notice.title}</div>
      <div className="mb-2">{notice.description}</div>

      <video
        src={notice.videoUrl}
        className="h-auto max-h-[60dvh] w-full lg:max-h-[80dvh]"
        controls
        loop
        autoPlay
      />

      <div className="mt-4 flex justify-end">
        <Button
          className={cn(
            "border border-accent text-accent",
            "bg-transparent/10 hover:bg-transparent/20",
          )}
          size="sm"
          onClick={() => onClose(localizedNotice)}
        >
          {t("close-btn")}
        </Button>
      </div>
    </div>
  );
}
