import { Logo } from "@/types/custom-element.ts";
import { LogoFilters } from "@components/logos/logo-list-filters.tsx";

export function filterLogos(logos: Logo[], filters: LogoFilters) {
  return logos.filter((logo) => {
    const { firstLetters, searchText } = filters;

    // first filter by first letters
    let firstLetterMatch = false;
    if (firstLetters) {
      if (firstLetters === "09") {
        firstLetterMatch = !!logo.name[0].match(/[0-9]/);
      } else {
        firstLetterMatch =
          logo.name.toLowerCase().startsWith(firstLetters[0].toLowerCase()) ||
          logo.name.toLowerCase().startsWith(firstLetters[1].toLowerCase());
      }

      if (!firstLetterMatch) return false;
    }

    // then filter by search text
    if (searchText) {
      return logo.name.toLowerCase().includes(searchText.toLowerCase());
    }

    return firstLetterMatch;
  });
}
