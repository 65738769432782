import { Model } from "@/types/model.ts";
import { ShopifyProductLink } from "@/types/shopify.ts";
import ShopifyProductLinkTableFilters from "@admin/components/shopify/shopify-product-link-table-filters.tsx";
import ShopifyProductLinkTable from "@admin/components/shopify/shopify-product-link-table.tsx";
import ConfirmUnlinkProductAlertDialog from "@admin/components/shopify/shopify-product-link/confirm-unlink-product-alert-dialog.tsx";
import ShopifyProductLinkDialog from "@admin/components/shopify/shopify-product-link/shopify-product-link-dialog.tsx";
import { useAdminContext } from "@admin/hooks/use-admin-context.ts";
import { useShopifyProducts } from "@admin/hooks/use-shopify-products.ts";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "sonner";

export default function AdminShopifyRoute() {
  const [productLinkSelected, setProductLinkSelected] =
    useState<ShopifyProductLink>();
  const [showConfirmUnlinkDialog, setShowConfirmUnlinkDialog] =
    useState<boolean>(false);

  const { dispatch } = useAdminContext();

  const {
    products,
    filters,
    setFilters,
    loading,
    fetchNextPage,
    hasNextPage,
    linkProductToModel,
  } = useShopifyProducts();

  function handleRowClick(row: ShopifyProductLink) {
    setProductLinkSelected(row);
  }

  async function handleLink(
    productLink: ShopifyProductLink,
    modelToLink: Model,
  ) {
    const promise = linkProductToModel(productLink.product, modelToLink);
    toast.promise(promise, {
      loading: "Association en cours...",
      duration: 7000,
      success: "Association réussie",
      error:
        "Erreur lors de l'association. Veuillez réessayer plus tard ou contactez le développeur.",
    });
    const { product: productUpdated, model: modelUpdated } = await promise;

    dispatch({ type: "update_model", payload: modelUpdated });
    setProductLinkSelected({ product: productUpdated, model: modelUpdated });
  }

  async function handleUnlink(productLink: ShopifyProductLink) {
    const { product } = productLink;

    const promise = linkProductToModel(product);
    toast.promise(promise, {
      loading: "Dé-association en cours...",
      duration: 7000,
      success: "Les produits ont été dé-associés",
      error:
        "Erreur lors de la dé-association. Veuillez réessayer plus tard ou contactez le développeur.",
    });
    const { product: productUpdated, model: modelUpdated } = await promise;
    dispatch({ type: "update_model", payload: modelUpdated });
    setProductLinkSelected({ product: productUpdated, model: undefined });
  }

  return (
    <div className="py-4">
      <div className="flex items-center justify-between">
        <div className="text-xl font-black uppercase text-black">Shopify</div>
        <Link
          className="text-sm text-slate-950 underline underline-offset-2 after:content-['_↗'] hover:opacity-75"
          to={import.meta.env.VITE_SHOPIFY_STORE_ADMIN_URL}
          target="_blank"
        >
          Aller sur Shopify
        </Link>
      </div>
      <div className="mb-3 text-sm text-slate-950">
        Associe les produits Shopify avec les modèles Custom Lab pour qu'ils
        soient configurables sur le site.
      </div>

      <div>
        <ShopifyProductLinkTableFilters
          className="mb-6"
          filters={filters}
          onFiltersChange={setFilters}
        />

        <div className="rounded-lg border border-gray-400 bg-white pb-4">
          <ShopifyProductLinkTable
            tableClassName="text-slate-950"
            products={products}
            loading={loading}
            nextPage={fetchNextPage}
            hasNextPage={hasNextPage ?? false}
            onRowClick={handleRowClick}
            bodyStyle={{ height: `calc(100dvh - 350px)` }}
          />
        </div>
      </div>

      {productLinkSelected ? (
        <ShopifyProductLinkDialog
          open
          onOpenChange={(change) =>
            !change && setProductLinkSelected(undefined)
          }
          productLink={productLinkSelected}
          onLink={handleLink}
          onUnlink={() => setShowConfirmUnlinkDialog(true)}
        />
      ) : null}

      {productLinkSelected && showConfirmUnlinkDialog ? (
        <ConfirmUnlinkProductAlertDialog
          open={showConfirmUnlinkDialog}
          onOpenChange={setShowConfirmUnlinkDialog}
          productLink={productLinkSelected}
          onConfirm={() => handleUnlink(productLinkSelected)}
        />
      ) : null}
    </div>
  );
}
