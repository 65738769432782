import Admin from "@admin/components/admin.tsx";
import { useAdminContext } from "@admin/hooks/use-admin-context.ts";
import MxlabLoader from "@components/ui/mxlab-loader.tsx";
import { api } from "@lib/api.ts";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

export default function AdminRoute() {
  const {
    state: { loading, isLogged },
    dispatch,
  } = useAdminContext();

  useEffect(() => {
    dispatch({ type: "set_loading", payload: true });
    Promise.all([api.getModels(), api.getCustomModels()])
      .then((values) => {
        dispatch({ type: "set_models", payload: values[0] });
        dispatch({ type: "set_custom_models", payload: values[1] });
      })
      .catch((err) => {
        console.log("Error while fetching models and custom models");
        console.error(err);
      })
      .finally(() => {
        dispatch({ type: "set_loading", payload: false });
      });
  }, []);

  return loading ? (
    <div className="grid place-items-center">
      <MxlabLoader
        color="black"
        text="Chargement du panel d'administration..."
      />
    </div>
  ) : isLogged ? (
    <Admin />
  ) : (
    <Navigate to="login" />
  );
}
