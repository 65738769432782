import mxlab_logo_black from "@assets/mxlab/logo_mxlab_blason_black.png";
import mxlab_logo_white from "@assets/mxlab/logo_mxlab_blason_white.png";
import { cn } from "@lib/utils.ts";
import React from "react";

type MxlabLoaderProps = React.ComponentPropsWithoutRef<"div"> & {
  color?: "white" | "black";
  text?: string;
  textClassName?: string;
};

export default function MxlabLoader({
  className,
  color = "white",
  text,
  textClassName,
  ...props
}: MxlabLoaderProps) {
  const src = color === "white" ? mxlab_logo_white : mxlab_logo_black;

  const textClasses = {
    white: "text-white",
    black: "text-black",
  };

  return (
    <div
      className={cn(
        "relative flex  flex-col items-center",
        "h-[150px] w-[150px] lg:h-[200px] lg:w-[200px] xl:h-[250px] xl:w-[250px]",
        className,
      )}
      {...props}
    >
      <img
        className="h-full w-full animate-loader object-contain"
        src={src}
        alt="MXLab blason logo"
      />

      {text ? (
        <div
          className={cn(
            "mt-6 whitespace-nowrap text-center text-lg font-semibold",
            textClasses[color],
            textClassName,
          )}
        >
          {text}
        </div>
      ) : null}
    </div>
  );
}
