import { Model } from "@/types/model.ts";
import CustomModelCard from "@admin/components/custom-models/custom-model-card.tsx";
import { cn } from "@lib/utils.ts";
import React from "react";
import RenderIfVisible from "react-render-if-visible";

type ModelListProps = React.ComponentPropsWithoutRef<"div"> & {
  models: Model[];
  onModelClick: (model: Model) => void;
  onModelDelete: (id: string) => Promise<void>;
};

export default function ModelList({
  className,
  models,
  onModelClick,
  onModelDelete,
  ...props
}: ModelListProps) {
  return (
    <div className={cn("", className)} {...props}>
      <div className="grid grid-cols-2 gap-4 xl:grid-cols-3">
        {models.map((model) => (
          <RenderIfVisible key={model.id} defaultHeight={300} stayRendered>
            <CustomModelCard
              customModel={{
                id: `${model.id}-cm-preview`,
                model,
                colorLayers: [],
                elements: [],
                importedLogos: [],
              }}
              onClick={() => onModelClick(model)}
              onDelete={onModelDelete}
            />
          </RenderIfVisible>
        ))}
      </div>
    </div>
  );
}
