import { cn } from "@/lib/utils";
import { CustomModel } from "@/types/custom-model.ts";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu.tsx";
import { DropdownMenuProps } from "@radix-ui/react-dropdown-menu";
import { Trash } from "lucide-react";
import { LuMoreVertical } from "react-icons/lu";

type CustomModelCardDropdownActionsProps = DropdownMenuProps & {
  customModel: CustomModel;
  triggerClassName?: string;
  onDelete?: (customModel: CustomModel) => void;
};

export default function CustomModelCardDropdownActions({
  customModel,
  triggerClassName,
  onDelete,
  ...props
}: CustomModelCardDropdownActionsProps) {
  return (
    <DropdownMenu {...props}>
      <DropdownMenuTrigger
        className={cn(
          "rounded-full p-1",
          "hover:bg-slate-200/20",
          "duration-100 radix-state-open:rotate-90",
          triggerClassName,
        )}
      >
        <LuMoreVertical className="h-5 w-5 fill-slate-200" />
      </DropdownMenuTrigger>

      <DropdownMenuContent>
        <DropdownMenuLabel className="text-base">
          {customModel.model.name}
        </DropdownMenuLabel>

        {/* TODO: copy on click */}
        <DropdownMenuLabel className="font-normal">
          id: <span className="italic">{customModel.id}</span>
        </DropdownMenuLabel>

        <DropdownMenuSeparator />

        {customModel.createdAt ? (
          <DropdownMenuLabel className="flex justify-between gap-2">
            <span className="font-normal">Création: </span>
            <span className="font-semibold">
              {new Date(customModel.createdAt).toLocaleDateString("fr", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
          </DropdownMenuLabel>
        ) : null}

        {customModel.updatedAt ? (
          <DropdownMenuLabel className="flex justify-between gap-2">
            <span className="font-normal">Dernière modif: </span>
            <span className="font-semibold">
              {new Date(customModel.updatedAt).toLocaleDateString("fr", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
          </DropdownMenuLabel>
        ) : null}

        {onDelete ? <DropdownMenuSeparator /> : null}

        {onDelete ? (
          <DropdownMenuItem
            className="text-error focus:text-error"
            onClick={() => onDelete(customModel)}
            onSelect={(e) => e.preventDefault()}
          >
            <Trash className="mr-2" size={16} />
            <span>Supprimer</span>
          </DropdownMenuItem>
        ) : null}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
