import AdminCustomModelsRoute from "@routes/admin-custom-models-route.tsx";
import AdminLoginRoute from "@routes/admin-login-route.tsx";
import AdminLogosRoute from "@routes/admin-logos-route.tsx";
import AdminModelsRoute from "@routes/admin-models-route.tsx";
import AdminNewModelRoute from "@routes/admin-new-model-route.tsx";
import AdminRoute from "@routes/admin-route.tsx";
import CustomizationRoute from "@routes/customization-route.tsx";
import ReactDOM from "react-dom/client";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import "./index.css";

import AdminSettingsRoute from "@routes/admin-settings-route.tsx";
import AdminShopifyRoute from "@routes/admin-shopify.route.tsx";
import CustomizationErrorRoute from "@routes/customization-error-route.tsx";
import PageNotFound from "@routes/page-not-found.tsx";
import App from "./App.tsx";

import "./i18n.ts";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { index: true, element: <Navigate to="admin" replace /> },
      {
        path: "admin",
        element: <AdminRoute />,
        children: [
          { index: true, element: <Navigate to="models" replace /> },
          { path: "models", element: <AdminModelsRoute /> },
          { path: "customizations", element: <AdminCustomModelsRoute /> },
          { path: "logos", element: <AdminLogosRoute /> },
          { path: "shopify", element: <AdminShopifyRoute /> },
          { path: "settings", element: <AdminSettingsRoute /> },
        ],
      },
      { path: "/admin/models/new", element: <AdminNewModelRoute /> },
      { path: "/admin/login", element: <AdminLoginRoute /> },
      {
        path: "/customize/:customModelId?",
        element: <CustomizationRoute />,
        errorElement: <CustomizationErrorRoute />,
      },
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <RouterProvider router={router} />,
);
