import { useScreenshotContext } from "@/_screenshots/use-screenshot-context.ts";
import { CustomModel } from "@/types/custom-model.ts";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu.tsx";
import { Download } from "lucide-react";
import { useMemo } from "react";

type DlCustomizationDropdownProps = {
  className?: string;
  customModel: CustomModel;
};

export default function DlCustomizationDropdown({
  className,
  customModel,
}: DlCustomizationDropdownProps) {
  const { screenshot } = useScreenshotContext();

  // we can either screenshot the whole model or a specific focusable color layer (e.g. Kit Plastique)
  const colorLayersFocusable = useMemo(
    () => customModel.colorLayers.filter((cl) => !!cl.focusText),
    [customModel],
  );

  function handleScreenshot(colorLayerId?: string) {
    screenshot(customModel, { colorLayerId, download: true });
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className={className}>
        <Download className="h-[24px] w-[24px] hover:opacity-75" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[150px]">
        <DropdownMenuLabel>Screenshot</DropdownMenuLabel>

        <DropdownMenuSeparator />

        <DropdownMenuItem onClick={() => handleScreenshot()}>
          Complet
        </DropdownMenuItem>

        {colorLayersFocusable.map((colorLayer) => (
          <DropdownMenuItem
            key={colorLayer.id}
            className="capitalize"
            onClick={() => handleScreenshot(colorLayer.id)}
          >
            {colorLayer.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
