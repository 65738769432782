import arrow from "@assets/fleche.png";
import { cn } from "@lib/utils.ts";
import React from "react";

type StepBarArrowProps = React.ComponentPropsWithoutRef<"img">;

export default function StepBarArrow({
  className,
  ...props
}: StepBarArrowProps) {
  return (
    <img
      className={cn(className)}
      src={arrow}
      alt="Flèche de la barre d'étape"
      {...props}
    />
  );
}
