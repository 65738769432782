import { cn } from "@lib/utils.ts";
import React from "react";

type StepBarStepProps = React.ComponentPropsWithoutRef<"div"> & {
  active?: boolean;
};

export default function StepBarStep({
  className,
  active = false,
  children,
  ...props
}: StepBarStepProps) {
  return (
    <div
      className={cn(
        "rounded px-2 lg:py-0.5",
        "cursor-pointer hover:bg-[#4a4a4a]",
        { "bg-accent text-slate-950 hover:bg-accent/75": active },
        className,
      )}
      {...props}
    >
      <span className="whitespace-nowrap text-lg font-bold uppercase lg:text-xl">
        {children}
      </span>
    </div>
  );
}
