import OvalLoader from "@components/ui/oval-loader.tsx";
import { cn } from "@lib/utils.ts";
import React from "react";

export type ButtonColor = "primary" | "secondary" | "danger" | "default";

type MyButtonProps = React.ComponentPropsWithoutRef<"button"> & {
  color?: ButtonColor;
  loading?: boolean;
};

export default function MyButton({
  className,
  color = "primary",
  loading,
  children,
  ...props
}: MyButtonProps) {
  const buttonColorVariants = {
    primary: "text-white bg-primary",
    secondary: "text-white bg-secondary",
    danger: "text-white bg-error",
    default: "text-white bg-[#666]",
  };

  return (
    <button
      className={cn(
        "relative",
        "px-3 py-1.5",
        "rounded font-bold",
        { "hover:opacity-75": !loading },
        { "cursor-wait": loading },
        buttonColorVariants[color],
        {
          "cursor-not-allowed bg-[#4a4a4a] opacity-75": props.disabled,
        },
        className,
      )}
      {...props}
    >
      <div className={cn({ "opacity-0": loading })}>{children}</div>

      {loading ? (
        <OvalLoader
          wrapperClass="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          color="#fff"
          secondaryColor="#fafafa"
          width={27}
          height={27}
          strokeWidth={5}
        />
      ) : null}
    </button>
  );
}
