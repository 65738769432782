import { cn } from "@/lib/utils";
import { LocalizedNotice } from "@/types/model.ts";
import { CircleHelp } from "lucide-react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

type NoticeSummaryProps = React.ComponentPropsWithoutRef<"div"> & {
  active?: boolean;
  notice: LocalizedNotice;
  onShowNotice: (localizedNotice: LocalizedNotice) => void;
};

export default function NoticeSummary({
  className,
  active = false,
  notice: localizedNotice,
  onShowNotice,
  ...props
}: NoticeSummaryProps) {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation();

  const notice = useMemo(
    () => localizedNotice[resolvedLanguage as "fr" | "en"],
    [localizedNotice, resolvedLanguage],
  );

  return (
    <div className={cn(className)} {...props}>
      <div className={cn("grid grid-cols-[1fr_auto_1fr] items-center", "mb-2")}>
        <div />

        <div className="w-fit justify-self-center rounded bg-accent px-2 py-1 font-bold uppercase text-slate-950">
          {notice.title}
        </div>
        <CircleHelp
          className={cn(
            "h-5 w-5 justify-self-end",
            "cursor-pointer hover:text-accent",
            { "text-accent": active },
          )}
          onClick={() => onShowNotice(localizedNotice)}
        />
      </div>

      <div className="text-center leading-5">{notice.summary}</div>
    </div>
  );
}
