import { Logo } from "@/types/custom-element.ts";
import { CustomModel } from "@/types/custom-model.ts";
import { Model } from "@/types/model.ts";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import {
  onAuthStateChanged as firebaseOnAuthStateChanged,
  signOut as firebaseSignOut,
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
  UserCredential,
} from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import {
  deleteObject,
  getDownloadURL as firebaseGetDownloadURL,
  getStorage,
  listAll,
  ref,
  uploadBytes,
  UploadResult,
} from "firebase/storage";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

if (import.meta.env.DEV) {
  // @ts-expect-error - Allow to debug Firebase App Check in development
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
} else if (import.meta.env.VITE_FIREBASE_APP_CHECK_DEBUG_TOKEN_FROM_CI) {
  // @ts-expect-error - Allow to debug Firebase App Check in CI
  self.FIREBASE_APPCHECK_DEBUG_TOKEN =
    import.meta.env.VITE_FIREBASE_APP_CHECK_DEBUG_TOKEN_FROM_CI;
}

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(
    import.meta.env.VITE_FIREBASE_APP_CHECK_RECAPTCHA_PUBLIC_KEY,
  ),
  isTokenAutoRefreshEnabled: true,
});

const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);

if (import.meta.env.DEV) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

if (import.meta.env.PROD) {
  getAnalytics(app);
}

async function getCustomModels(): Promise<CustomModel[]> {
  const db = getFirestore(app);
  const customModelsCollection = collection(db, "custom-models");
  const querySnapshot = await getDocs(customModelsCollection);
  return querySnapshot.docs
    .map((doc) => doc.data())
    .map((cm) => ({
      id: `${cm.id}-db`,
      ...cm,
      createdAt: new Date(cm.createdAt),
      updatedAt: cm.updatedAt ? new Date(cm.updatedAt) : undefined,
    })) as CustomModel[];
}

async function getCustomModelById(
  id: string,
): Promise<CustomModel | undefined> {
  const docRef = doc(db, "custom-models", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as CustomModel;
  } else {
    return undefined;
  }
}

async function createCustomModel(customModel: CustomModel): Promise<void> {
  await setDoc(
    doc(db, "custom-models", customModel.id),
    JSON.parse(JSON.stringify({ ...customModel, importedLogos: [] })),
  );
}

async function deleteCustomModel(id: string): Promise<void> {
  const docRef = doc(db, "custom-models", id);
  await deleteDoc(docRef);
}

async function createModel(model: Model): Promise<void> {
  await setDoc(doc(db, "models", model.id), JSON.parse(JSON.stringify(model)));
}

async function updateModel(model: Model): Promise<void> {
  await setDoc(doc(db, "models", model.id), JSON.parse(JSON.stringify(model)), {
    merge: true,
  });
}

async function getModels(): Promise<Model[]> {
  const db = getFirestore(app);
  const modelsCollection = collection(db, "models");
  const querySnapshot = await getDocs(modelsCollection);
  return querySnapshot.docs.map((doc) => doc.data()) as Model[];
}

async function getModelById(modelId: string): Promise<Model | undefined> {
  const docRef = doc(db, "models", modelId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as Model;
  } else {
    return undefined;
  }
}

async function deleteModel(modelId: string): Promise<void> {
  const docRef = doc(db, "models", modelId);
  await deleteDoc(docRef);
}

function uploadFile(fullpath: string, blob: Blob): Promise<UploadResult> {
  const storage = getStorage();
  const storageRef = ref(storage, fullpath);
  return uploadBytes(storageRef, blob);
}

function getDownloadURL(fullpath: string): Promise<string> {
  const storage = getStorage();
  return firebaseGetDownloadURL(ref(storage, fullpath));
}

function getFolder(folderpath: string) {
  const storage = getStorage();
  return listAll(ref(storage, folderpath));
}

function deleteLogos(logos: Logo[]): Promise<void[]> {
  const storage = getStorage();
  const promises = logos.map((logo) =>
    deleteObject(ref(storage, `Logos/${logo.name}`)),
  );
  return Promise.all(promises);
}

function signIn(email: string, password: string): Promise<UserCredential> {
  return signInWithEmailAndPassword(auth, email, password);
}

function signOut(): Promise<void> {
  return firebaseSignOut(auth);
}

function verifyEmail(): Promise<void> {
  if (!auth.currentUser) throw new Error("No user connected");
  return sendEmailVerification(auth.currentUser);
}

function onAuthStateChanged(
  callback: (user: UserCredential["user"] | null) => void,
): () => void {
  return firebaseOnAuthStateChanged(auth, callback);
}

export const firebaseService = {
  functions,
  getCustomModels,
  createCustomModel,
  getCustomModelById,
  deleteCustomModel,
  createModel,
  updateModel,
  getModels,
  getModelById,
  deleteModel,
  uploadFile,
  getDownloadURL,
  getFolder,
  deleteLogos,
  signIn,
  signOut,
  verifyEmail,
  onAuthStateChanged,
};
