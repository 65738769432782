import {
  CUSTOM_LAB_COLORS,
  CUSTOM_LAB_PATTERNS,
  FOCUS_CHECKBOX_DEFAULT_TEXT,
  colorModeNames,
  colorModes,
} from "@/config.ts";
import { ColorLayer, ColorMode } from "@/types/color-elements.ts";
import { ColorLayerConfigUpdate } from "@/types/model.ts";
import { Button } from "@components/ui/button.tsx";
import { Input } from "@components/ui/input.tsx";
import { Label } from "@components/ui/label.tsx";
import { Switch } from "@components/ui/switch.tsx";
import { Textarea } from "@components/ui/textarea.tsx";
import { ToggleGroup, ToggleGroupItem } from "@components/ui/toggle-group.tsx";
import ColorLayerConfigColorSelect from "@custom-model-editor/components/color-layers/color-layer-config-color-select.tsx";
import ColorLayerConfigPatternSelect from "@custom-model-editor/components/color-layers/color-layer-config-pattern-select.tsx";
import { useCustomModelEditorContext } from "@custom-model-editor/hooks/use-custom-model-editor-context.ts";
import { cn } from "@lib/utils.ts";
import React, { useMemo, useState } from "react";
import { toast } from "sonner";

type ColorLayerConfigFormProps = React.ComponentPropsWithoutRef<"div"> & {
  colorLayer: ColorLayer;
  baseConfigColClassName?: string;
  colorListColClassName?: string;
  patternsColClassName?: string;
  submitClassName?: string;
};

export default function ColorLayerConfigForm({
  className,
  colorLayer,
  baseConfigColClassName,
  colorListColClassName,
  patternsColClassName,
  submitClassName,
  ...props
}: ColorLayerConfigFormProps) {
  const [formValue, setFormValue] =
    useState<ColorLayerConfigUpdate>(colorLayer);

  const focusable = useMemo(() => !!formValue.focusText, [formValue.focusText]);

  const { dispatch } = useCustomModelEditorContext();

  function validate() {
    dispatch({
      type: "update_color_layer_config",
      payload: {
        colorLayerId: colorLayer.id,
        updates: { ...formValue },
      },
    });
    toast.success("Configuration du calque mise à jour");
  }

  return (
    <div className={cn(className)} {...props}>
      <div className={cn(baseConfigColClassName)}>
        <div className="text-lg font-semibold capitalize">
          {colorLayer.name}
        </div>
        <div className="text-sm text-slate-700">Configure le calque</div>

        <div className="mt-3 flex flex-col gap-3">
          <Label>
            <div className="mb-1">Message</div>
            <Textarea
              placeholder="Le message affiché en haut du menu du calque"
              value={formValue.menuText}
              onChange={(e) =>
                setFormValue((prev) => ({ ...prev, menuText: e.target.value }))
              }
            />
          </Label>

          <div className="my-3 flex flex-col gap-1">
            <Label className="flex cursor-pointer items-center justify-between gap-2">
              <span>Focalisable</span>
              <Switch className="cursor-not-allowed" checked={focusable} />
            </Label>
            <Input
              value={formValue.focusText ?? ""}
              placeholder={FOCUS_CHECKBOX_DEFAULT_TEXT}
              onChange={(e) =>
                setFormValue((prev) => ({ ...prev, focusText: e.target.value }))
              }
            />
          </div>

          <Label>
            <div className="mb-2">Bases d'impression</div>

            <ToggleGroup
              value={formValue.colorModes}
              type="multiple"
              className="flex-wrap justify-start"
              onValueChange={(values) =>
                setFormValue((prev) => ({
                  ...prev,
                  colorModes: values as ColorMode[],
                }))
              }
            >
              {colorModes.map((colorMode) => (
                <ToggleGroupItem
                  key={colorMode}
                  className="whitespace-nowrap"
                  value={colorMode}
                >
                  {colorModeNames[colorMode]}
                </ToggleGroupItem>
              ))}
            </ToggleGroup>
          </Label>
        </div>
      </div>

      <div className={cn(colorListColClassName)}>
        <div>
          Couleurs ({formValue.colorList.length} / {CUSTOM_LAB_COLORS.length})
        </div>
        <div className="text-sm text-slate-700">
          Liste des couleurs autorisées pour ce calque
        </div>

        <ColorLayerConfigColorSelect
          className="mt-3"
          value={formValue.colorList}
          onChange={(colors) =>
            setFormValue((prev) => ({ ...prev, colorList: colors }))
          }
        />
      </div>

      <div className={cn(patternsColClassName)}>
        <div>
          Motifs ({formValue.patterns?.length ?? 0} /{" "}
          {CUSTOM_LAB_PATTERNS.length})
        </div>
        <div className="text-sm text-slate-700">
          Liste des motifs autorisés pour ce calque
        </div>

        <ColorLayerConfigPatternSelect
          className="mt-3"
          value={formValue.patterns ?? []}
          onChange={(patterns) =>
            setFormValue((prev) => ({ ...prev, patterns }))
          }
        />
      </div>

      <Button
        className={cn(submitClassName)}
        variant="primary"
        size="sm"
        onClick={validate}
      >
        Valider
      </Button>
    </div>
  );
}
