import SearchInput from "@components/ui/search-input.tsx";
import { cn } from "@lib/utils.ts";
import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

type ShopifyProductLinkTableFiltersProps =
  React.ComponentPropsWithoutRef<"div"> & {
    filters: ShopifyProductFilters;
    onFiltersChange: (filters: ShopifyProductFilters) => void;
  };

export type ShopifyProductFilters = {
  query?: string;
};

export default function ShopifyProductLinkTableFilters({
  className,
  filters,
  onFiltersChange,
  ...props
}: ShopifyProductLinkTableFiltersProps) {
  const [query, setQuery] = useState<string>(filters.query ?? "");
  const [debouncedQuery] = useDebounce(query, 500);

  useEffect(() => {
    onFiltersChange({ ...filters, query: debouncedQuery });
  }, [debouncedQuery]);

  return (
    <div className={cn("flex items-center gap-3", className)} {...props}>
      <SearchInput
        wrapperClassName="h-10"
        className="h-10 w-[600px] border-gray-400"
        placeholder="Filtrer les produits..."
        value={query}
        onChange={(event) => setQuery(event.target.value)}
      />
    </div>
  );
}
