// Colors for the color layers
import rg10RougeBetaFluo from "@/assets/colorThumbnails/RG10_rouge_beta_fluo.jpg";
import rg11OrangeKTM from "@/assets/colorThumbnails/RG11_orange_ktm.jpg";
import rg12Rose from "@/assets/colorThumbnails/RG12_rose.jpg";
import rg13Violet from "@/assets/colorThumbnails/RG13_violet.jpg";
import rg14JauneSuz from "@/assets/colorThumbnails/RG14_jaune_suz.jpg";
import rg15VertKawa from "@/assets/colorThumbnails/RG15_vert_kawa.jpg";
import rg16Blanc from "@/assets/colorThumbnails/RG16_blanc.jpg";
import rg17Sable from "@/assets/colorThumbnails/RG17_sable.jpg";
import rg1Noir from "@/assets/colorThumbnails/RG1_noir.jpg";
import rg2GrisFonce from "@/assets/colorThumbnails/RG2_gris_fonce.jpg";
import rg3GrisMoyen from "@/assets/colorThumbnails/RG3_gris_moyen.jpg";
import rg4GrisClair from "@/assets/colorThumbnails/RG4_gris_clair.jpg";
import rg5BleuHVA from "@/assets/colorThumbnails/RG5_bleu_hva.jpg";
import rg6BleuYam from "@/assets/colorThumbnails/RG6_bleu_yam.jpg";
import rg7BleuTM from "@/assets/colorThumbnails/RG7_bleu_tm.jpg";
import rg8BleuCyan from "@/assets/colorThumbnails/RG8_cyan.jpg";
import rg9RougeHonda from "@/assets/colorThumbnails/RG9_rouge_honda.jpg";

import { Color, ColorMode, Pattern } from "@/types/color-elements.ts";
import { LocalizedNotice, Model } from "@/types/model.ts";
import { options as PanZoomOptions } from "@svgdotjs/svg.panzoom.js";

export const CUSTOM_MODEL_EDITOR_PANZOOM_OPTIONS: PanZoomOptions = {
  panning: true,
  pinchZoom: true,
  wheelZoom: true,
  panButton: 0,
  oneFingerPan: true,
  margins: false,
  zoomMin: 0.25,
  zoomMax: 2.5,
  zoomFactor: 0.1,
};

export const CUSTOM_LAB_COLORS: Color[] = [
  {
    name: "BLACK",
    color: "#111111",
    gradient: [
      { color: "#060606", location: 10 },
      { color: "#4F4A50", location: 25 },
      { color: "#272B32", location: 27 },
      { color: "#060606", location: 50 },
      { color: "#4F4A50", location: 60 },
      { color: "#272B32", location: 77 },
      { color: "#060606", location: 90 },
    ],
  },
  {
    name: "DARK GREY",
    color: "#3c3c3b",
    gradient: [
      { color: "#3C3C3B", location: 10 },
      { color: "#4C4C4C", location: 25 },
      { color: "#272B32", location: 27 },
      { color: "#3C3C3B", location: 50 },
      { color: "#4C4C4C", location: 60 },
      { color: "#272B32", location: 77 },
      { color: "#3C3C3B", location: 90 },
    ],
  },
  {
    name: "GREY 80",
    color: "#575756",
    gradient: [
      { color: "#575756", location: 10 },
      { color: "#6A6A69", location: 25 },
      { color: "#404245", location: 27 },
      { color: "#575756", location: 50 },
      { color: "#6A6A69", location: 60 },
      { color: "#404245", location: 77 },
      { color: "#575756", location: 90 },
    ],
  },
  {
    name: "GREY 70",
    color: "#706f6f",
    gradient: [
      { color: "#706F6F", location: 10 },
      { color: "#848282", location: 25 },
      { color: "#5D5E5F", location: 27 },
      { color: "#706F6F", location: 50 },
      { color: "#848282", location: 60 },
      { color: "#5D5E5F", location: 77 },
      { color: "#706F6F", location: 90 },
    ],
  },
  {
    name: "HVA GREY",
    color: "#969593",
    gradient: [
      { color: "#969593", location: 10 },
      { color: "#A09F9D", location: 25 },
      { color: "#7D7D7C", location: 27 },
      { color: "#969593", location: 50 },
      { color: "#A09F9D", location: 60 },
      { color: "#7D7D7C", location: 77 },
      { color: "#969593", location: 90 },
    ],
  },
  {
    name: "LIGHT GREY",
    color: "#c6c6c6",
    gradient: [
      { color: "#C6C6C6", location: 10 },
      { color: "#DAD8DD", location: 25 },
      { color: "#A4A4A3", location: 27 },
      { color: "#C6C6C6", location: 50 },
      { color: "#DAD8DD", location: 60 },
      { color: "#A4A4A3", location: 77 },
      { color: "#C6C6C6", location: 90 },
    ],
  },
  {
    name: "WHITE",
    color: "#f2f2f2",
    gradient: [
      { color: "#f2f2f2", location: 0 },
      { color: "#ececec", location: 9 },
      { color: "#dddddd", location: 11 },
      { color: "#ffffff", location: 21 },
      { color: "#c2c2c1", location: 22 },
      { color: "#ececec", location: 34 },
      { color: "#dddddd", location: 36 },
      { color: "#f2f2f2", location: 54 },
      { color: "#cdcdcc", location: 56 },
      { color: "#ffffff", location: 69 },
      { color: "#c2c2c1", location: 70 },
      { color: "#f2f2f2", location: 85 },
      { color: "#dddddd", location: 87 },
      { color: "#f2f2f2", location: 100 },
    ],
  },
  {
    name: "DARK RED",
    color: "#9c1006",
    gradient: [
      { color: "#9C1006", location: 10 },
      { color: "#BF4646", location: 25 },
      { color: "#701307", location: 27 },
      { color: "#9C1006", location: 50 },
      { color: "#BF4646", location: 60 },
      { color: "#701307", location: 77 },
      { color: "#9C1006", location: 90 },
    ],
  },
  {
    name: "HONDA RED",
    color: "#e00a0a",
    gradient: [
      { color: "#E00A0A", location: 10 },
      { color: "#E25D56", location: 25 },
      { color: "#B01714", location: 27 },
      { color: "#E00A0A", location: 50 },
      { color: "#E25D56", location: 60 },
      { color: "#B01714", location: 77 },
      { color: "#E00A0A", location: 90 },
    ],
  },
  {
    name: "KTM ORANGE",
    color: "#e95a0c",
    gradient: [
      { color: "#e95a0C", location: 10 },
      { color: "#f3a473", location: 25 },
      { color: "#ec732f", location: 27 },
      { color: "#e95a0C", location: 50 },
      { color: "#f3a473", location: 60 },
      { color: "#ec732f", location: 77 },
      { color: "#e95a0C", location: 90 },
    ],
  },
  {
    name: "DARK YELLOW",
    color: "#fbba00",
    gradient: [
      { color: "#FBBA00", location: 10 },
      { color: "#F4CB8F", location: 25 },
      { color: "#ED9B12", location: 27 },
      { color: "#FBBA00", location: 50 },
      { color: "#F4CB8F", location: 60 },
      { color: "#ED9B12", location: 77 },
      { color: "#FBBA00", location: 90 },
    ],
  },
  {
    name: "SUZUKI YELLOW",
    color: "#ffed00",
    gradient: [
      { color: "#FFED00", location: 10 },
      { color: "#FFEDA1", location: 25 },
      { color: "#EACD21", location: 27 },
      { color: "#FFED00", location: 50 },
      { color: "#FFEDA1", location: 60 },
      { color: "#EACD21", location: 77 },
      { color: "#FFED00", location: 90 },
    ],
  },
  {
    name: "HVA YELLOW",
    color: "#fbfa2e",
    gradient: [
      { color: "#FBFA2E", location: 10 },
      { color: "#EEEEAB", location: 25 },
      { color: "#E9DC24", location: 27 },
      { color: "#FBFA2E", location: 50 },
      { color: "#EEEEAB", location: 60 },
      { color: "#E9DC24", location: 77 },
      { color: "#FBFA2E", location: 90 },
    ],
  },
  {
    name: "LIME GREEN",
    color: "#95c11f",
    gradient: [
      { color: "#95C11F", location: 10 },
      { color: "#CDDB6D", location: 25 },
      { color: "#8AB31F", location: 27 },
      { color: "#95C11F", location: 50 },
      { color: "#CDDB6D", location: 60 },
      { color: "#8AB31F", location: 77 },
      { color: "#95C11F", location: 90 },
    ],
  },
  {
    name: "KAWA GREEN",
    color: "#4b9900",
    gradient: [
      { color: "#4B9900", location: 10 },
      { color: "#7AB15F", location: 25 },
      { color: "#418431", location: 27 },
      { color: "#4B9900", location: 50 },
      { color: "#7AB15F", location: 60 },
      { color: "#418431", location: 77 },
      { color: "#4B9900", location: 90 },
    ],
  },
  {
    name: "DARK GREEN",
    color: "#0e5b00",
    gradient: [
      { color: "#0E5B00", location: 10 },
      { color: "#327F3F", location: 25 },
      { color: "#10471F", location: 27 },
      { color: "#0E5B00", location: 50 },
      { color: "#327F3F", location: 60 },
      { color: "#10471F", location: 77 },
      { color: "#0E5B00", location: 90 },
    ],
  },
  {
    name: "KAKI GREEN",
    color: "#2d382f",
    gradient: [
      { color: "#2D382F", location: 10 },
      { color: "#4A5A4D", location: 25 },
      { color: "#252F26", location: 27 },
      { color: "#2D382F", location: 50 },
      { color: "#4A5A4D", location: 60 },
      { color: "#252F26", location: 77 },
      { color: "#2D382F", location: 90 },
    ],
  },
  {
    name: "TURQUOISE",
    color: "#00a099",
    gradient: [
      { color: "#00A099", location: 10 },
      { color: "#7DBDB3", location: 25 },
      { color: "#008B87", location: 27 },
      { color: "#00A099", location: 50 },
      { color: "#7DBDB3", location: 60 },
      { color: "#008B87", location: 77 },
      { color: "#00A099", location: 90 },
    ],
  },
  {
    name: "LIGHT BLUE",
    color: "#5bc5f2",
    gradient: [
      { color: "#5BC5F2", location: 10 },
      { color: "#A1D8F7", location: 25 },
      { color: "#3AB9E8", location: 27 },
      { color: "#5BC5F2", location: 50 },
      { color: "#A1D8F7", location: 60 },
      { color: "#3AB9E8", location: 77 },
      { color: "#5BC5F2", location: 90 },
    ],
  },
  {
    name: "BLUE",
    color: "#009fe3",
    gradient: [
      { color: "#009FE3", location: 10 },
      { color: "#71CBF4", location: 25 },
      { color: "#0091D1", location: 27 },
      { color: "#009FE3", location: 50 },
      { color: "#71CBF4", location: 60 },
      { color: "#0091D1", location: 77 },
      { color: "#009FE3", location: 90 },
    ],
  },
  {
    name: "TM BLUE",
    color: "#1c7fc1",
    gradient: [
      { color: "#1C7FC1", location: 10 },
      { color: "#51AFE3", location: 25 },
      { color: "#0077BE", location: 27 },
      { color: "#1C7FC1", location: 50 },
      { color: "#51AFE3", location: 60 },
      { color: "#0077BE", location: 77 },
      { color: "#1C7FC1", location: 90 },
    ],
  },
  {
    name: "YAM BLUE",
    color: "#1d2b8b",
    gradient: [
      { color: "#1D2B8B", location: 10 },
      { color: "#33539F", location: 25 },
      { color: "#29206D", location: 27 },
      { color: "#1D2B8B", location: 50 },
      { color: "#33539F", location: 60 },
      { color: "#29206D", location: 77 },
      { color: "#1D2B8B", location: 90 },
    ],
  },
  {
    name: "HVA BLUE",
    color: "#000c3d",
    gradient: [
      { color: "#000C3D", location: 10 },
      { color: "#213660", location: 25 },
      { color: "#101025", location: 27 },
      { color: "#000C3D", location: 50 },
      { color: "#213660", location: 60 },
      { color: "#101025", location: 77 },
      { color: "#000C3D", location: 90 },
    ],
  },
  {
    name: "DARK PURPLE",
    color: "#25165b",
    gradient: [
      { color: "#25165B", location: 10 },
      { color: "#313575", location: 25 },
      { color: "#241F4C", location: 27 },
      { color: "#25165B", location: 50 },
      { color: "#313575", location: 60 },
      { color: "#241F4C", location: 77 },
      { color: "#25165B", location: 90 },
    ],
  },
  {
    name: "PURPLE",
    color: "#5b167f",
    gradient: [
      { color: "#5B167F", location: 10 },
      { color: "#6B468A", location: 25 },
      { color: "#4F2370", location: 27 },
      { color: "#5B167F", location: 50 },
      { color: "#6B468A", location: 60 },
      { color: "#4F2370", location: 77 },
      { color: "#5B167F", location: 90 },
    ],
  },
  {
    name: "LIGHT PURPLE",
    color: "#990b8f",
    gradient: [
      { color: "#990B8F", location: 10 },
      { color: "#A84895", location: 25 },
      { color: "#831975", location: 27 },
      { color: "#990B8F", location: 50 },
      { color: "#A84895", location: 60 },
      { color: "#831975", location: 77 },
      { color: "#990B8F", location: 90 },
    ],
  },
  {
    name: "MAGENTA",
    color: "#e6007e",
    gradient: [
      { color: "#E6007E", location: 10 },
      { color: "#E95595", location: 25 },
      { color: "#C5006C", location: 27 },
      { color: "#E6007E", location: 50 },
      { color: "#E95595", location: 60 },
      { color: "#C5006C", location: 77 },
      { color: "#E6007E", location: 90 },
    ],
  },
  {
    name: "PINK",
    color: "#ea528e",
    gradient: [
      { color: "#EA528E", location: 10 },
      { color: "#EE82A6", location: 25 },
      { color: "#DB417F", location: 27 },
      { color: "#EA528E", location: 50 },
      { color: "#EE82A6", location: 60 },
      { color: "#DB417F", location: 77 },
      { color: "#EA528E", location: 90 },
    ],
  },
  {
    name: "BROWN",
    color: "#663300",
    gradient: [
      { color: "#663300", location: 10 },
      { color: "#834824", location: 25 },
      { color: "#582E08", location: 27 },
      { color: "#663300", location: 50 },
      { color: "#834824", location: 60 },
      { color: "#582E08", location: 77 },
      { color: "#663300", location: 90 },
    ],
  },
  {
    name: "LIGHT BROWN",
    color: "#996a3f",
    gradient: [
      { color: "#97673D", location: 10 },
      { color: "#BC7E4C", location: 25 },
      { color: "#895E37", location: 27 },
      { color: "#97673D", location: 50 },
      { color: "#BC7E4C", location: 60 },
      { color: "#895E37", location: 77 },
      { color: "#97673D", location: 90 },
    ],
  },
  {
    name: "BEIGE",
    color: "#c69c6d",
    gradient: [
      { color: "#C69C6D", location: 10 },
      { color: "#E9B889", location: 25 },
      { color: "#B48E63", location: 27 },
      { color: "#C69C6D", location: 50 },
      { color: "#E9B889", location: 60 },
      { color: "#B48E63", location: 77 },
      { color: "#C69C6D", location: 90 },
    ],
  },
  {
    name: "SAND",
    color: "#c7b299",
    gradient: [
      { color: "#C7B299", location: 10 },
      { color: "#E3CDBA", location: 25 },
      { color: "#B8A58F", location: 27 },
      { color: "#C7B299", location: 50 },
      { color: "#E3CDBA", location: 60 },
      { color: "#B8A58F", location: 77 },
      { color: "#C7B299", location: 90 },
    ],
  },
  {
    name: "TAUPE",
    color: "#998675",
    gradient: [
      { color: "#998675", location: 10 },
      { color: "#BDA490", location: 25 },
      { color: "#917F6F", location: 27 },
      { color: "#998675", location: 50 },
      { color: "#BDA490", location: 60 },
      { color: "#917F6F", location: 77 },
      { color: "#998675", location: 90 },
    ],
  },
  {
    name: "DARK TAUPE",
    color: "#736357",
    gradient: [
      { color: "#736357", location: 10 },
      { color: "#8F7B6D", location: 25 },
      { color: "#66584D", location: 27 },
      { color: "#736357", location: 50 },
      { color: "#8F7B6D", location: 60 },
      { color: "#66584D", location: 77 },
      { color: "#736357", location: 90 },
    ],
  },
  {
    name: "RG1 Noir",
    color: "#111111",
    thumbnailUrl: new URL(rg1Noir, import.meta.url).href,
  },
  {
    name: "RG2 Gris foncé",
    color: "#3c3c3b",
    thumbnailUrl: new URL(rg2GrisFonce, import.meta.url).href,
  },
  {
    name: "RG3 Gris moyen",
    color: "#636363",
    thumbnailUrl: new URL(rg3GrisMoyen, import.meta.url).href,
  },
  {
    name: "RG4 Gris clair",
    color: "#969593",
    thumbnailUrl: new URL(rg4GrisClair, import.meta.url).href,
  },
  {
    name: "RG5 Bleu HVA",
    color: "#000c3d",
    thumbnailUrl: new URL(rg5BleuHVA, import.meta.url).href,
  },
  {
    name: "RG6 Bleu Yam",
    color: "#1d2b8b",
    thumbnailUrl: new URL(rg6BleuYam, import.meta.url).href,
  },
  {
    name: "RG7 Bleu TM",
    color: "#0062ba",
    thumbnailUrl: new URL(rg7BleuTM, import.meta.url).href,
  },
  {
    name: "RG8 Cyan",
    color: "#14b6ef",
    thumbnailUrl: new URL(rg8BleuCyan, import.meta.url).href,
  },
  {
    name: "RG9 Rouge Honda",
    color: "#b70b0b",
    thumbnailUrl: new URL(rg9RougeHonda, import.meta.url).href,
  },
  {
    name: "RG10 Rouge fluo Beta",
    color: "#e00a0a",
    thumbnailUrl: new URL(rg10RougeBetaFluo, import.meta.url).href,
  },
  {
    name: "RG11 Orange KTM",
    color: "#e95a0c",
    thumbnailUrl: new URL(rg11OrangeKTM, import.meta.url).href,
  },
  {
    name: "RG12 Rose",
    color: "#d83d7d",
    thumbnailUrl: new URL(rg12Rose, import.meta.url).href,
  },
  {
    name: "RG13 Violet",
    color: "#7533bc",
    thumbnailUrl: new URL(rg13Violet, import.meta.url).href,
  },
  {
    name: "RG14 Jaune Suz",
    color: "#ffed00",
    thumbnailUrl: new URL(rg14JauneSuz, import.meta.url).href,
  },
  {
    name: "RG15 Vert Kawa",
    color: "#4b9900",
    thumbnailUrl: new URL(rg15VertKawa, import.meta.url).href,
  },
  {
    name: "RG16 Blanc",
    color: "#f2f2f2",
    thumbnailUrl: new URL(rg16Blanc, import.meta.url).href,
  },
  {
    name: "RG17 Sable",
    color: "#c69c6d",
    thumbnailUrl: new URL(rg17Sable, import.meta.url).href,
  },
];
export const NO_COLOR: Color = { name: "NO_NAME", color: "transparent" };

export const CUSTOM_LAB_PATTERNS: Pattern[] = [
  {
    name: "TOPOGRAPHIC BLACK",
    size: 350,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Ftopogrphic_black.svg?alt=media&token=d9b82646-9a9e-4c15-a200-bd50827f70f8",
    thumbnailSize: 300,
  },
  {
    name: "TOPOGRAPHIC GREY",
    size: 350,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Ftopogrphic_grey80.svg?alt=media&token=52c7708b-f852-4e34-bac6-28216dece13b",
    thumbnailSize: 300,
  },
  {
    name: "TOPOGRAPHIC LIGHT GREY",
    size: 350,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Ftopogrphic_light-grey.svg?alt=media&token=abb62012-c08f-4c39-8142-25e1e8ff0b3b",
    thumbnailSize: 300,
  },
  {
    name: "TOPOGRAPHIC WHITE",
    size: 350,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Ftopogrphic_white.svg?alt=media&token=83eb79de-b390-447c-a84e-9962051b7d10",
    thumbnailSize: 300,
  },
  {
    name: "ELEPHANT BLACK",
    size: 150,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Felephant_black.svg?alt=media&token=3741c46e-bd2f-41b7-a89a-ad21f85479f7",
    thumbnailSize: 150,
  },
  {
    name: "ELEPHANT GREY",
    size: 150,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Felephant_grey80.svg?alt=media&token=9d5d11e1-583a-4488-9bac-9969cd76dc0b",
    thumbnailSize: 150,
  },
  {
    name: "ELEPHANT LIGHT GREY",
    size: 150,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Felephant_light-grey.svg?alt=media&token=03b83c5c-7465-402f-af76-c9bd93b2c310",
    thumbnailSize: 150,
  },
  {
    name: "ELEPHANT WHITE",
    size: 150,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Felephant_white.svg?alt=media&token=a0008982-5033-42da-bc1f-68681c8bbfd7",
    thumbnailSize: 150,
  },
  {
    name: "LEOPARD BLACK",
    size: 180,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fleopard_black.svg?alt=media&token=0b604452-54ec-4bf4-9c2b-1b1d1c645e5b",
    thumbnailSize: 125,
  },
  {
    name: "LEOPARD GREY",
    size: 180,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fleopard_grey80.svg?alt=media&token=01ae56cb-9012-44d5-9ccc-9df2fbd25106",
    thumbnailSize: 125,
  },
  {
    name: "LEOPARD LIGHT GREY",
    size: 180,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fleopard_light-grey.svg?alt=media&token=00a5cb3f-63bb-4c4f-9795-cea867b3fa46",
    thumbnailSize: 125,
  },
  {
    name: "LEOPARD WHITE",
    size: 180,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fleopard_white.svg?alt=media&token=d0cce2bf-5ff6-4ef2-a6a9-569d1a9695ff",
    thumbnailSize: 125,
  },
  {
    name: "ZEBRE BLACK",
    size: 180,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fzebre_black.svg?alt=media&token=6f91b3b6-065d-4e1b-b031-7980ba8fa644",
    thumbnailSize: 80,
  },
  {
    name: "ZEBRE GREY",
    size: 180,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fzebre_grey80.svg?alt=media&token=1af89fbb-9b85-4387-bd28-1825d790eb11",
    thumbnailSize: 80,
  },
  {
    name: "ZEBRE LIGHT GREY",
    size: 180,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fzebre_light-grey.svg?alt=media&token=d9119f3a-70ee-439f-993c-e70f8e1064d2",
    thumbnailSize: 80,
  },
  {
    name: "ZEBRE WHITE",
    size: 180,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fzebre_white.svg?alt=media&token=3769ebb5-ef88-4f80-af4c-e28947ba8b53",
    thumbnailSize: 80,
  },
  {
    name: "DAMIER BLACK",
    size: 100,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fdamier_black.svg?alt=media&token=ca884995-9f11-42bc-b46d-f49df3713283",
    thumbnailSize: 100,
  },
  {
    name: "DAMIER GREY",
    size: 100,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fdamier_grey80.svg?alt=media&token=82015a08-cfa8-4c8a-8798-e1559eaecf0b",
    thumbnailSize: 100,
  },
  {
    name: "DAMIER LIGHT GREY",
    size: 100,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fdamier_light-grey.svg?alt=media&token=e395492e-fa91-4929-88b9-10ebaf68b61f",
    thumbnailSize: 100,
  },
  {
    name: "DAMIER WHITE",
    size: 100,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fdamier_white.svg?alt=media&token=ff439a42-c9c4-48a7-85e4-522052914a41",
    thumbnailSize: 100,
  },
  {
    name: "PETROL BLACK",
    size: 250,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fpetrol_black.svg?alt=media&token=a387ea18-837f-47b5-acca-4053c03fd865",
    thumbnailSize: 140,
  },
  {
    name: "PETROL GREY",
    size: 250,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fpetrol_grey80.svg?alt=media&token=a19f0dfc-86ef-416e-8d7e-6dd7719ee9da",
    thumbnailSize: 140,
  },
  {
    name: "PETROL LIGHT GREY",
    size: 250,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fpetrol_light-grey.svg?alt=media&token=e1de013a-6a5e-429e-a5e2-c67a9be30d12",
    thumbnailSize: 140,
  },
  {
    name: "PETROL WHITE",
    size: 250,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fpetrol_white.svg?alt=media&token=27e3f1d4-1c85-473c-be58-40f364f0c928",
    thumbnailSize: 140,
  },
  {
    name: "CAMO BLACK",
    size: 250,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fcamo_black.svg?alt=media&token=40b9bc98-d224-4388-9c54-06c580a519d8",
    thumbnailSize: 60,
  },
  {
    name: "CAMO GREY",
    size: 250,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fcamo_grey80.svg?alt=media&token=5a92137b-7614-468c-9fad-263e162bbeb1",
    thumbnailSize: 60,
  },
  {
    name: "CAMO LIGHT GREY",
    size: 250,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fcamo_light-grey.svg?alt=media&token=73a2f0bd-dbf0-4b80-a36b-54e69e2a5197",
    thumbnailSize: 60,
  },
  {
    name: "CAMO WHITE",
    size: 250,
    url: "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Patterns%2Fcamo_white.svg?alt=media&token=255e161b-723f-49ca-99b3-f057219eb497",
    thumbnailSize: 60,
  },
];

// Fonts for the custom elements
export const CUSTOM_TEXTS_FONTS = [
  "Style_1",
  "Style_2",
  "Style_3",
  "Style_4",
  "Style_5",
  "Style_6",
  "Style_7",
  "Style_8",
  "Style_9",
  "Style_10",
  "Style_11",
  "Style_12",
  "Style_13",
  "Style_14",
  "Style_15",
  "Style_16",
  "Style_17",
  "Style_18",
  "Style_19",
  "Style_20",
];
export const CUSTOM_NUMBERS_FONTS = [
  "Style_1",
  "Style_2",
  "Style_3",
  "Style_4",
  "Style_5",
  "Style_6",
];

export const FOCUS_CHECKBOX_DEFAULT_TEXT = "Afficher le kit deco";

// Models
export const DEFAULT_MODEL_COLOR_LAYERS_CONFIG: Model["colorLayersConfig"] = [
  {
    colorLayerId: "kit_deco",
    colorList: CUSTOM_LAB_COLORS,
    colorModes: ["flat", "chromium", "chromium-white"],
    patterns: CUSTOM_LAB_PATTERNS,
  },
  {
    colorLayerId: "kit_plastique",
    colorList: [
      {
        name: "BLACK",
        color: "#111111",
        gradient: [
          { color: "#060606", location: 10 },
          { color: "#4F4A50", location: 25 },
          { color: "#272B32", location: 27 },
          { color: "#060606", location: 50 },
          { color: "#4F4A50", location: 60 },
          { color: "#272B32", location: 77 },
          { color: "#060606", location: 90 },
        ],
      },
      {
        name: "DARK GREY",
        color: "#3c3c3b",
        gradient: [
          { color: "#3C3C3B", location: 10 },
          { color: "#4C4C4C", location: 25 },
          { color: "#272B32", location: 27 },
          { color: "#3C3C3B", location: 50 },
          { color: "#4C4C4C", location: 60 },
          { color: "#272B32", location: 77 },
          { color: "#3C3C3B", location: 90 },
        ],
      },
      {
        name: "HVA GREY",
        color: "#969593",
        gradient: [
          { color: "#969593", location: 10 },
          { color: "#A09F9D", location: 25 },
          { color: "#7D7D7C", location: 27 },
          { color: "#969593", location: 50 },
          { color: "#A09F9D", location: 60 },
          { color: "#7D7D7C", location: 77 },
          { color: "#969593", location: 90 },
        ],
      },
      {
        name: "WHITE",
        color: "#f2f2f2",
        gradient: [
          { color: "#f2f2f2", location: 0 },
          { color: "#ececec", location: 9 },
          { color: "#dddddd", location: 11 },
          { color: "#ffffff", location: 21 },
          { color: "#c2c2c1", location: 22 },
          { color: "#ececec", location: 34 },
          { color: "#dddddd", location: 36 },
          { color: "#f2f2f2", location: 54 },
          { color: "#cdcdcc", location: 56 },
          { color: "#ffffff", location: 69 },
          { color: "#c2c2c1", location: 70 },
          { color: "#f2f2f2", location: 85 },
          { color: "#dddddd", location: 87 },
          { color: "#f2f2f2", location: 100 },
        ],
      },
      {
        name: "HONDA RED",
        color: "#e00a0a",
        gradient: [
          { color: "#E00A0A", location: 10 },
          { color: "#E25D56", location: 25 },
          { color: "#B01714", location: 27 },
          { color: "#E00A0A", location: 50 },
          { color: "#E25D56", location: 60 },
          { color: "#B01714", location: 77 },
          { color: "#E00A0A", location: 90 },
        ],
      },
      {
        name: "KTM ORANGE",
        color: "#e95a0c",
        gradient: [
          { color: "#e95a0C", location: 10 },
          { color: "#f3a473", location: 25 },
          { color: "#ec732f", location: 27 },
          { color: "#e95a0C", location: 50 },
          { color: "#f3a473", location: 60 },
          { color: "#ec732f", location: 77 },
          { color: "#e95a0C", location: 90 },
        ],
      },
      {
        name: "SUZUKI YELLOW",
        color: "#ffed00",
        gradient: [
          { color: "#FFED00", location: 10 },
          { color: "#FFEDA1", location: 25 },
          { color: "#EACD21", location: 27 },
          { color: "#FFED00", location: 50 },
          { color: "#FFEDA1", location: 60 },
          { color: "#EACD21", location: 77 },
          { color: "#FFED00", location: 90 },
        ],
      },
      {
        name: "KAWA GREEN",
        color: "#4b9900",
        gradient: [
          { color: "#4B9900", location: 10 },
          { color: "#7AB15F", location: 25 },
          { color: "#418431", location: 27 },
          { color: "#4B9900", location: 50 },
          { color: "#7AB15F", location: 60 },
          { color: "#418431", location: 77 },
          { color: "#4B9900", location: 90 },
        ],
      },
      {
        name: "TM BLUE",
        color: "#1c7fc1",
        gradient: [
          { color: "#1C7FC1", location: 10 },
          { color: "#51AFE3", location: 25 },
          { color: "#0077BE", location: 27 },
          { color: "#1C7FC1", location: 50 },
          { color: "#51AFE3", location: 60 },
          { color: "#0077BE", location: 77 },
          { color: "#1C7FC1", location: 90 },
        ],
      },
      {
        name: "YAM BLUE",
        color: "#1d2b8b",
        gradient: [
          { color: "#1D2B8B", location: 10 },
          { color: "#33539F", location: 25 },
          { color: "#29206D", location: 27 },
          { color: "#1D2B8B", location: 50 },
          { color: "#33539F", location: 60 },
          { color: "#29206D", location: 77 },
          { color: "#1D2B8B", location: 90 },
        ],
      },
      {
        name: "HVA BLUE",
        color: "#000c3d",
        gradient: [
          { color: "#000C3D", location: 10 },
          { color: "#213660", location: 25 },
          { color: "#101025", location: 27 },
          { color: "#000C3D", location: 50 },
          { color: "#213660", location: 60 },
          { color: "#101025", location: 77 },
          { color: "#000C3D", location: 90 },
        ],
      },
      {
        name: "MAGENTA",
        color: "#e6007e",
        gradient: [
          { color: "#E6007E", location: 10 },
          { color: "#E95595", location: 25 },
          { color: "#C5006C", location: 27 },
          { color: "#E6007E", location: 50 },
          { color: "#E95595", location: 60 },
          { color: "#C5006C", location: 77 },
          { color: "#E6007E", location: 90 },
        ],
      },
    ],
    focusText: FOCUS_CHECKBOX_DEFAULT_TEXT,
    menuText:
      "Configure la moto comme la tienne !\nKit plastique non inclus avec le kit deco.",
  },
  {
    colorLayerId: "couleur_coque",
    colorList: CUSTOM_LAB_COLORS,
    focusText: FOCUS_CHECKBOX_DEFAULT_TEXT,
  },
];

// Custom Elements
export const CUSTOM_ELEMENTS_MIN_WIDTH = 25;
export const CUSTOM_ELEMENTS_MIN_HEIGHT = 25;

// Logos
export const LOGO_DEFAULT_WIDTH = -1;
export const LOGO_DEFAULT_HEIGHT = -1;
export const LOGO_MAX_WIDTH = 1000;
export const LOGO_MIN_WIDTH = 1;
export const LOGO_MAX_HEIGHT = 1000;
export const LOGO_MIN_HEIGHT = 1;

// Custom numbers
export const CUSTOM_NUMBERS_DEFAULT_FONT = CUSTOM_NUMBERS_FONTS[0];
export const CUSTOM_NUMBERS_DEFAULT_FONT_SIZE = 132;
export const CUSTOM_NUMBERS_MAX_FONT_SIZE = 400;
export const CUSTOM_NUMBERS_MIN_FONT_SIZE = 6;
export const CUSTOM_NUMBERS_DEFAULT_COLOR = CUSTOM_LAB_COLORS[0];
export const CUSTOM_NUMBERS_STROKE_DEFAULT_COLOR =
  CUSTOM_LAB_COLORS.find((c) => c.name === "KTM ORANGE")?.color ?? "#fff";
export const CUSTOM_NUMBERS_STROKE_MAX_WIDTH = 30;
export const CUSTOM_NUMBERS_MAX_SPACING = 30;
export const CUSTOM_NUMBERS_MIN_SPACING = -30;

// Custom text
export const CUSTOM_TEXT_DEFAULT_FONT = CUSTOM_TEXTS_FONTS[0];
export const CUSTOM_TEXT_DEFAULT_FONT_SIZE = 64;
export const CUSTOM_TEXT_MAX_FONT_SIZE = 400;
export const CUSTOM_TEXT_MIN_FONT_SIZE = 6;
export const CUSTOM_TEXT_DEFAULT_COLOR = CUSTOM_LAB_COLORS[0];
export const CUSTOM_TEXT_STROKE_DEFAULT_COLOR =
  CUSTOM_LAB_COLORS.find((c) => c.name === "KTM ORANGE")?.color ?? "#fff";
export const CUSTOM_TEXT_STROKE_MAX_WIDTH = 50;
export const CUSTOM_TEXT_MAX_SPACING = 100;
export const CUSTOM_TEXT_MIN_SPACING = -50;

export const CUSTOM_TEXT_SPACING_RATIO = 50;
export const CUSTOM_TEXT_FONT_SIZE_STEP = 3;
export const CUSTOM_TEXT_STROKE_WIDTH_RATIO = 50;

// Selection box
export const SELECTION_BOX_RECT = {
  fill: "none",
  stroke: {
    color: "#0ea5e9",
    width: 5,
    dasharray: "20,20",
    linecap: "square",
    linejoin: "round",
  },
};
export const SELECTION_BOX_ROTATE_HANDLE = {
  size: 20,
  fill: "#0ea5e9",
  cursor: "alias",
};
export const SELECTION_BOX_RESIZE_HANDLE = {
  size: 20,
  fill: "#0ea5e9",
  cursor: "nwse-resize",
};
export const SELECTION_BOX_REMOVE_CROSS = {
  size: 20,
  circleColor: "white",
  lineColors: "red",
};

// Admin
export const ADMIN_ERROR_MESSAGES = {
  "auth/invalid-credential": "Combinaison email/mot de passe incorrect",
  "auth/invalid-email": "Email invalide",
};

// Color modes
export const colorModes = ["flat", "chromium", "chromium-white"] as const;
export const colorModeNames: Record<ColorMode, string> = {
  flat: "Standard",
  chromium: "Chrome",
  "chromium-white": "Chrome + Blanc",
};

// Notices
export const PLASTICS_DEFAULT_NOTICE: LocalizedNotice = {
  fr: {
    id: "kit_plastic",
    title: "Kit Plastique",
    description:
      "Clique sur un plastique, et modifie sa couleur dans le menu à gauche.",
    summary:
      "Dans cet onglet, choisis la couleur des plastiques et housse de selle pour une meilleur visualisation de ton kit déco",
    videoUrl:
      "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Notice%20videos%2Fnotice-video-plastics.MOV?alt=media&token=573d0713-4fa4-4152-91ed-9d366c9f082e",
  },
  en: {
    id: "kit_plastic",
    title: "Plastics",
    description: "Click on a plastic, and change its color in the left menu.",
    summary:
      "In this tab, change the colors of the plastics and seat cover for a better visualization of your graphic kit",
    videoUrl:
      "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Notice%20videos%2Fnotice-video-plastics.MOV?alt=media&token=573d0713-4fa4-4152-91ed-9d366c9f082e",
  },
};
export const COULEUR_COQUE_DEFAULT_NOTICE: LocalizedNotice = {
  fr: {
    id: "couleur_coque",
    title: "Couleur coque",
    description:
      "Clique sur la coque, et modifie sa couleur dans le menu à gauche.",
    summary:
      "Dans cet onglet, choisis la couleur de la coque pour une meilleure visualisation de ton kit déco",
    videoUrl:
      "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Notice%20videos%2Fnotice-video-plastics.MOV?alt=media&token=573d0713-4fa4-4152-91ed-9d366c9f082e",
  },
  en: {
    id: "couleur_coque",
    title: "Hull color",
    description: "Click on the hull, and change its color in the left menu.",
    summary:
      "In this tab, change the color of the hull for a better visualization of your graphic kit",
    videoUrl:
      "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Notice%20videos%2Fnotice-video-plastics.MOV?alt=media&token=573d0713-4fa4-4152-91ed-9d366c9f082e",
  },
};
export const GRAPHIC_KIT_DEFAULT_NOTICE: LocalizedNotice = {
  fr: {
    id: "kit_deco",
    title: "Kit Déco",
    description:
      "Sélectionne directement sur le kit déco la couleur à modifier.",
    summary: "Dans cet onglet, change les couleurs du kit deco",
    videoUrl:
      "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Notice%20videos%2Fnotice-video-graphic-kit.MOV?alt=media&token=7f83b8ab-c559-4d99-b11d-61c00a1245b8",
  },
  en: {
    id: "kit_deco",
    title: "Graphic Kit",
    description:
      "Click direclty on the graphic kit the color you want to change.",
    summary: "In this tab, change the colors of the graphic kit",
    videoUrl:
      "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Notice%20videos%2Fnotice-video-graphic-kit.MOV?alt=media&token=7f83b8ab-c559-4d99-b11d-61c00a1245b8",
  },
};
export const LOGOS_DEFAULT_NOTICE: LocalizedNotice = {
  fr: {
    id: "logos",
    title: "Logos",
    description: "",
    summary: "Dans cet onglet, ajoute/supprime/importe des logos",
    videoUrl:
      "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Notice%20videos%2Fnotice-video-logos.MOV?alt=media&token=4a749965-afb9-4a4f-ad03-78e5cd068440",
  },
  en: {
    id: "logos",
    title: "Logos",
    description: "",
    summary: "In this tab, add/delete/import logos",
    videoUrl:
      "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Notice%20videos%2Fnotice-video-logos.MOV?alt=media&token=4a749965-afb9-4a4f-ad03-78e5cd068440",
  },
};
export const CUSTOM_NUMBERS_DEFAULT_NOTICE: LocalizedNotice = {
  fr: {
    id: "custom_numbers",
    title: "Numéros",
    description: "",
    summary: "Dans cet onglet, ajoute tes numéros",
    videoUrl:
      "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Notice%20videos%2Fnotice-video-numbers.MOV?alt=media&token=5aa8a426-3043-47b0-b0eb-b96f16856744",
  },
  en: {
    id: "custom_numbers",
    title: "Numbers",
    description: "",
    summary: "In this tab, add your numbers",
    videoUrl:
      "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Notice%20videos%2Fnotice-video-numbers.MOV?alt=media&token=5aa8a426-3043-47b0-b0eb-b96f16856744",
  },
};
export const CUSTOM_TEXTS_DEFAULT_NOTICE: LocalizedNotice = {
  fr: {
    id: "custom_texts",
    title: "Textes",
    description: "",
    summary: "Dans cet onglet, ajoute un nom ou du texte",
    videoUrl:
      "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Notice%20videos%2Fnotice-video-texts.MOV?alt=media&token=f2554ad9-b81f-4bec-80e3-3b0a49e63484",
  },
  en: {
    id: "custom_texts",
    title: "Texts",
    description: "",
    summary: "In this tab, add your name or some texts",
    videoUrl:
      "https://firebasestorage.googleapis.com/v0/b/mxlab-customlab.appspot.com/o/Notice%20videos%2Fnotice-video-texts.MOV?alt=media&token=f2554ad9-b81f-4bec-80e3-3b0a49e63484",
  },
};

export const defaultNotices: Record<string, LocalizedNotice> = {
  kit_plastique: PLASTICS_DEFAULT_NOTICE,
  couleur_coque: COULEUR_COQUE_DEFAULT_NOTICE,
  kit_deco: GRAPHIC_KIT_DEFAULT_NOTICE,
  logos: LOGOS_DEFAULT_NOTICE,
  custom_numbers: CUSTOM_NUMBERS_DEFAULT_NOTICE,
  custom_texts: CUSTOM_TEXTS_DEFAULT_NOTICE,
};

type HELP_QUESTION = {
  readonly question: string;
  readonly answer: string;
};

export const HELP_QUESTIONS: Record<"fr" | "en", HELP_QUESTION[]> = {
  fr: [
    {
      question: "Help ! J’ai besoin d’une démonstration",
      answer:
        "Une vidéo d’aide est disponible pour chaque étape du processus, retrouve les à tout moment dans l’onglet « NOTICE » en bas de l’écran ou en cliquant sur « ? » en haut à gauche de l'écran",
    },
    {
      question: "Les plastiques ne correspondent pas à l’année de ma moto",
      answer:
        "Pas d’inquiétude, nos graphistes adapteront le kit déco sur la maquette que tu recevras par mail. Mentionne le modèle et l’année de ta moto dans l’encart « Modèle » du formulaire de commande",
    },
    {
      question: "Si je quitte la page est ce que je perd ma configuration ?",
      answer:
        "Non, ta configuration est sauvegardée automatiquement dans ton navigateur, clique sur CONTINUER lorsque tu re ouvres le configurateur pour reprendre ta création",
    },
    {
      question: "Puis-je zoomer sur mon kit déco ?",
      answer:
        "Utilise la molette de ta souris/ton pad ou les outils + et - en haut à gauche de l’écran pour agrandir/réduire le visuel",
    },
    {
      question: "Comment changer la couleur de mes plastiques / de ma coque ?",
      answer:
        "Il s’agit de la premiere étape lorsque tu démarres la configuration, tu peux aussi cliquer sur « kit plastique » ou « couleur coque » dans le menu en bas de page. Clique ensuite sur la partie concernée et change sa couleur dans le nuancier à gauche de l’écran",
    },
    {
      question: "Comment changer les couleurs de mon kit déco ?",
      answer:
        "Clique sur « KIT DÉCO » dans le menu en bas de la page, une fois dans cet onglet, clique sur n’importe quelle partie du kit déco puis change sa couleur et/ou ajoute des motifs dans le nuancier à gauche de l’écran",
    },
    {
      question: "Je souhaite un kit déco finition chrome",
      answer:
        "Dans l’onglet « KIT DECO », en haut a gauche de ton écran, tu peux choisir ta base d’impression pour avoir un aperçu. Attention à bien sélectionner ta base d’impression dans le formulaire de commande du site sinon elle ne sera pas prise en compte",
    },
    {
      question: "Est ce que je peux ajouter un logo ?",
      answer:
        "Oui, clique sur « LOGOS » dans le menu en bas de la page, une fois dans cet onglet tu peux visiter notre « bibliothèque de logos » ou importer ton propre logo",
    },
    {
      question:
        "Est ce que je peux supprimer les logos présents sur le kit déco ?",
      answer:
        "Oui, clique sur « LOGOS » dans le menu en bas de la page, une fois dans cet onglet tu peux cliquer sur le logo de ton choix et le supprimer avec la croix rouge qui s’affiche",
    },
    {
      question: "Comment déplacer, agrandir ou réduire les logos ?",
      answer:
        "Clique sur « LOGOS » dans le menu en bas de la page, une fois dans cet onglet, clique sur le logo de ton choix. Sélectionne le rond bleu en haut à gauche du logo pour le faire pivoter, sélectionne le carré en bas à droite pour étirer et réduire le logo. Tu peux également utiliser la fenêtre à gauche de l’écran pour positionner manuellement le logo et le dupliquer",
    },
    {
      question:
        "Est ce que je peux modifier / supprimer / ajouter des numéros ?",
      answer:
        "Oui, clique sur « NUMÉROS » dans le menu en bas de la page, une fois dans cet onglet, clique sur le numero existant et modifie le dans le menu à gauche (numéro, style, couleur, contours). Utilise la fenêtre à gauche du visuel pour augmenter ou réduire sa taille manuellement (« taille du texte » en px). Duplique le en cliquant sur l’icône en bas. Supprime le en cliquant sur La Croix rouge ou l’icône supprimer. Tu peux ajouter de nouveau numéro dans le même menu en cliquant sur « Ajouter »",
    },
    {
      question: "Est ce que je peux ajouter mon nom, des écritures ?",
      answer:
        "Oui, clique sur « TEXTES » dans le menu en bas de la page, une fois dans cet onglet, utilise le menu a gauche de l’écran pour écrire et personnaliser ton texte (style, couleur, contours) puis clique sur « ajouter ». Utilise la fenêtre à gauche du visuel pour augmenter ou réduire sa taille manuellement (« taille du texte » en px). Duplique le en cliquant sur l’icône en bas. Supprime le en cliquant sur La Croix rouge ou l’icône supprimer. Clique sur un texte existant pour le modifier",
    },
    {
      question: "Pourquoi notre configurateur est-il génial ?",
      answer: "Bonne question ! Peut être parce que notre team est géniale ?",
    },
  ],
  en: [
    {
      question: "Help! I need a demonstration",
      answer:
        "A help video is available for each step of the process. Find them at any time in the “NOTICE” tab at the bottom of the screen or by clicking on « ? » at the top left of the screen",
    },
    {
      question: "The plastics don't match the year of my bike",
      answer:
        "Don't worry, our graphic designers will adapt the graphic kit to the mock-up you'll receive by e-mail. Mention the model and year of your motorcycle in the “Model” box on the order form",
    },
    {
      question: "If I leave the page, do I lose my configuration?",
      answer:
        "No, your configuration is automatically saved in your browser. Click on CONTINUE when you reopen the configurator to resume your creation",
    },
    {
      question: "Can I zoom in on my deco kit?",
      answer:
        "Use your mouse wheel/pad or the + and - tools at the top left of the screen to zoom in/out",
    },
    {
      question: "How do I change the color of my plastics / hull?",
      answer:
        "This is the first step when you start configuration, but you can also click on “PLASTICS” or “HULL COLOR” in the menu at the bottom of the page. Then click on the relevant part and change its color in the color chart on the left of the screen",
    },
    {
      question: "How do I change the colors of my graphic kit?",
      answer:
        "Click on “GRAPHIC KIT” in the menu at the bottom of the page. Once in this tab, click on any part of the deco kit, then change its color and/or add motifs in the color chart on the left of the screen",
    },
    {
      question: "I would like a chrome finish deco kit",
      answer:
        "In the “GRAPHIC KIT” tab, at the top left of your screen, you can choose your print base for a preview. Make sure you select your print base in the website order form, otherwise it won't be taken into account",
    },
    {
      question: "Can I add a logo?",
      answer:
        "Yes, click on “LOGOS” in the menu at the bottom of the page, once in this tab you can visit our “logo library” or import your own logo",
    },
    {
      question: "Can I remove the logos from the graphic kit?",
      answer:
        "Yes, click on “LOGOS” in the menu at the bottom of the page. Once in this tab, you can click on the logo of your choice and delete it with the red cross that appears",
    },
    {
      question: "How do I move, enlarge or reduce logos?",
      answer:
        "Click on “LOGOS” in the menu at the bottom of the page. Once in this tab, click on the logo of your choice. Select the blue circle at the top left of the logo to rotate it, and select the square at the bottom right to stretch and shrink the logo. You can also use the window on the left of the screen to manually position and duplicate the logo",
    },
    {
      question: "Can I modify / delete / add numbers?",
      answer:
        "Yes, click on “NUMBERS” in the menu at the bottom of the page. Once in this tab, click on the existing number and modify it in the menu on the left (number, style, color, outline). Use the window to the left of the visual to manually increase or reduce its size (“text size” in px). Duplicate it by clicking on the icon at the bottom. Delete it by clicking on the red cross or delete icon. You can add new numbers in the same menu by clicking on “Add”",
    },
    {
      question: "Can I add my name, some writings?",
      answer:
        "Yes, click on “TEXTS” in the menu at the bottom of the page. Once in this tab, use the menu on the left of the screen to write and customize your text (style, color, outline), then click on “add”. Use the window to the left of the visual to increase or reduce its size manually (“text size” in px). Duplicate it by clicking on the icon at the bottom. Delete it by clicking on the red cross or delete icon. Click on an existing text to modify it",
    },
    {
      question: "Why is our configurator great?",
      answer: "Good question! Maybe because our team is so great?",
    },
  ],
};

export const HELP_QUESTIONS_INTERVAL = 7000;
