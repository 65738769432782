import { useAdminContext } from "@admin/hooks/use-admin-context.ts";
import { useAdminLogin } from "@admin/hooks/use-admin-login.ts";
import mxlabLogo from "@assets/mxlab/logo_mxlab_3_white.png";
import { Button } from "@components/ui/button.tsx";
import InputIcon from "@components/ui/input-icon.tsx";
import Message from "@components/ui/message.tsx";
import { cn } from "@lib/utils.ts";
import React from "react";
import { FaLock, FaUser } from "react-icons/fa6";
import { Navigate } from "react-router-dom";

type AdminLoginRouteProps = React.ComponentPropsWithoutRef<"div">;

export default function AdminLoginRoute({
  className,
  ...props
}: AdminLoginRouteProps) {
  const {
    state: { isLogged },
  } = useAdminContext();

  const { formValues, setFormValues, loading, error, submitForm } =
    useAdminLogin();

  return isLogged ? (
    <Navigate to="/admin" />
  ) : (
    <div className={cn("grid place-items-center", className)} {...props}>
      <div
        className={cn(
          "card !bg-[#323232]",
          "flex flex-col items-center gap-6",
          "w-[600px] !p-14",
        )}
      >
        {/* header */}
        <div>
          <div
            className={cn(
              "text-center font-['Avant_Garde'] text-5xl font-bold uppercase",
              "-mb-2",
            )}
          >
            Custom Lab
          </div>
          <div className="text-center text-xl uppercase text-admin">
            Administration
          </div>
        </div>

        <div className="text-center text-2xl font-semibold uppercase">
          Connexion
        </div>

        <form
          className="flex flex-col gap-4 self-stretch px-6"
          onSubmit={submitForm}
        >
          {/* email */}
          <InputIcon
            name="email"
            type="email"
            placeholder="E-mail"
            autoComplete="username"
            value={formValues.email}
            onChange={(event) =>
              setFormValues({ ...formValues, email: event.target.value })
            }
            autoFocus
            Icon={FaUser}
            required
          />
          {/* password */}
          <InputIcon
            name="password"
            type="password"
            placeholder="Mot de passe"
            autoComplete="current-password"
            value={formValues.password}
            onChange={(event) =>
              setFormValues({ ...formValues, password: event.target.value })
            }
            Icon={FaLock}
            required
          />

          {/* submit */}
          <Button
            variant="primary"
            size="lg"
            className={cn("mt-4 text-lg uppercase", {
              "animate-[pulse_1s_ease-in-out_infinite]": loading,
            })}
            type="submit"
          >
            {loading ? "Connexion en cours..." : "Continuer"}
          </Button>
        </form>

        {/* error */}
        {error ? <Message type="error">{error}</Message> : null}

        {/* footer */}
        <div className="mt-2 flex flex-col items-center gap-1">
          <div className="-mb-1 text-lg">Powered by</div>
          <img src={mxlabLogo} alt="MXLab logo" width="70%" height="auto" />
        </div>
      </div>
    </div>
  );
}
