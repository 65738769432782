import { CustomModel } from "@/types/custom-model.ts";
import { Model } from "@/types/model.ts";
import { AdminState } from "@admin/admin.provider.tsx";
import { localStorageService } from "@lib/local-storage.ts";

export type AdminAction =
  | { type: "set_loading"; payload: boolean }
  | { type: "set_is_logged"; payload: boolean }
  | { type: "set_models"; payload: Model[] }
  | { type: "update_model"; payload: Model }
  | { type: "deleteModel"; payload: string }
  | { type: "set_custom_models"; payload: CustomModel[] }
  | { type: "deleteCustomModel"; payload: string }
  | { type: "deleteAllLocalCustomModels" };

export function adminReducer(
  state: AdminState,
  action: AdminAction,
): AdminState {
  switch (action.type) {
    case "set_loading":
      return { ...state, loading: action.payload };
    case "set_is_logged":
      return { ...state, isLogged: action.payload };
    case "set_models":
      return { ...state, models: action.payload };
    case "deleteModel":
      return {
        ...state,
        models: state.models.filter((model) => model.id !== action.payload),
      };
    case "set_custom_models":
      return { ...state, customModels: action.payload };
    case "deleteCustomModel":
      return {
        ...state,
        customModels: state.customModels.filter(
          (cm) => cm.id !== action.payload,
        ),
      };
    case "deleteAllLocalCustomModels": {
      localStorageService.resetState();
      return { ...state, customModels: [] };
    }
    // only update linked shopify product
    case "update_model":
      return {
        ...state,
        models: state.models.map((model) =>
          model.id === action.payload.id
            ? { ...model, shopifyProduct: action.payload.shopifyProduct }
            : model,
        ),
      };
    default:
      throw new Error("Unknown action");
  }
}
