import { Calendar as CalendarIcon } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { DayPickerRangeProps } from "react-day-picker";

export type DateRangePickerProps = Partial<DayPickerRangeProps> & {
  placeholder?: string;
};

export function DateRangePicker({
  className,
  placeholder,
  selected,
  onSelect,
  ...props
}: DateRangePickerProps) {
  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button id="date" variant="secondary" size="sm" className="bg-white">
            <CalendarIcon className="mr-2 h-4 w-4 stroke-slate-600" />

            {selected?.from ? (
              selected.to ? (
                <>
                  {format(selected.from, "dd/MM/yyyy")} -{" "}
                  {format(selected.to, "dd/MM/yyyy")}
                </>
              ) : (
                format(selected.from, "dd/MM/yyyy")
              )
            ) : (
              <span className="text-sm">
                {placeholder || "Sélectionner une date"}
              </span>
            )}
          </Button>
        </PopoverTrigger>

        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            defaultMonth={props?.defaultMonth || selected?.from || new Date()}
            numberOfMonths={2}
            selected={selected}
            onSelect={onSelect}
            {...props}
            mode="range"
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
