import { useAdminContext } from "@admin/hooks/use-admin-context.ts";
import mxlabLogo from "@assets/mxlab/logo_mxlab_3_white.png";
import { cn } from "@lib/utils.ts";
import React from "react";
import { MdAdminPanelSettings } from "react-icons/md";
import { Link } from "react-router-dom";

type SidebarLogoProps = React.ComponentPropsWithoutRef<"div">;

export default function SidebarLogo({ className, ...props }: SidebarLogoProps) {
  const {
    state: { isLogged },
  } = useAdminContext();

  return (
    <div className={cn("relative", className)} {...props}>
      {isLogged ? (
        <Link to="/admin">
          <img
            className="px-2"
            src={mxlabLogo}
            alt="MXLab logo"
            width="100%"
            height="100%"
          />

          <MdAdminPanelSettings
            className={cn(
              "absolute bottom-0 right-0 translate-y-2.5",
              "fill-info",
              "h-9 w-9",
            )}
          />
        </Link>
      ) : (
        <img
          className="px-2"
          src={mxlabLogo}
          alt="MXLab logo"
          width="100%"
          height="100%"
        />
      )}
    </div>
  );
}
