import { ColorGroup, ColorMode } from "@/types/color-elements.ts";
import ColorSquare from "@custom-model-editor/components/color-elements/color-square.tsx";
import { cn, normalizeStr } from "@lib/utils.ts";
import React from "react";
import { useTranslation } from "react-i18next";

type ColorGroupLinkProps = React.ComponentPropsWithoutRef<"div"> & {
  colorGroup: ColorGroup;
  colorMode: ColorMode;
};

/**
 * Link to a color group in the sidebar menu
 * Displays a summary of the color elements in the group
 * @param className
 * @param colorGroup
 * @param colorMode
 * @param props
 * @constructor
 */
export default function ColorGroupLink({
  className,
  colorGroup,
  colorMode,
  ...props
}: ColorGroupLinkProps) {
  const { t } = useTranslation("color-group-elements");

  return (
    <div
      className={cn(
        "rounded p-2",
        "cursor-pointer hover:bg-[#4a4a4a]",
        className,
      )}
      {...props}
    >
      <div className="text-lg font-bold">
        {t(normalizeStr(colorGroup.name), colorGroup.name)}
      </div>

      <div className="mt-2 flex flex-wrap items-center gap-1.5 xl:gap-2">
        {colorGroup.colorElements
          .filter((child) => child.color)
          .map((child) => (
            <ColorSquare
              key={child.id}
              color={child.color}
              colorMode={colorMode}
              pattern={child.pattern}
            />
          ))}
      </div>
    </div>
  );
}
