import { TextEditorValue } from "@custom-model-editor/components/text-editor/text-editor.tsx";
import {
  drawCustomTextElement,
  getSvgCenterPos,
} from "@custom-model-editor/lib/svg.ts";
import { cn, whiteOrBlack } from "@lib/utils.ts";
import { SVG, Svg } from "@svgdotjs/svg.js";
import React, { useCallback, useEffect } from "react";

type TextEditorPreviewProps = React.ComponentPropsWithoutRef<"div"> & {
  preview: TextEditorValue;
  gradient?: boolean;
};

/**
 * Display a preview of the text element inside a little svg box.
 */
export default function TextEditorPreview({
  className,
  preview,
  gradient = false,
  ...props
}: TextEditorPreviewProps) {
  const updatePreview = useCallback(async () => {
    // wait for the fonts to be loaded to avoid misplacement
    await document.fonts.ready;

    const svgId = "text-editor-preview";
    const svg = SVG(`#${svgId}`) as Svg;
    svg.clear();
    svg.group().id("custom-texts");

    // draw a background rectangle
    // choose the background color depending on the stroke and text color
    const bgColor =
      whiteOrBlack(
        preview.stroke.width > 0 ? preview.stroke.color : preview.color.color,
      ) === "#fff"
        ? "#f2f2f2"
        : "#111111";
    const background = svg.rect(2000, 2000).fill(bgColor).move(-1000, -1000);

    const { x, y } = getSvgCenterPos("text-editor-preview");

    drawCustomTextElement(
      svgId,
      {
        type: "custom-text",
        id: "text-preview",
        x,
        y,
        width: 0,
        height: 0,
        a: 0,
        customText: { ...preview, fontSize: 64 },
      },
      gradient,
    );

    const text = SVG(`#${svgId} #text-preview`);

    // center the text on the svg viewbox
    if (text) {
      text.before(background);
      text.translate(-text.bbox().width / 2, -text.bbox().height / 2 + 5);
    }
  }, [preview, gradient]);

  // update the preview at every change
  useEffect(() => {
    updatePreview();
  }, [preview, updatePreview]);

  return (
    <div className={cn("h-[50px] w-full lg:h-[75px]", className)} {...props}>
      {/* svg preview */}
      <svg
        id="text-editor-preview"
        className="rounded bg-white"
        width="100%"
        height="100%"
        viewBox="0 0 264 100"
      />
    </div>
  );
}
