import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@components/ui/alert-dialog.tsx";
import MyButton from "@components/ui/my-button.tsx";
import { cn } from "@lib/utils.ts";
import { AlertDialogProps } from "@radix-ui/react-alert-dialog";
import { LuFileEdit, LuFilePlus } from "react-icons/lu";

type ConfirmExportExistingModelDialogProps = AlertDialogProps & {
  onExportAsNew: () => void;
  onReplaceExisting: () => void;
};

export default function ConfirmExportExistingModelDialog({
  onExportAsNew,
  onReplaceExisting,
  ...props
}: ConfirmExportExistingModelDialogProps) {
  return (
    <AlertDialog {...props}>
      <AlertDialogContent className="flex w-[420px] flex-col items-center">
        <AlertDialogHeader className="mb-3 !text-center">
          <AlertDialogTitle>Ce modèle existe déjà</AlertDialogTitle>
          <AlertDialogDescription className="text-base">
            Comment veux-tu l'exporter ?
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogAction asChild>
          <button
            className={cn(
              "grid grid-cols-[1fr_auto_1fr] items-center justify-center",
              "h-12 w-[350px] px-4",
              "rounded border border-primary bg-primary text-white",
              "hover:opacity-75",
            )}
            onClick={onExportAsNew}
          >
            <LuFilePlus className="h-5 w-5 self-center" />
            <span>Exporter comme nouveau modèle</span>
            <span></span>
          </button>
        </AlertDialogAction>
        <AlertDialogAction asChild>
          <button
            className={cn(
              "grid grid-cols-[1fr_auto_1fr] items-center justify-center",
              "h-12 w-[350px] px-4",
              "rounded border border-primary text-primary",
              "hover:opacity-75",
            )}
            onClick={onReplaceExisting}
          >
            <LuFileEdit className="h-5 w-5 self-center" />
            <span>Remplacer l'existant</span>
            <span></span>
          </button>
        </AlertDialogAction>

        <AlertDialogFooter>
          <AlertDialogCancel asChild>
            <MyButton className="border-none bg-transparent text-slate-600">
              Annuler
            </MyButton>
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
