import { Oval, OvalProps } from "react-loader-spinner";

type OvalLoaderProps = OvalProps;

export default function OvalLoader({ ...props }: OvalLoaderProps) {
  return (
    <Oval
      color="#e95a0c"
      secondaryColor="#e95a0c"
      width={200}
      height={200}
      strokeWidth={3}
      {...props}
    />
  );
}
