import { HELP_QUESTIONS, HELP_QUESTIONS_INTERVAL } from "@/config.ts";
import { cn } from "@/lib/utils";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@components/ui/accordion.tsx";

import { CircleHelp, X } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type HelpCardProps = React.ComponentPropsWithoutRef<"div">;

export default function HelpCard({ className, ...props }: HelpCardProps) {
  const [open, setOpen] = useState<boolean>(false);
  // for interval
  const [currQuestionIndex, setCurrQuestionIndex] = useState<number>(0);
  const [accordionValue, setAccordionValue] = useState<string>();

  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation("help-card");

  const questions = useMemo(
    () => HELP_QUESTIONS[resolvedLanguage as "fr" | "en"],
    [resolvedLanguage],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrQuestionIndex((prev) => (prev + 1) % questions.length);
    }, HELP_QUESTIONS_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  function handleQuestionClick(index: number) {
    setOpen(true);
    setAccordionValue(index.toString());
  }

  return (
    <div
      className={cn(
        "absolute bottom-2 right-3 lg:bottom-5 xl:bottom-8",
        "rounded-md bg-white/95",
        "max-h-[75dvh] overflow-auto lg:w-[275px]",
        "text-slate-800",
        { "w-[38px] rounded-full": !open },
        { "w-[250px]": open },
        className,
      )}
      {...props}
    >
      {open ? (
        <div
          onClick={() => setOpen(false)}
          className={cn(
            "sticky top-0 z-20",
            "grid grid-cols-[auto_20px] items-center gap-2",
            "border-b bg-white p-2",
            "text-sm font-semibold leading-4",
            "group cursor-pointer underline-offset-2 hover:underline",
          )}
        >
          {t("close-btn")}
          <X className="group-hover:opacity-75" size={18} />
        </div>
      ) : null}

      <div className={cn("relative overflow-hidden", { "h-[40px]": !open })}>
        {/* questions interval */}
        <div
          className={cn(
            "grid grid-cols-1 items-center",
            "px-2 lg:duration-500",
            {
              hidden: open,
            },
          )}
          style={{
            gridTemplateRows: `repeat(${questions.length}, 40px)`,
            transform: open ? "0" : `translateY(-${currQuestionIndex * 40}px)`,
          }}
        >
          {questions.map((q, i) => (
            <div
              key={`question-interval-${i}`}
              className={cn(
                "grid grid-cols-[20px_auto] items-center gap-2",
                "text-sm leading-[0.9rem]",
                "cursor-pointer underline-offset-2 hover:underline",
              )}
              onClick={() => handleQuestionClick(i)}
            >
              <CircleHelp size={20} />
              <span className="line-clamp-2">{q.question}</span>
            </div>
          ))}
        </div>

        <Accordion
          type="single"
          collapsible
          value={accordionValue}
          onValueChange={(value) => setAccordionValue(value)}
        >
          {questions.map((q, i) => (
            <AccordionItem
              key={i}
              value={i.toString()}
              className="px-2 leading-4 last:border-none odd:bg-slate-100 data-[state=open]:bg-slate-200"
            >
              <AccordionTrigger className="py-2 text-left text-sm leading-4 data-[state=open]:font-semibold">
                {q.question}
              </AccordionTrigger>

              <AccordionContent className="pb-2 text-sm leading-4">
                {q.answer}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
}
