import { Model } from "@/types/model.ts";
import AdminSectionDivider from "@admin/components/layout/admin-section-divider.tsx";
import ModelListFilters, {
  ModelFilters,
} from "@admin/components/models/model-list-filters.tsx";
import ModelList from "@admin/components/models/model-list.tsx";
import NewModelLink from "@admin/components/models/new-model-link.tsx";
import { useAdminContext } from "@admin/hooks/use-admin-context.ts";
import { api } from "@lib/api.ts";
import { filterModels } from "@lib/models.ts";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function AdminModelsRoute() {
  const [activeFilters, setActiveFilters] = useState<ModelFilters>({
    searchText: "",
    brand: undefined,
  });

  const {
    state: { models },
    dispatch,
  } = useAdminContext();

  const [filteredModels, setFilteredModels] = useState(models);

  const brands: string[] = useMemo(
    () =>
      Array.from(
        new Set(
          models
            .map((model) => model.brand)
            .filter((brand) => !!brand) as string[],
        ),
      ),
    [models],
  );

  // group the models by brand
  const modelsByBrand = useMemo(() => {
    const modelsByBrand: { [brand: string]: Model[] } = {};
    filteredModels.forEach((model) => {
      const brand = model.brand || "Autres";
      if (!Object.prototype.hasOwnProperty.call(modelsByBrand, brand)) {
        modelsByBrand[brand] = [];
      }
      modelsByBrand[brand] = [...modelsByBrand[brand], model];
    });
    return modelsByBrand;
  }, [filteredModels]);

  const navigate = useNavigate();

  function handleFilter(filters: ModelFilters) {
    setActiveFilters(filters);

    if (!filters.searchText && !filters.brand) {
      setFilteredModels(models);
    } else {
      setFilteredModels(filterModels(models, filters));
    }
  }

  function handleModelClick(model: Model) {
    navigate(`/customize?model=${model.id}`);
  }

  async function removeModel(id: string) {
    await api.deleteModel(id);
    dispatch({ type: "deleteModel", payload: id });
  }

  return (
    <div>
      <div className="mb-2 flex items-center justify-between">
        <div className="text-xl font-black uppercase text-black">Modèles</div>
        <NewModelLink />
      </div>

      <ModelListFilters
        className="mb-4"
        activeFilters={activeFilters}
        onFilter={handleFilter}
        brandOptions={brands}
      />

      {/* model list, group by brand */}
      <div className="flex flex-col gap-8">
        {Object.entries(modelsByBrand)
          .sort(([brandA], [brandB]) =>
            brandA.toLowerCase() < brandB.toLowerCase() ? -1 : 1,
          )
          .map(([brand, models]) => (
            <div key={brand}>
              <AdminSectionDivider>{brand}</AdminSectionDivider>
              <ModelList
                models={models}
                onModelClick={handleModelClick}
                onModelDelete={removeModel}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
