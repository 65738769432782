import { useAdminContext } from "@admin/hooks/use-admin-context.ts";
import React from "react";

type NotAdminGuardProps = {
  children: React.ReactNode;
};

/**
 * Prevent children from rendering if the user is an admin
 */
export default function NotAdminGuard({ children }: NotAdminGuardProps) {
  const {
    state: { isLogged },
  } = useAdminContext();

  return <>{!isLogged ? children : null}</>;
}
