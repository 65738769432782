import { cn } from "@lib/utils.ts";
import { NavLink, NavLinkProps } from "react-router-dom";

type AdminMenuLinkProps = NavLinkProps;

export default function AdminMenuLink({
  className,
  children,

  ...props
}: AdminMenuLinkProps) {
  return (
    <NavLink
      className={({ isActive }) =>
        cn(
          "px-2 py-1",
          "text-xl uppercase",
          "rounded",
          "cursor-pointer hover:bg-[#444]",
          { "bg-[#555]": isActive },
          className,
        )
      }
      {...props}
    >
      {children}
    </NavLink>
  );
}
