import {
  CUSTOM_MODEL_EDITOR_PANZOOM_OPTIONS,
  defaultNotices,
} from "@/config.ts";
import { CustomElementUpdate } from "@/types/custom-element.ts";
import { CustomModel, CustomModelUpdate } from "@/types/custom-model.ts";
import MxlabLoader from "@components/ui/mxlab-loader.tsx";
import {
  applyCustomizationToSvg,
  getColorLayersFromSvg,
} from "@custom-model-editor/lib/custom-model.ts";
import { makeSvgInteractive } from "@custom-model-editor/lib/svg-event.ts";
import { normalizeStr } from "@lib/utils.ts";
import { G, SVG, Svg } from "@svgdotjs/svg.js";
import "@svgdotjs/svg.panzoom.js";
import React, { ComponentType, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG, Props as ReactSVGProps } from "react-svg";

type CustomModelSvgInjectorProps = {
  customModel: CustomModel;
  interactive?: boolean;
  onCustomModelUpdate?: (updates: CustomModelUpdate) => void;
  onElementSelect?: (elementId: string | undefined) => void;
  onElementUpdate?: (elementId: string, updates: CustomElementUpdate) => void;
  onElementRemove?: (elementId: string) => void;
  Loader?: ComponentType;
  afterInit?: () => void;
};

// Memoized the SVG so it doesn't re-render on every state change
// (we want to keep the svg padding and zooming)
const ReactSVGMemo = React.memo(ReactSVG);

export default function CustomModelSvgInjector({
  customModel,
  interactive = true,
  onCustomModelUpdate,
  onElementSelect,
  onElementUpdate,
  onElementRemove,
  Loader,
  afterInit,
}: CustomModelSvgInjectorProps) {
  const [loading, setLoading] = useState<boolean>(true);

  const { t } = useTranslation("custom-model-svg-injector");

  async function initSvg(_customModel: CustomModel) {
    const svg = SVG(`#${_customModel.id}`) as Svg;

    const shadows = SVG(`#${_customModel.id} #shadows`) as G;
    // we want to click through the shadows
    shadows.css("pointer-events" as CSSStyleName, "none");
    // create the groups for the custom elements and patterns, then put them after the shadows layer
    svg
      .group()
      .id("patterns")
      .after(shadows)
      .css("pointer-events" as CSSStyleName, "none");
    svg.group().id("custom-numbers").after(shadows);
    svg.group().id("logos").after(shadows);
    svg.group().id("custom-texts").after(shadows);

    const {
      model: { modelConfig },
    } = _customModel;

    if (!modelConfig.logos) {
      SVG(`#${_customModel.id} #logos`)
        .css("opacity", "0")
        .css("pointer-events" as CSSStyleName, "none");
    }
    if (!modelConfig.customNumbers) {
      SVG(`#${_customModel.id} #custom-numbers`)
        .css("opacity", "0")
        .css("pointer-events" as CSSStyleName, "none");
    }
    if (!modelConfig.customTexts) {
      SVG(`#${_customModel.id} #custom-texts`)
        .css("opacity", "0")
        .css("pointer-events" as CSSStyleName, "none");
    }

    if (interactive) {
      makeSvgInteractive(
        _customModel.id,
        (element) => onElementSelect?.(element?.id()),
        (element, updates) => onElementUpdate?.(element.id(), updates),
        (element) => onElementRemove?.(element.id()),
      );
      // Set up pan and zoom on the whole svg
      svg.panZoom(CUSTOM_MODEL_EDITOR_PANZOOM_OPTIONS);
    }

    // if the color layers are already set, apply the customization to the SVG
    if (_customModel.colorLayers.length) {
      applyCustomizationToSvg(_customModel);
    }
    // if the model has a default customization, apply it
    else if (_customModel.model.defaultCustomization) {
      const { elements } = JSON.parse(
        JSON.stringify(_customModel.model.defaultCustomization),
      );
      const colorLayers = JSON.parse(
        JSON.stringify(
          _customModel.model.defaultCustomization.colorLayers.map((cl) => {
            return {
              ...cl,
              localizedNotice: defaultNotices[normalizeStr(cl.id)],
            };
          }),
        ),
      );

      _customModel.colorLayers = colorLayers;
      _customModel.elements = elements;
      applyCustomizationToSvg(_customModel);
      onCustomModelUpdate?.({
        colorLayers: [...colorLayers],
        elements: [...elements],
      });
    } else {
      // otherwise, extract the color layers from the SVG itself
      const colorLayers = getColorLayersFromSvg(svg, customModel.model);
      onCustomModelUpdate?.({ colorLayers });
    }

    // little trick to ensure that the fonts are ready for the custom texts/numbers
    await document.fonts.ready;
    setTimeout(() => {
      setLoading(false);
      applyCustomizationToSvg(customModel);
      svg.attr("opacity", "1");
      afterInit?.();
    }, 500);
  }

  const memoizedInitSvg = useCallback(initSvg, []);

  const memoValue: Omit<ReactSVGProps, "ref"> = useMemo(
    () => ({
      src: customModel.model.svgSrc,
      className: "svg-wrapper",
      wrapper: "div",
      beforeInjection: (svg) => {
        setLoading(true);
        svg.setAttribute("id", customModel.id);
        svg.setAttribute("width", "100%");
        svg.setAttribute("height", "100%");
        // we hide the svg until everything is loaded for a better UX
        svg.setAttribute("opacity", "0");
      },
      afterInjection: () => {
        memoizedInitSvg(customModel);
      },
      onError: (error) => {
        // TODO Handle error
        console.log("Error loading SVG");
        console.error(error);
      },
    }),
    [customModel.model, memoizedInitSvg],
  );

  return (
    <>
      {loading ? (
        // custom loader as prop
        Loader ? (
          <Loader />
        ) : (
          // default loader
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <MxlabLoader
              color="black"
              text={t("apply-customization-loading-msg")}
            />
          </div>
        )
      ) : null}
      <ReactSVGMemo {...memoValue} />
    </>
  );
}
