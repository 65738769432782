import { CustomModelEditorMode } from "@/types/custom-model-editor.ts";
import { CustomModel } from "@/types/custom-model.ts";
import { customModelEditorReducer } from "@custom-model-editor/custom-model-editor.reducer.ts";
import { buildStepBar } from "@custom-model-editor/lib/custom-model-editor.ts";
import { ReactNode, useReducer } from "react";
import { CustomModelEditorContext } from "./custom-model-editor-context";

type CustomModelEditorProviderProps = {
  customModel: CustomModel;
  mode?: CustomModelEditorMode;
  children: ReactNode;
};

export function CustomModelEditorProvider({
  customModel,
  mode = "edit",
  children,
}: CustomModelEditorProviderProps) {
  const stepBarItems = buildStepBar(customModel);

  const [state, dispatch] = useReducer(customModelEditorReducer, {
    customModel,
    elementSelected: undefined,
    colorElementSelected: undefined,
    importedLogos: [],
    noticeToShow: undefined,
    stepBarItems,
    currentStepBarItem:
      customModel.model.modelConfig.notice === false
        ? stepBarItems[0]
        : undefined,
    mode,
  });

  return (
    <CustomModelEditorContext.Provider value={{ state, dispatch }}>
      {children}
    </CustomModelEditorContext.Provider>
  );
}
