import { Model } from "@/types/model.ts";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@components/ui/alert-dialog.tsx";
import { Button } from "@components/ui/button.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu.tsx";
import { useToast } from "@components/ui/use-toast.ts";
import { cn } from "@lib/utils.ts";
import { useState } from "react";
import { LuMoreVertical, LuTrash } from "react-icons/lu";

type ModelCardDropdownActionsProps = {
  className?: string;
  id: string;
  model: Model;
  isCustomModel?: boolean;
  onDelete: (id: string) => Promise<void>;
};

export default function ModelCardDropdownActions({
  className,
  id,
  model,
  isCustomModel,
  onDelete,
}: ModelCardDropdownActionsProps) {
  const [openDropdownMenu, setOpenDropdownMenu] = useState<boolean>(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { toast } = useToast();

  async function handleDelete() {
    if (loading) return;

    setLoading(true);

    try {
      await onDelete(id);
      toast({
        title: "Modèle supprimé",
        description: `Le modèle ${model.name} a été supprimé`,
      });
    } catch (err) {
      console.log("Error while deleting model");
      console.error(err);
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors de la suppression du modèle",
        variant: "destructive",
      });
    } finally {
      setOpenDropdownMenu(false);
      setOpenConfirmDeleteDialog(false);
      setLoading(false);
    }
  }

  return (
    <>
      <DropdownMenu
        open={openDropdownMenu}
        onOpenChange={(open) => setOpenDropdownMenu(open)}
      >
        <DropdownMenuTrigger
          className={cn(
            "rounded-full p-1",
            "hover:bg-slate-200/20",
            "duration-100 radix-state-open:rotate-90",
            className,
          )}
        >
          <LuMoreVertical className={cn("h-5 w-5 fill-slate-200")} />
        </DropdownMenuTrigger>

        <DropdownMenuContent>
          <DropdownMenuLabel className="text-base">
            {model.name}
          </DropdownMenuLabel>
          {/* TODO: copy on click */}
          <DropdownMenuLabel className="font-normal">
            id: <span className="italic">{id}</span>
          </DropdownMenuLabel>

          <DropdownMenuSeparator />

          {model.createdAt ? (
            <DropdownMenuLabel className="flex justify-between gap-2">
              <span className="font-normal">Création: </span>
              <span className="font-semibold">
                {new Date(model.createdAt).toLocaleDateString("fr", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </DropdownMenuLabel>
          ) : null}
          {!isCustomModel && model.updatedAt ? (
            <DropdownMenuLabel className="flex justify-between gap-2">
              <span className="font-normal">Dernière modif: </span>
              <span className="font-semibold">
                {new Date(model.updatedAt).toLocaleDateString("fr", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </DropdownMenuLabel>
          ) : null}

          <DropdownMenuSeparator />

          {/* TODO: shopify link if model */}

          <DropdownMenuItem
            className="text-error focus:text-error"
            onClick={() => setOpenConfirmDeleteDialog(true)}
            onSelect={(e) => e.preventDefault()}
          >
            <LuTrash className="mr-2" />
            <span className="translate-y-[1px]">Supprimer</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      {/* confirm delete model dialog */}
      <AlertDialog open={openConfirmDeleteDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Supprimer {model.name} ?</AlertDialogTitle>
            <AlertDialogDescription>
              Cette action ne peut pas être annulée. Cela supprimera le modèle
              de manière permanente.
            </AlertDialogDescription>
          </AlertDialogHeader>

          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => setOpenConfirmDeleteDialog(false)}
            >
              Annuler
            </AlertDialogCancel>

            <AlertDialogAction asChild>
              <Button variant="destructive" size="sm" onClick={handleDelete}>
                Supprimer
              </Button>
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
