import { NO_COLOR } from "@/config.ts";
import { Color, ColorMode, Pattern } from "@/types/color-elements.ts";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@components/ui/tooltip.tsx";
import ColorSquare from "@custom-model-editor/components/color-elements/color-square.tsx";
import { cn } from "@lib/utils.ts";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import React, { useEffect, useRef, useState } from "react";
import { FaAngleDown } from "react-icons/fa6";

type AccordionProps = Omit<
  React.ComponentPropsWithoutRef<"div">,
  "onChange"
> & {
  title?: string;
  expanded?: boolean;
  onChange?: (expanded: boolean) => void;

  activeColor?: Color;
  activePattern?: Pattern;
  colorMode?: ColorMode;

  colorList?: Color[];
  onColorClick: (color: Color) => void;
  onColorOver?: (color: Color) => void;
  onColorOut?: () => void;

  patternList?: Pattern[];
  onPatternClick?: (pattern: Pattern | undefined) => void;
  onPatternOver?: (pattern: Pattern) => void;
  onPatternOut?: () => void;
};

export default function ColorAccordion({
  className,
  title = "Color Accordion",
  expanded: expandedProp = false,
  onChange,
  activeColor,
  activePattern,
  colorMode = "flat",
  colorList = [],
  patternList = [],
  onColorClick,
  onColorOver,
  onColorOut,
  onPatternClick,
  onPatternOver,
  onPatternOut,
  ...props
}: AccordionProps) {
  const [expanded, setExpanded] = useState<boolean>(false);

  // used for auto scroll
  const accordionRef = useRef<HTMLDivElement>(null);
  // used for accordion behavior
  const expandableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setExpanded(expandedProp);
  }, [expandedProp]);

  // scroll to the color accordion when it's expanded
  useEffect(() => {
    const scrollToColorAccordion = () => {
      if (expanded && accordionRef.current) {
        accordionRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    };

    // scrollToColorAccordion();
    // wait a little to the open animation to play, then scroll again
    setTimeout(() => {
      scrollToColorAccordion();
    }, 200);
  }, [expanded]);

  return (
    <div ref={accordionRef} className={cn("w-full", className)} {...props}>
      <div
        className={cn(
          "flex items-center gap-3",
          "rounded-lg border border-transparent p-2 xl:py-3",
          "cursor-pointer hover:bg-[#4a4a4a]",
          { "border-[#727272] bg-[#4a4a4a]": expanded },
        )}
        onClick={() =>
          onChange
            ? onChange(!expanded)
            : setExpanded((prevState) => !prevState)
        }
      >
        {/* accordion current active color */}
        <ColorSquare
          color={activeColor ?? NO_COLOR}
          colorMode={colorMode}
          pattern={activePattern}
          size="sm"
        />

        {/* accordion title */}
        <div className="line-clamp-2 text-sm xl:line-clamp-1 xl:text-base">
          {title}
        </div>

        {/* accordion dropdown icon */}
        <FaAngleDown
          className={cn("ml-auto h-4 w-4 duration-200", {
            "-rotate-180": expanded,
          })}
        />
      </div>

      {/* expandable panel with color picker */}
      <div
        ref={expandableRef}
        className={cn("overflow-hidden duration-200")}
        style={{
          maxHeight: expanded ? expandableRef.current?.scrollHeight : 0,
        }}
      >
        {/* colors */}
        <div className="grid grid-cols-4 gap-y-3 pb-2 pt-4 xl:grid-cols-5">
          {colorList.map((color) => (
            <TooltipProvider key={`${title}-${color.name}`}>
              <Tooltip>
                <TooltipTrigger className="justify-self-center">
                  <ColorSquare
                    checked={color.color === activeColor?.color}
                    color={color}
                    colorMode={colorMode}
                    size="lg"
                    onClick={() => onColorClick(color)}
                    onMouseOver={() => onColorOver?.(color)}
                    onMouseOut={onColorOut}
                  />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent sideOffset={10}>
                    <p>{color.name}</p>
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            </TooltipProvider>
          ))}
        </div>

        {/* patterns */}
        {patternList?.length ? (
          <>
            <hr className="mx-4 my-4 rounded-lg border-2" />
            <div className="mt-3 grid grid-cols-4 gap-3 px-1 pb-2">
              {/* no pattern */}
              <ColorSquare
                className="col-span-4"
                color={NO_COLOR}
                size="xl"
                onClick={() => onPatternClick?.(undefined)}
              />
              {patternList
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((pattern) => (
                  <TooltipProvider key={`${title}-${pattern.name}`}>
                    <Tooltip>
                      <TooltipTrigger>
                        <ColorSquare
                          color={activeColor ?? NO_COLOR}
                          colorMode={colorMode}
                          pattern={pattern}
                          checked={pattern.url === activePattern?.url}
                          size="xl"
                          onClick={() => onPatternClick?.(pattern)}
                          onMouseOver={() => onPatternOver?.(pattern)}
                          onMouseOut={onPatternOut}
                        />
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent sideOffset={10}>
                          <p>{pattern.name}</p>
                        </TooltipContent>
                      </TooltipPortal>
                    </Tooltip>
                  </TooltipProvider>
                ))}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
