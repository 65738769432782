import Sidebar from "@components/layout/sidebar.tsx";
import AskLandscape from "@custom-model-editor/components/ask-landscape.tsx";
import CardError from "@custom-model-editor/components/card-error.tsx";
import * as Sentry from "@sentry/browser";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useRouteError } from "react-router-dom";

export default function CustomizationErrorRoute() {
  const { t } = useTranslation("customization-error-route");

  const error = useRouteError();

  useEffect(() => {
    console.error(error);
    Sentry.withScope((scope) => {
      scope.setExtra("component", "CustomizationErrorRoute");
      Sentry.captureException(error);
    });
  }, [error]);

  return (
    <AskLandscape>
      <Sidebar>
        <div className="p-2">{t("title")}</div>
      </Sidebar>
      <div className="grid place-items-center">
        <CardError>
          <Trans ns="customization-error-route" i18nKey="description">
            Une erreur inattendue s'est produite.
            <br />
            Veuillez réessayez plus tard ou essayez de rafraichir la page.
          </Trans>
        </CardError>
      </div>
    </AskLandscape>
  );
}
