import { useCustomModelEditorContext } from "@custom-model-editor/hooks/use-custom-model-editor-context.ts";
import { useEffect, useState } from "react";

/**
 * Hook to manage the expanded list of color elements in the custom model editor
 * Expands the accordion of the selected color element
 * Closes other accordions when a new color element is selected
 * Allow to open multiple accordion at a time manually
 */
export const useColorElementExpandedList = () => {
  const [expandedList, setExpandedList] = useState<string[]>([]);

  const {
    state: { colorElementSelected },
  } = useCustomModelEditorContext();

  /**
   * When a new color element is selected, expand its accordion and close others
   */
  useEffect(() => {
    setExpandedList((prevState) =>
      colorElementSelected ? [colorElementSelected.id] : prevState,
    );
  }, [colorElementSelected]);

  /**
   * Allow to open multiple accordion at a time manually
   */
  function handleColorAccordionChange(
    colorElementId: string,
    expanded: boolean,
  ) {
    if (expanded) {
      setExpandedList((prevState) => [...prevState, colorElementId]);
    } else {
      setExpandedList((prevState) =>
        prevState.filter((ceId) => ceId !== colorElementId),
      );
    }
  }

  return { expandedList, handleColorAccordionChange };
};
