import { PageInfo } from "@/types/api.ts";
import { Model } from "@/types/model.ts";
import { ShopifyProduct } from "@/types/shopify.ts";
import { ShopifyProductFilters } from "@admin/components/shopify/shopify-product-link-table-filters.tsx";
import { api } from "@lib/api.ts";
import { useEffect, useState } from "react";

export function useShopifyProducts() {
  const [products, setProducts] = useState<ShopifyProduct[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<ShopifyProductFilters>({
    query: "",
  });
  const [currPageInfo, setCurrPageInfo] = useState<PageInfo>();

  useEffect(() => {
    setLoading(true);
    api
      .getShopifyProducts(undefined, filters.query)
      .then((data) => {
        setProducts(data.products.nodes);
        setCurrPageInfo(data.products.pageInfo);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filters]);

  async function fetchNextPage() {
    if (!currPageInfo?.hasNextPage) return;
    if (loading) return;

    const data = await api.getShopifyProducts(currPageInfo.endCursor);
    setProducts((prev) => [...prev, ...data.products.nodes]);
    setCurrPageInfo(data.products.pageInfo);
  }

  async function linkProductToModel(
    product: ShopifyProduct,
    model?: Model,
  ): Promise<{ product: ShopifyProduct; model: Model }> {
    try {
      const { product: updatedProduct, model: updatedModel } =
        await api.linkShopifyProductToCustomLabModel(product, model?.id);

      setProducts((prev) =>
        prev.map((p) => (p.id === updatedProduct.id ? updatedProduct : p)),
      );

      return { product: updatedProduct, model: updatedModel };
    } catch (err) {
      console.log("Error while linking product");
      console.error(err);
      throw err;
    }
  }

  return {
    products,
    loading,
    filters,
    setFilters,
    fetchNextPage,
    hasNextPage: currPageInfo?.hasNextPage,
    linkProductToModel,
  };
}
