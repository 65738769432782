import { useAdminContext } from "@admin/hooks/use-admin-context.ts";
import { cn } from "@lib/utils.ts";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

type MainProps = React.ComponentPropsWithoutRef<"div">;

export default function Main({ className, children, ...props }: MainProps) {
  const {
    state: { loading },
  } = useAdminContext();

  const location = useLocation();

  const isSidebarVisible = useMemo(
    () => !(location.pathname === "/admin/login" || loading),
    [location.pathname, loading],
  );

  return (
    <>
      {/* padding left: sidebar width + sidebar left */}
      <main
        className={cn(
          "overflow-x-auto",
          {
            "pl-[calc(200px+1.5rem)] lg:pl-[calc(237px+1.5rem)] xl:pl-[calc(300px+1.5rem)]":
              isSidebarVisible,
          },
          className,
        )}
        {...props}
      >
        <div
          className={cn(
            "mxlab-logo-background min-w-screen grid min-h-[100dvh]",
            {
              "!bg-[center_center]": !isSidebarVisible,
            },
          )}
        >
          {children}
        </div>
      </main>
    </>
  );
}
