import { CustomModel } from "@/types/custom-model.ts";
import { Button } from "@components/ui/button.tsx";
import CustomModelSvgInjector from "@custom-model-editor/components/custom-model-svg-injector.tsx";
import { cn } from "@lib/utils.ts";
import { X } from "lucide-react";
import React from "react";

type CustomModelPreviewProps = React.ComponentPropsWithoutRef<"div"> & {
  customModel: CustomModel;
  onClose?: () => void;
  onLoad?: () => void;
};

export default function CustomModelPreview({
  className,
  customModel,
  onClose,
  onLoad,
  ...props
}: CustomModelPreviewProps) {
  return (
    <div
      className={cn(
        "fixed bottom-0 left-0 right-0 top-0",
        "bg-[radial-gradient(#fff,#adada8)]",
        "z-40",
        className,
      )}
      {...props}
    >
      <div className={cn("mxlab-logo-background h-full w-full !bg-center")}>
        <CustomModelSvgInjector
          customModel={{
            ...customModel,
            id: `${customModel.id}-preview`,
          }}
          interactive={false}
          afterInit={onLoad}
        />

        {onClose ? (
          <Button
            className={cn(
              "fixed right-2 top-2",
              "bg-transparent hover:bg-transparent",
              "text-slate-950 hover:opacity-50",
            )}
            onClick={onClose}
          >
            <X size={32} />
          </Button>
        ) : null}
      </div>
    </div>
  );
}
