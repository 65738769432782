import { cn } from "@lib/utils.ts";
import React from "react";

type AdminSectionDividerProps = React.ComponentPropsWithoutRef<"div"> & {
  borderClassName?: string;
  textClassName?: string;
};

export default function AdminSectionDivider({
  className,
  children,
  textClassName,
  borderClassName,
  ...props
}: AdminSectionDividerProps) {
  return (
    <div
      className={cn(
        "mb-3 grid grid-cols-[auto_min-content_auto] items-center",
        className,
      )}
      {...props}
    >
      {/* border left */}
      <div
        className={cn(
          "mx-8 h-0 rounded-full border border-slate-700/75",
          borderClassName,
        )}
      />

      {/* children */}
      <div
        className={cn(
          "whitespace-nowrap text-lg font-semibold text-slate-700",
          textClassName,
        )}
      >
        {children}
      </div>

      {/* border right */}
      <div
        className={cn(
          "mx-8 h-0 rounded-full border border-slate-700/75",
          borderClassName,
        )}
      />
    </div>
  );
}
