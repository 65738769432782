import { cn } from "@/lib/utils";
import { useCustomModelEditorContext } from "@custom-model-editor/hooks/use-custom-model-editor-context.ts";
import { Eye, Pen } from "lucide-react";
import React from "react";

type CustomModelEditorModelSwitchProps = React.ComponentPropsWithoutRef<"div">;

export default function CustomModelEditorModeSwitch({
  className,
  ...props
}: CustomModelEditorModelSwitchProps) {
  const {
    state: { mode },
    dispatch,
  } = useCustomModelEditorContext();

  function handleSwitch() {
    dispatch({ type: "switch_mode" });
  }

  return (
    <div
      className={cn(
        "flex items-center gap-2 rounded",
        "cursor-pointer hover:bg-white/20",
        className,
      )}
      onClick={handleSwitch}
      {...props}
    >
      {mode === "read-only" ? (
        <>
          <Eye />
          Read-only
        </>
      ) : mode === "edit" ? (
        <>
          <Pen className="h-5 w-5" />
          Edition
        </>
      ) : null}
    </div>
  );
}
