import CustomModelListFilters, {
  CustomModelFilters,
} from "@/_admin/components/custom-models/custom-model-list-filters";
import { CustomModel } from "@/types/custom-model.ts";
import CustomModelList from "@admin/components/custom-models/custom-model-list.tsx";
import AdminSectionDivider from "@admin/components/layout/admin-section-divider.tsx";
import { useAdminContext } from "@admin/hooks/use-admin-context.ts";
import useConfirm from "@hooks/useConfirm.tsx";
import { api } from "@lib/api.ts";
import {
  addDays,
  compareDesc,
  isAfter,
  isBefore,
  isSameDay,
  subDays,
} from "date-fns";
import { useMemo, useState } from "react";
import { toast } from "sonner";

export default function AdminCustomModelsRoute() {
  const [activeFilters, setActiveFilters] = useState<CustomModelFilters>({
    searchText: "",
    dateRange: undefined,
  });

  const [ConfirmDeleteDialog, confirmDelete] = useConfirm(
    "Supprimer la configuration ?",
    "La configuration sera supprimée, cette action est irréversible.",
  );

  const {
    state: { customModels },
    dispatch,
  } = useAdminContext();

  const [filteredCustomModels, setFilteredCustomModels] =
    useState<CustomModel[]>(customModels);

  const handleFilter = (filters: CustomModelFilters) => {
    setActiveFilters(filters);

    const { searchText, dateRange } = filters;
    let filtered = customModels;

    if (dateRange) {
      filtered = customModels.filter((cm) => {
        if (!cm.createdAt) return false;

        // one date selected
        if (dateRange.from && !dateRange.to) {
          return isSameDay(cm.createdAt, dateRange.from);
        }
        // two dates selected
        else if (dateRange.from && dateRange.to) {
          const to = addDays(dateRange.to, 1);
          const from = subDays(dateRange.from, 1);

          return isBefore(cm.createdAt, to) && isAfter(cm.createdAt, from);
        }
      });
    }

    // filter by name and brand
    filtered = filtered.filter(
      (cm) =>
        cm.model.name.toLowerCase().includes(searchText) ||
        cm.model.brand?.toLowerCase().includes(searchText),
    );

    setFilteredCustomModels(filtered);
  };

  // group the custom models by creation date
  const customModelsByDate = useMemo(() => {
    const customModelsByDate: { [date: string]: CustomModel[] } = {};
    filteredCustomModels.forEach((customModel) => {
      const date = customModel.updatedAt
        ? new Date(customModel.updatedAt).toDateString()
        : customModel.createdAt
          ? new Date(customModel.createdAt).toDateString()
          : "Pas de date";
      if (!Object.prototype.hasOwnProperty.call(customModelsByDate, date)) {
        customModelsByDate[date] = [];
      }
      customModelsByDate[date] = [...customModelsByDate[date], customModel];
    });
    return customModelsByDate;
  }, [filteredCustomModels]);

  async function handleDelete(id: string) {
    const confirm = await confirmDelete();

    if (confirm) {
      const promise = api.deleteCustomModel(id);

      toast.promise(promise, {
        loading: "Suppression en cours...",
        duration: 10000,
        success: () => {
          dispatch({ type: "deleteCustomModel", payload: id });
          setFilteredCustomModels(
            filteredCustomModels.filter((cm) => cm.id !== id),
          );
          return "Configuration supprimée";
        },
        error:
          "Erreur lors de la suppression. Veuillez réessayer plus tard ou contactez le développeur.",
      });
    }
  }

  return (
    <>
      <div className="text-xl font-black uppercase text-black">
        Customisations
      </div>
      <div className="mb-3 text-sm text-slate-950">
        Retouve ici les customisations des clients passées sur le site.
      </div>
      <CustomModelListFilters
        className="mb-3"
        activeFilters={activeFilters}
        onFilter={handleFilter}
      />
      <div className="flex flex-col gap-8">
        {Object.entries(customModelsByDate).length ? (
          Object.entries(customModelsByDate)
            .sort(([dateA], [dateB]) => {
              return compareDesc(dateA, dateB);
            })
            .map(([date, customModels]) => (
              <div key={date}>
                <AdminSectionDivider>
                  {new Date(date).toLocaleDateString("fr")}
                </AdminSectionDivider>
                <CustomModelList
                  customModels={customModels}
                  onDelete={handleDelete}
                />
              </div>
            ))
        ) : (
          <div className="mt-6 text-center text-slate-950">
            Aucune customisation trouvée
          </div>
        )}
      </div>
      <ConfirmDeleteDialog />;
    </>
  );
}
