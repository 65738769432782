import SearchInput from "@components/ui/search-input.tsx";
import { cn } from "@lib/utils.ts";
import React from "react";
import { useTranslation } from "react-i18next";

export type LogoFilters = {
  searchText: string;
  firstLetters?: string;
};

type LogoListFiltersProps = React.ComponentPropsWithoutRef<"div"> & {
  activeFilters: LogoFilters;
  onFilter: (filters: LogoFilters) => void;
  inputClassName?: string;
  lettersContainerClassName?: string;
  lettersItemClassName?: string;
};

const LOGO_LETTERS_FILTERS =
  "09abcdefghijklmnopqrstuvwxyz".match(/.{1,2}/g) ?? [];

export default function LogoListFilters({
  className,
  activeFilters,
  onFilter,
  inputClassName,
  lettersContainerClassName,
  lettersItemClassName,
  ...props
}: LogoListFiltersProps) {
  const { t } = useTranslation("logo-library");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFilter({ ...activeFilters, searchText: e.target.value });
  };

  const handleLettersClick = (letters: string) => {
    onFilter({
      ...activeFilters,
      firstLetters:
        letters === activeFilters.firstLetters ? undefined : letters,
    });
  };

  return (
    <div className={cn("", className)} {...props}>
      {/* search */}
      <SearchInput
        id="search-text-logos"
        className={cn("w-[250px] md:w-[300px] xl:w-[400px]", inputClassName)}
        value={activeFilters.searchText}
        onChange={handleSearch}
        type="text"
        placeholder={t("search-placeholder")}
      />

      {/* first letters filters */}
      <div
        className={cn(
          "mt-2 flex flex-wrap items-center gap-3",
          lettersContainerClassName,
        )}
      >
        {LOGO_LETTERS_FILTERS.map((letters) => (
          <div
            key={letters}
            className={cn(
              "px-1.5 lg:px-3 lg:py-0.5",
              "whitespace-nowrap uppercase",
              "rounded border-2 border-transparent bg-white text-slate-950",
              "cursor-pointer hover:bg-slate-200",
              {
                "border-primary bg-slate-200":
                  activeFilters.firstLetters === letters,
              },
              lettersItemClassName,
            )}
            onClick={() => handleLettersClick(letters)}
          >
            {letters[0]}-{letters[1]}
          </div>
        ))}
      </div>
    </div>
  );
}
