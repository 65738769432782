import {
  CUSTOM_TEXT_MIN_FONT_SIZE,
  CUSTOM_TEXT_SPACING_RATIO,
  CUSTOM_TEXT_STROKE_WIDTH_RATIO,
  SELECTION_BOX_RECT,
  SELECTION_BOX_REMOVE_CROSS,
  SELECTION_BOX_RESIZE_HANDLE,
  SELECTION_BOX_ROTATE_HANDLE,
} from "@/config.ts";
import {
  ColorElement,
  ColorLayer,
  ColorMode,
  Pattern,
} from "@/types/color-elements.ts";
import { CustomElement } from "@/types/custom-element.ts";
import { G, SVG, Svg } from "@svgdotjs/svg.js";
import tinycolor from "tinycolor2";

/**
 * Draw a color element
 * @param svgId
 * @param elementId The id of the svg element when we draw
 * @param colorElement The color element to draw
 * @param colorMode The way to color the element (e.g. gradient or not)
 */
export function setColorElementColor(
  svgId: string,
  elementId: string,
  colorElement: ColorElement,
  colorMode: ColorMode,
) {
  const element = SVG(`#${svgId} #${elementId}`);
  if (!element) return;

  SVG(`#${svgId}-${elementId}-pattern`)?.remove();
  SVG(`#${svgId}-${elementId}-clip`)?.remove();

  const { color, pattern } = colorElement;

  const gradient =
    colorMode === "chromium" ||
    (colorMode === "chromium-white" && color.name !== "WHITE");

  if (gradient && color.gradient) {
    const grad = (SVG(`#${svgId}`) as Svg).gradient("linear", (add) => {
      color.gradient?.forEach((stop) => {
        add.stop(stop.location / 100, stop.color);
      });
    });
    grad.from(0, 0).to(1, 0.7);

    element.fill(grad);
    element.children().forEach((child) => {
      child.fill(grad);
    });
  } else {
    element.fill(color.color);
    element.children().forEach((child) => {
      child.fill(color.color);
    });
  }

  if (pattern) {
    drawPattern(svgId, elementId, pattern);
  }
}

/**
 * Set the color of all color elements of a color layer
 * @param svgId
 * @param colorLayer
 */
export function setColorLayerColorElementsColor(
  svgId: string,
  colorLayer: ColorLayer,
) {
  if (colorLayer.level === "two-level") {
    colorLayer.colorGroups.forEach((colorGroup) => {
      colorGroup.colorElements.forEach((colorElement) => {
        setColorElementColor(
          svgId,
          colorElement.id,
          colorElement,
          colorLayer.activeColorMode,
        );
      });
    });
  } else if (colorLayer.level === "one-level") {
    colorLayer.colorElements.forEach((colorElement) => {
      setColorElementColor(
        svgId,
        colorElement.id,
        colorElement,
        colorLayer.activeColorMode,
      );
    });
  }
}

function drawPattern(svgId: string, elementId: string, pattern: Pattern) {
  const group = SVG(`#${svgId} #patterns`) as G;

  const element = SVG(`#${svgId} #${elementId}`);
  if (!element) return;

  SVG(`#${svgId}-${elementId}-pattern`)?.remove();
  SVG(`#${svgId}-${elementId}-clip`)?.remove();

  const patternGroup = group.group().id(`${svgId}-${elementId}-pattern`);
  const clip = group.clip().id(`${svgId}-${elementId}-clip`);
  element.children().forEach((child) => {
    clip.add(child.clone());
  });

  const { size, url } = pattern;

  const maskX = element.x() as number;
  const maskY = element.y() as number;
  const maskWidth = element.width() as number;
  const maskHeight = element.height() as number;

  let currentX = maskX;
  let currentY = maskY;

  while (currentY < maskY + maskHeight) {
    while (currentX < maskX + maskWidth) {
      patternGroup.image(url).move(currentX, currentY).size(size, size);
      currentX += size;
    }
    currentX = maskX;
    currentY += size;
  }

  patternGroup.clipWith(clip);
}

export function getSvgCenterPos(svgId: string): { x: number; y: number } {
  const svg = SVG(`#${svgId}`) as Svg;
  if (!svg) {
    return { x: 0, y: 0 };
  }

  return {
    x: svg.viewbox().x + svg.viewbox().width / 2,
    y: svg.viewbox().y + svg.viewbox().height / 2,
  };
}

export function drawLogoElement(
  svgId: string,
  logoElement: CustomElement,
): CustomElement | undefined {
  if (logoElement.type !== "logo") return;

  const layer = SVG(`#${svgId} #logos`) as G;
  if (!layer) return;

  const existing = SVG(`#${svgId} #${logoElement.id}`);
  const existingClasses = existing?.classes();
  if (existing) existing.remove();

  const { width, height, x, y, a, id } = logoElement;

  const image = layer
    .image(new URL(logoElement.logo.url, import.meta.url).href)
    .size(width, height)
    .transform({
      translate: [x, y],
      rotate: a,
    })
    .attr("element-type", "logo")
    .id(id);

  // we keep the position as custom attributes since we position the element with a svg transformation
  // position may differ from translate values and cause calculation errors
  // useful to drag / rotate / resize calculations
  image.attr("pos-x", logoElement.x).attr("pos-y", logoElement.y);
  existingClasses?.forEach((className) => image.addClass(className));

  if (image.hasClass("interactive")) {
    makeElementInteractive(svgId, image.id());
  }

  return {
    ...logoElement,
    width: image.bbox().width,
    height: image.bbox().height,
  };
}

export function drawCustomTextElement(
  svgId: string,
  textElement: CustomElement,
  gradient = false,
) {
  const { type } = textElement;
  if (type !== "custom-text" && type !== "custom-numbers") return;

  // get the layer of the text element according to its type
  const layer =
    type === "custom-text"
      ? (SVG(`#${svgId} #custom-texts`) as G)
      : (SVG(`#${svgId} #custom-numbers`) as G);
  if (!layer) return;

  const existing = SVG(`#${svgId} #${textElement.id}`);
  const existingClasses = existing?.classes();
  if (existing) existing.remove();

  const { id, x, y, a } = textElement;
  const { text, color, stroke, font, fontSize, spacing } =
    type === "custom-text" ? textElement.customText : textElement.customNumbers;

  const grad = gradient
    ? (SVG(`#${svgId}`) as Svg).gradient("linear", (add) => {
        color.gradient?.forEach((stop) => {
          add.stop(stop.location / 100, stop.color);
        });
      })
    : undefined;

  const strokeWidth =
    (stroke.width * fontSize) / CUSTOM_TEXT_STROKE_WIDTH_RATIO;
  // letter spacing
  const dx = Array(text.length)
    .fill((spacing * fontSize) / CUSTOM_TEXT_SPACING_RATIO)
    .join(" ");

  const textSvgElement = layer
    .plain(text)
    .font({
      family: font,
      size:
        fontSize < CUSTOM_TEXT_MIN_FONT_SIZE
          ? CUSTOM_TEXT_MIN_FONT_SIZE
          : fontSize,
      fill: grad ?? color.color,
    })
    .stroke({ width: strokeWidth, color: stroke.color })
    .css("dominant-baseline" as CSSStyleName, "hanging")
    .css("paint-order" as CSSStyleName, "stroke")
    .css("user-select" as CSSStyleName, "none")
    .css("-webkit-user-select" as CSSStyleName, "none")
    .attr("dx", dx)
    .attr("pos-x", x)
    .attr("pos-y", y)
    .attr("element-type", type)
    .id(id);

  textSvgElement.move(0, 0);
  textSvgElement.transform({ translate: [x, y], rotate: a });

  existingClasses?.forEach((className) => textSvgElement.addClass(className));

  if (textSvgElement.hasClass("interactive")) {
    makeElementInteractive(svgId, textSvgElement.id());
  }
}

export function removeElement(svgId: string, elementId: string) {
  const element = SVG(`#${svgId} #${elementId}`);
  if (!element) return;

  element.remove();
}

/**
 * An interactive element can be moved, resized, and rotated
 */
export function makeElementInteractive(svgId: string, elementId: string) {
  const svgElement = SVG(`#${svgId} #${elementId}`);
  if (!svgElement) return;

  svgElement.css("cursor", "pointer");
}

/**
 * A non-interactive element can't be moved, resized, or rotated
 */
export function makeElementNonInteractive(svgId: string, elementId: string) {
  const svgElement = SVG(`#${svgId} #${elementId}`);
  if (!svgElement) return;

  svgElement.css("cursor", "default");
}

/**
 * Draw a selection box around an element
 * The selection box is a group with a rect, a rotate handle and a resize handle
 */
export function drawSelectionBox(svgId: string, elementId: string) {
  const svg = SVG(`#${svgId}`) as Svg;
  if (!svg) return;

  // remove selection box if exists
  const existing = SVG(`#${svgId} #selection-box`);
  if (existing) existing.remove();

  const element = SVG(`#${svgId} #${elementId}`);
  if (!element) return;

  const elementType = element.attr("element-type");

  const selectionBox = svg
    .group()
    .id("selection-box")
    .attr("element-id", elementId);

  // Rect
  const boxWidth = element.bbox().width;
  const boxHeight = element.bbox().height;

  selectionBox
    .rect(boxWidth, boxHeight)
    .move(0, 0)
    .fill(SELECTION_BOX_RECT.fill)
    .stroke(SELECTION_BOX_RECT.stroke)
    .id("selection-box-rect")
    .front();

  // Rotate handle
  const rotateHandleSize = SELECTION_BOX_ROTATE_HANDLE.size;
  const rotateHandleX = -rotateHandleSize / 2;
  const rotateHandleY = -rotateHandleSize / 2;

  selectionBox
    .circle(rotateHandleSize)
    .move(rotateHandleX, rotateHandleY)
    .fill(SELECTION_BOX_ROTATE_HANDLE.fill)
    .css("cursor", SELECTION_BOX_ROTATE_HANDLE.cursor)
    .id("selection-box-rotate-handle")
    .front();

  if (elementType === "logo") {
    // Resize handle
    const resizeHandleSize = SELECTION_BOX_RESIZE_HANDLE.size;
    const resizeHandleX =
      boxWidth - resizeHandleSize + SELECTION_BOX_RECT.stroke.width / 2;
    const resizeHandleY =
      boxHeight - resizeHandleSize + SELECTION_BOX_RECT.stroke.width / 2;

    selectionBox
      .rect(resizeHandleSize, resizeHandleSize)
      .move(resizeHandleX, resizeHandleY)
      .fill(SELECTION_BOX_RESIZE_HANDLE.fill)
      .css("cursor", SELECTION_BOX_RESIZE_HANDLE.cursor)
      .id("selection-box-resize-handle")
      .front();
  }

  // Remove cross
  const removeCircleSize = SELECTION_BOX_REMOVE_CROSS.size;
  const removeCircleX = boxWidth - removeCircleSize / 2;
  const removeCircleY = -removeCircleSize / 2;
  const crossPadding = removeCircleSize / 4;

  const removeCross = selectionBox.group().css("cursor", "pointer");
  removeCross
    .circle(removeCircleSize)
    .move(removeCircleX, removeCircleY)
    .fill(SELECTION_BOX_REMOVE_CROSS.circleColor)
    .addClass("selection-box-remove-cross");
  removeCross
    .line(
      removeCircleX + crossPadding,
      removeCircleY + crossPadding,
      removeCircleX + removeCircleSize - crossPadding,
      removeCircleY + removeCircleSize - crossPadding,
    )
    .stroke({
      width: 2,
      color: SELECTION_BOX_REMOVE_CROSS.lineColors,
      linecap: "round",
    })
    .addClass("selection-box-remove-cross");
  removeCross
    .line(
      removeCircleX + removeCircleSize - crossPadding,
      removeCircleY + crossPadding,
      removeCircleX + crossPadding,
      removeCircleY + removeCircleSize - crossPadding,
    )
    .stroke({
      width: 2,
      color: SELECTION_BOX_REMOVE_CROSS.lineColors,
      linecap: "round",
    })
    .addClass("selection-box-remove-cross");

  removeCross.on("mouseenter", () => {
    removeCross.css("opacity", "0.75");
  });
  removeCross.on("mouseleave", () => {
    removeCross.css("opacity", "1");
  });

  selectionBox.transform(element.transform());
}

export function removeSelectionBox(svgId: string) {
  const selectionBox = SVG(`#${svgId} #selection-box`);
  if (selectionBox) selectionBox.remove();
}

export function addClassToElement(
  svgId: string,
  elementId: string,
  className: string,
) {
  const element = SVG(`#${svgId} #${elementId}`);
  if (!element) return;

  element.addClass(className);
}

export function removeClassFromElement(
  svgId: string,
  elementId: string,
  className: string,
) {
  const element = SVG(`#${svgId} #${elementId}`);
  if (!element) return;

  element.removeClass(className);
}

export function highlightElement(
  svgId: string,
  elementId: string,
  color: string,
) {
  const element = SVG(`#${svgId} #${elementId}`);
  if (!element) return;

  // we highlight the element with a darker or lighter color depending on the color brightness
  const darkenColor = tinycolor(color).darken(10).toString();
  const lightenColor = tinycolor(color).lighten(10).toString();
  const highlightColor = tinycolor(color).isDark() ? lightenColor : darkenColor;

  element.fill(highlightColor);
  element.children().forEach((child) => {
    child.fill(highlightColor);
  });
}

export function hideElement(svgId: string, elementId: string) {
  const element = SVG(`#${svgId} #${elementId}`);
  if (!element) return;
  element.hide();
}

export function showElement(svgId: string, elementId: string) {
  const element = SVG(`#${svgId} #${elementId}`);
  if (!element) return;
  element.show();
}

export function zoomIn(svgId: string) {
  const svg = SVG(`#${svgId}`) as Svg;
  if (!svg) return;

  svg.zoom(svg.zoom() + 0.1);
}

export function zoomOut(svgId: string) {
  const svg = SVG(`#${svgId}`) as Svg;
  if (!svg) return;

  svg.zoom(svg.zoom() - 0.1);
}

export function makeElementClickable(
  svgId: string,
  elementId: string,
  clickable = true,
) {
  const element = SVG(`#${svgId} #${elementId}`);
  if (!element) return;

  const cursor = clickable ? "pointer" : "default";
  const pointerEvents = clickable ? "auto" : "none";

  element.css("cursor", cursor);
  element.css("pointer-events" as CSSStyleName, pointerEvents);
}
