import CustomModelPreview from "@components/custom-models/custom-model-preview.tsx";
import { Button } from "@components/ui/button.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@components/ui/tooltip.tsx";
import ConfirmResetCustomizationAlertDialog from "@custom-model-editor/components/confirm-reset-customization-alert-dialog.tsx";
import { useCustomModelEditorContext } from "@custom-model-editor/hooks/use-custom-model-editor-context.ts";
import { zoomIn, zoomOut } from "@custom-model-editor/lib/svg.ts";
import { localStorageService } from "@lib/local-storage.ts";
import { cn } from "@lib/utils.ts";
import { CircleHelp, Expand, ZoomIn, ZoomOut } from "lucide-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { LuBan } from "react-icons/lu";

type ToolbarProps = React.ComponentPropsWithoutRef<"div">;

export default function Toolbar({ className, ...props }: ToolbarProps) {
  const [openConfirmResetDialog, setOpenConfirmResetDialog] =
    useState<boolean>(false);
  const [showCustomModelPreview, setShowCustomModelPreview] =
    useState<boolean>(false);

  const {
    state: { customModel, currentStepBarItem, noticeToShow },
    dispatch,
  } = useCustomModelEditorContext();

  const { t } = useTranslation("toolbar");

  function handleResetBtnClick() {
    setOpenConfirmResetDialog(true);
  }

  function resetCustomization() {
    localStorageService.resetCustomization(customModel.model.id);
    window.location.reload();
  }

  function handleHelpBtnClick() {
    dispatch({
      type: "set_notice_to_show",
      payload: currentStepBarItem?.localizedNotice,
    });
  }

  return (
    <>
      <div
        className={cn(
          "absolute left-3 top-0 lg:left-4 lg:top-3 xl:left-6 xl:top-6",
          "flex items-center gap-4",
          className,
        )}
        {...props}
      >
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                className="bg-transparent p-0 duration-200 hover:scale-110 hover:bg-[transparent]"
                onClick={() => setShowCustomModelPreview(true)}
              >
                <Expand />
              </Button>
            </TooltipTrigger>
            <TooltipContent>{t("maximize-tooltip")}</TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <Button
          className="bg-transparent p-0 duration-200 hover:scale-110 hover:bg-[transparent]"
          onClick={() => zoomIn(customModel.id)}
        >
          <ZoomIn className="h-6 w-6" />
        </Button>

        <Button
          className="bg-transparent p-0 duration-200 hover:scale-110 hover:bg-[transparent]"
          onClick={() => zoomOut(customModel.id)}
        >
          <ZoomOut className="h-6 w-6" />
        </Button>

        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                className="bg-transparent p-0 duration-200 hover:scale-110 hover:bg-[transparent]"
                onClick={handleResetBtnClick}
              >
                <LuBan className="h-[25px] w-[25px]" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>{t("reset-tooltip")}</TooltipContent>
          </Tooltip>
        </TooltipProvider>

        {currentStepBarItem?.localizedNotice ? (
          <Button
            className={cn(
              "bg-transparent p-0 hover:bg-[transparent] hover:text-accent",
              {
                "text-accent":
                  noticeToShow?.fr.id ===
                  currentStepBarItem?.localizedNotice.fr.id,
              },
            )}
            onClick={handleHelpBtnClick}
          >
            <CircleHelp className="h-[27px] w-[27px]" />
          </Button>
        ) : null}
      </div>

      {showCustomModelPreview ? (
        <CustomModelPreview
          customModel={customModel}
          onClose={() => setShowCustomModelPreview(false)}
        />
      ) : null}

      <ConfirmResetCustomizationAlertDialog
        open={openConfirmResetDialog}
        onOpenChange={setOpenConfirmResetDialog}
        onConfirm={resetCustomization}
      />
    </>
  );
}
