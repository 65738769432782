/* eslint @typescript-eslint/no-explicit-any: 0 */

import {
  CUSTOM_ELEMENTS_MIN_HEIGHT,
  CUSTOM_ELEMENTS_MIN_WIDTH,
  CUSTOM_MODEL_EDITOR_PANZOOM_OPTIONS,
} from "@/config.ts";
import { drawSelectionBox } from "@custom-model-editor/lib/svg.ts";
import { Element, SVG, Svg } from "@svgdotjs/svg.js";

/**
 * Prepare the SVG for interactive elements
 * Set svg pan and zoom
 * Create necessary svg groups such as logos, custom-text and custom-numbers
 * Set svg event listeners for dragging, rotating, and resizing elements
 * @param svgId The id of the SVG element
 * @param onElementSelect Callback function when an element is selected or unselected
 * @param onElementUpdate Callback function when an element is dragged, rotated, or resized
 * @param onElementRemove Callback function when an element is removed
 */
export function makeSvgInteractive(
  svgId: string,
  onElementSelect: (element: Element | undefined) => void,
  onElementUpdate: (
    element: Element,
    updates: Partial<{
      x: number;
      y: number;
      width: number;
      height: number;
      a: number;
    }>,
  ) => void,
  onElementRemove: (element: Element) => void,
) {
  const svg = SVG(`#${svgId}`) as Svg;
  if (!svg) return;

  svg.css("touch-action" as CSSStyleName, "none");

  svg.off("pointerdown");
  svg.off("pointermove");
  svg.off("pointerup");
  svg.off("pointercancel");

  svg.on("panStart", () => svg.css("cursor", "grabbing"));
  svg.on("panEnd", () => svg.css("cursor", "default"));

  let panning = true;

  let isDragging = false;
  let draggingEvent: any | undefined = undefined;
  let isRotating = false;
  let isResizing = false;
  let isRemoving = false;

  // delta is used to move the object from the mouse position on the said object when dragging/rotating/resizing
  let delta = { x: 0, y: 0 };

  svg.on("pointerdown", (e) => {
    const event = e as any;
    if (event.button !== 0) return;

    draggingEvent = undefined;
    isDragging = getIsDragging(svgId, event);
    isRotating = getIsRotating(event);
    isResizing = getIsResizing(event);
    isRemoving = getIsRemoving(event);

    const selectedElement = getSelectedElement(svgId);

    if (
      (isDragging && selectedElement) ||
      isRotating ||
      isResizing ||
      isRemoving
    ) {
      panning = false;
      svg.panZoom({ ...CUSTOM_MODEL_EDITOR_PANZOOM_OPTIONS, panning: false });
    }

    if (isRemoving) {
      if (!selectedElement) return;
      onElementRemove(selectedElement);
    } else if (isDragging) {
      draggingEvent = event;
      if (!selectedElement) return;

      if (selectedElement.id() !== event.target.id) {
        onElementSelect(undefined);
        return;
      }

      const mouse = getMousePos(svg, event);
      delta = getMouseDelta(selectedElement, mouse);
      onElementSelect(selectedElement);
    } else if (!isDragging && !isRotating && !isResizing && !isRemoving) {
      onElementSelect(undefined);
    }
  });

  svg.on("pointerup", (e) => {
    const event = e as any;
    if (event.button !== 0) return;

    const hasMoved =
      draggingEvent &&
      draggingEvent.clientX !== event.clientX &&
      draggingEvent.clientY !== event.clientY;

    if (
      isDragging &&
      draggingEvent.target.id === event.target.id &&
      !hasMoved
    ) {
      drawSelectionBox(svgId, event.target.id);
      onElementSelect(SVG(`#${svgId} #${event.target.id}`));
    }
  });

  svg.on("pointermove", (e) => {
    const event = e as any;
    const mousePos = getMousePos(svg, event);

    const selectedElement = getSelectedElement(svgId);
    if (!selectedElement) return;

    if (isDragging) {
      const x = mousePos.x + delta.x;
      const y = mousePos.y + delta.y;

      onElementUpdate(selectedElement, { x, y });
    } else if (isRotating) {
      svg.css("cursor", "alias");

      const a = getRotatingAngleFromMousePos(selectedElement, mousePos);

      onElementUpdate(selectedElement, { a });
    } else if (isResizing) {
      svg.css("cursor", "nwse-resize");

      const { x, y, width, height } = getResizingValuesFromEvent(
        svgId,
        selectedElement,
        event,
      );
      if (
        width < CUSTOM_ELEMENTS_MIN_WIDTH ||
        height < CUSTOM_ELEMENTS_MIN_HEIGHT
      )
        return;

      onElementUpdate(selectedElement, { x, y, width, height });
    }
  });

  const reset = () => {
    svg.css("cursor", "default");

    if ((isDragging || isRotating || isResizing || isRemoving) && !panning) {
      panning = true;
      svg.panZoom(CUSTOM_MODEL_EDITOR_PANZOOM_OPTIONS);
    }

    if (isDragging) {
      isDragging = false;
      delta = { x: 0, y: 0 };
    } else if (isRotating) {
      isRotating = false;
    } else if (isResizing) {
      isResizing = false;
    } else if (isRemoving) {
      isResizing = false;
    }
  };

  svg.on("pointerup pointercancel", reset);
}

/**
 * Return the mouse position in the SVG coordinates
 */
function getMousePos(svg: Svg, event: MouseEvent) {
  const pt = svg.node.createSVGPoint();
  pt.x = event.clientX;
  pt.y = event.clientY;

  return pt.matrixTransform(svg.node.getScreenCTM()?.inverse());
}

/**
 * Return the delta between the mouse position and the element position
 * Used to move an element via mouse position instead of the element origin
 */
function getMouseDelta(
  element: Element,
  mouse: { x: number; y: number },
): { x: number; y: number } {
  const posX = element.attr("pos-x");
  const posY = element.attr("pos-y");

  return {
    x: posX - mouse.x,
    y: posY - mouse.y,
  };
}

/**
 * Return the current selected element
 * i.e. the element with a selection box around it
 */
function getSelectedElement(svgId: string): Element | null {
  return SVG(`#${svgId} #selection-box`)?.attr("element-id")
    ? SVG(`#${svgId} #${SVG(`#${svgId} #selection-box`).attr("element-id")}`)
    : null;
}

/**
 * Return true if the targeted element is draggable
 * => a custom text element can be dragged by clicking on its tspan children
 * => a custom numbers element can be dragged by clicking on its digit image
 */
function getIsDragging(svgId: string, event: any): boolean {
  if (!event.target) return false;
  const elementId = event.target.id;
  if (!elementId) return false;
  const element = SVG(`#${svgId} #${elementId}`);
  // only interactive elements can be dragged
  return element?.hasClass("interactive");
}

/**
 * Return true if the targeted element is the rotate handle of the selection box
 */
function getIsRotating(event: any): boolean {
  return event.target?.id === "selection-box-rotate-handle";
}

/**
 * Return true if the targeted element is the resize handle of the selection box
 */
function getIsResizing(event: any): boolean {
  return event.target?.id === "selection-box-resize-handle";
}

/**
 * Return true if the targeted element is the remove cross of the selection box
 */
function getIsRemoving(event: any): boolean {
  return event.target
    .getAttribute("class")
    ?.includes("selection-box-remove-cross");
}

/**
 * Return the angle to rotate an element from the mouse position
 */
function getRotatingAngleFromMousePos(
  element: Element,
  mouse: { x: number; y: number },
): number {
  const posX = element.attr("pos-x");
  const posY = element.attr("pos-y");

  const width = element.bbox().width;
  const height = element.bbox().height;

  const A = Math.atan2(height / 2, width / 2);

  const a =
    Math.atan2(posY + height / 2 - mouse.y, posX + width / 2 - mouse.x) - A;

  return (a * 180) / Math.PI;
}

/**
 * Calculate the new x, y, width, and height values of an element when resizing with the mouse
 * i.e. we do not only resize the element, but also move it to keep the mouse position on the same point
 *  (e.g. if it was rotated before resize)
 */
function getResizingValuesFromEvent(
  svgId: string,
  element: Element,
  event: any,
): {
  x: number;
  y: number;
  width: number;
  height: number;
} {
  const point = (SVG(`#${svgId}`) as Svg).node.createSVGPoint();
  point.x = event.clientX;
  point.y = event.clientY;

  const rotatedPoint = point.matrixTransform(
    (element.node as SVGSVGElement).getScreenCTM()?.inverse(),
  );

  const elementX = element.attr("pos-x");
  const elementY = element.attr("pos-y");
  const elementWidth = element.bbox().width;
  const elementHeight = element.bbox().height;
  const elementAngle = element.transform().rotate ?? 0;

  const widthDifference = rotatedPoint.x - elementWidth;
  const heightDifference = rotatedPoint.y - elementHeight;

  const widthOriginMovementRight =
    widthDifference * Math.cos((elementAngle * Math.PI) / 180);
  const widthOriginMovementDown =
    widthDifference * Math.sin((elementAngle * Math.PI) / 180);
  const heightOriginMovementRight =
    heightDifference * Math.cos(((elementAngle + 90) * Math.PI) / 180);
  const heightOriginMovementDown =
    heightDifference * Math.sin(((elementAngle + 90) * Math.PI) / 180);

  const sumMovementX =
    widthOriginMovementRight + heightOriginMovementRight - widthDifference;
  const sumMovementY =
    widthOriginMovementDown + heightOriginMovementDown - heightDifference;

  const x = elementX + sumMovementX / 2;
  const y = elementY + sumMovementY / 2;
  const width = rotatedPoint.x;
  const height = rotatedPoint.y;

  return { x, y, width, height };
}
