import { Model } from "@/types/model.ts";
import { ModelFilters } from "@admin/components/models/model-list-filters.tsx";

export function filterModels(models: Model[], filters: ModelFilters): Model[] {
  return models.filter((model) => {
    const { searchText, brand } = filters;

    // first filter by brand
    if (brand && model.brand !== brand) {
      return false;
    }

    // then filter by search text
    if (searchText) {
      return `${model.name}${model.brand ? " " + model.brand : ""}`
        .toLowerCase()
        .trim()
        .includes(searchText.toLowerCase().trim());
    }

    return true;
  });
}
