import { cn } from "@/lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@components/ui/tooltip.tsx";

export default function CustomLabAppTitle() {
  return (
    <TooltipProvider delayDuration={1000}>
      <Tooltip>
        <TooltipTrigger asChild className="hidden lg:block">
          <div className="select-none">
            <div
              className={cn(
                "absolute left-1/2 top-3 -translate-x-1/2 xl:top-6",
                "text-3xl uppercase",
                "font-['Avant_Garde'] font-bold",
              )}
            >
              Custom lab
            </div>
            <div
              className={cn(
                "absolute left-1/2 top-9 -translate-x-1/2  xl:top-12",
                "font-primary text-sm normal-case",
              )}
            >
              Powered by MXLab
            </div>
          </div>
        </TooltipTrigger>

        <TooltipContent side="bottom" sideOffset={80}>
          <p>
            Made with (too much) ☕️ by{" "}
            <span className="font-semibold ">@vlaude</span>
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
