import { Input } from "@components/ui/input.tsx";
import { cn } from "@lib/utils.ts";
import { ChevronDown, ChevronUp } from "lucide-react";
import React, { useRef } from "react";

type NumberInputProps = React.ComponentPropsWithoutRef<"input">;

export default function NumberInput({ className, ...props }: NumberInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  function handleStepUp() {
    if (!inputRef.current) return;
    inputRef.current.stepUp();
    inputRef.current.dispatchEvent(
      new CustomEvent("change", { bubbles: true }),
    );
  }

  function handleStepDown() {
    if (!inputRef.current) return;
    inputRef.current.stepDown();
    inputRef.current.dispatchEvent(
      new CustomEvent("change", { bubbles: true }),
    );
  }

  return (
    <div className="flex w-fit items-center gap-2">
      <Input
        ref={inputRef}
        type="number"
        className={cn("text-base", className)}
        {...props}
      />

      <div className="xl:hidden">
        <ChevronUp
          className="focus:opacity-50"
          size="28"
          onClick={handleStepUp}
        />
        <ChevronDown
          className="focus:opacity-50"
          size="28"
          onClick={handleStepDown}
        />
      </div>
    </div>
  );
}
