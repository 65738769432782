export default function PageNotFound() {
  return (
    <div className="mxlab-logo-background grid h-screen w-screen place-items-center !bg-white !bg-[center_center] text-slate-950">
      <div className="flex flex-col items-center gap-0 text-center">
        <div className="text-[10rem] leading-[10rem]">404</div>
        <div className="text-3xl font-bold">Not Found</div>
      </div>
    </div>
  );
}
