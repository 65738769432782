import { Logo } from "@/types/custom-element.ts";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@components/ui/alert-dialog.tsx";
import { Button } from "@components/ui/button.tsx";
import { AlertDialogProps } from "@radix-ui/react-alert-dialog";

type ConfirmDeleteLogosAlertDialogProps = AlertDialogProps & {
  logos: Logo[];
  onConfirm: (logos: Logo[]) => void;
  onCancel: () => void;
};

export default function ConfirmDeleteLogosAlertDialog({
  logos,
  onConfirm,
  onCancel,
  ...props
}: ConfirmDeleteLogosAlertDialogProps) {
  return (
    <AlertDialog {...props}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Supprimer{" "}
            {logos.length === 1
              ? "le logo séléctionné ?"
              : `les (${logos.length}) logos séléctionnés ?`}
          </AlertDialogTitle>
          <AlertDialogDescription>
            Cela supprimera les logos de manière permanente, et ils ne seront
            plus disponibles pour les customisations.
            <br />
            <br />
            Cette action ne peut pas être annulée.
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel onClick={onCancel}>Annuler</AlertDialogCancel>

          <AlertDialogAction asChild>
            <Button
              variant="destructive"
              size="sm"
              onClick={() => onConfirm(logos)}
            >
              Supprimer
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
