import MxlabLoader from "@/components/ui/mxlab-loader";
import { useToast } from "@/components/ui/use-toast";
import { Logo } from "@/types/custom-element.ts";
import AdminSectionDivider from "@admin/components/layout/admin-section-divider.tsx";
import ConfirmDeleteLogosAlertDialog from "@admin/components/logos/confirm-delete-logos-alert-dialog.tsx";
import LogoListActions from "@admin/components/logos/logo-list-actions.tsx";
import FilesImportDialog from "@components/files-import/files-import-dialog.tsx";
import LogoListFilters from "@components/logos/logo-list-filters.tsx";
import LogoList from "@components/logos/logo-list.tsx";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogTitle,
} from "@components/ui/alert-dialog.tsx";
import { Alert, AlertDescription, AlertTitle } from "@components/ui/alert.tsx";
import { Button } from "@components/ui/button.tsx";
import OvalLoader from "@components/ui/oval-loader.tsx";
import { useElementSize } from "@custom-react-hooks/use-element-size";
import useLogos from "@hooks/use-logos.ts";
import { api } from "@lib/api.ts";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { Import, TriangleAlert } from "lucide-react";
import { useState } from "react";

type LogosActionState = {
  processing: boolean;
  progress: number;
  total: number;
};

export default function AdminLogosRoute() {
  const {
    filteredLogos,
    loading,
    error,
    activeFilters,
    handleFilter,
    selectedLogos,
    selectMode,
    handleSelectLogos,
    addLogos,
    removeLogos,
  } = useLogos();

  const [showFilesImportDialog, setShowFilesImportDialog] =
    useState<boolean>(false);

  const [uploadState, setUploadState] = useState<LogosActionState>({
    processing: false,
    progress: 0,
    total: 0,
  });
  const [deleteState, setDeleteState] = useState<LogosActionState>({
    processing: false,
    progress: 0,
    total: 0,
  });

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] =
    useState<boolean>(false);

  const [headerRef, headerSize] = useElementSize();
  const { toast } = useToast();

  function handleSelectAll(select: boolean) {
    handleSelectLogos(select ? filteredLogos : []);
  }

  function handleLogoClick(logo: Logo) {
    const isSelected = selectedLogos.find((l) => l.url === logo.url);
    if (isSelected) {
      handleSelectLogos(selectedLogos.filter((l) => l.url !== logo.url));
    } else {
      handleSelectLogos([...selectedLogos, logo]);
    }
  }

  async function handleDelete(logos: Logo[]) {
    setDeleteState({ processing: true, progress: 0, total: logos.length });

    try {
      // delete logos one by one to get progress
      await Promise.all(
        logos.map(async (logo) => {
          await api.deleteLogos([logo]);
          setDeleteState((prevState) => ({
            ...prevState,
            progress: prevState.progress + 1,
          }));
        }),
      );

      removeLogos(logos);

      toast({
        title: "Logo(s) supprimé(s)",
        description: `Les logos ont été supprimés`,
      });
    } catch (err) {
      console.log("Error while deleting logos");
      console.error(err);
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors de la suppression des logos. Veuillez réessayer plus tard",
        variant: "destructive",
      });
    } finally {
      setOpenConfirmDeleteDialog(false);
      setDeleteState({ processing: false, progress: 0, total: 0 });
    }
  }

  async function handleUpload(files: File[]) {
    setUploadState({ processing: true, progress: 0, total: files.length });

    try {
      // upload files one by one to get progress
      const uploaded = (
        await Promise.all(
          files.map(async (file) => {
            const uploaded = await api.uploadLogos([file]);
            setUploadState((prevState) => ({
              ...prevState,
              progress: prevState.progress + 1,
            }));
            return uploaded;
          }),
        )
      ).flatMap((logo) => logo);

      addLogos(uploaded);
      setShowFilesImportDialog(false);

      toast({
        title: "Logo(s) importé(s)",
        description: `Les logos ont été importés sur le serveur`,
      });
    } catch (err) {
      console.log("Error while uploading logos");
      console.error(err);
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors de l'import des logos. Veuillez réessayer plus tard",
        variant: "destructive",
      });
    } finally {
      setUploadState({ processing: false, progress: 0, total: 0 });
    }
  }

  return (
    <>
      {loading ? (
        <div className="grid h-full place-items-center">
          <MxlabLoader color="black" text="Chargement des logos..." />
        </div>
      ) : (
        <>
          {/* header */}
          <div ref={headerRef}>
            <div className="mb-2 flex items-center justify-between">
              <div className="text-xl font-black uppercase text-black">
                Logos
              </div>

              <FilesImportDialog
                open={showFilesImportDialog}
                onOpenChange={setShowFilesImportDialog}
                options={{
                  accept: {
                    "image/jpeg": [".jpeg", ".jpg"],
                    "image/png": [".png"],
                    "application/pdf": [".pdf"],
                  },
                  maxSize: 1048576, // 1MB
                }}
                onSubmit={handleUpload}
              >
                <Button
                  className="text-sm uppercase"
                  variant="primary"
                  size="sm"
                >
                  <Import className="mr-2 h-4 w-4" />
                  Importer
                </Button>
              </FilesImportDialog>
            </div>

            <LogoListFilters
              activeFilters={activeFilters}
              onFilter={handleFilter}
            />
          </div>

          <div className="mt-4 rounded-lg bg-white">
            <div className="p-4 pb-2">
              <LogoListActions
                selectedLogos={selectedLogos}
                selectMode={selectMode}
                onSelectAll={handleSelectAll}
                onDeleteClick={() => setOpenConfirmDeleteDialog(true)}
              />
              <AdminSectionDivider
                className="mb-0"
                borderClassName="border-slate-600"
                textClassName="text-slate-600"
              >
                Logos ({filteredLogos.length})
              </AdminSectionDivider>
            </div>

            {/* scrollable content */}
            <div
              className="overflow-auto p-4 shadow-inner"
              style={{
                height: `calc(100dvh - ${headerSize.height}px - 176px)`,
              }}
            >
              {!error ? (
                <LogoList
                  logos={filteredLogos}
                  selectedLogos={selectedLogos}
                  onLogoClick={handleLogoClick}
                />
              ) : (
                <Alert variant="destructive" className="border-2 bg-white/50">
                  <TriangleAlert />
                  <AlertTitle>Oops</AlertTitle>
                  <AlertDescription>
                    Une erreur est survenue lors de la récupération des logos.
                    Veuillez réessayer plus tard.
                  </AlertDescription>
                </Alert>
              )}
            </div>
          </div>
        </>
      )}

      {/* upload progress */}
      <AlertDialog open={uploadState.processing}>
        <AlertDialogContent>
          <VisuallyHidden>
            <AlertDialogTitle>Import des logos</AlertDialogTitle>
            <AlertDialogDescription>
              Progession de l'import des logos
            </AlertDialogDescription>
          </VisuallyHidden>

          <div className="grid place-items-center gap-8 p-4 text-slate-950">
            <OvalLoader width={150} height={150} />
            <div className="text-center">
              Upload des logos sur le serveur en cours...
              <br />( {uploadState?.progress} / {uploadState?.total} )
            </div>
          </div>
        </AlertDialogContent>
      </AlertDialog>

      {/* delete progress */}
      <AlertDialog open={deleteState.processing}>
        <AlertDialogContent>
          <VisuallyHidden>
            <AlertDialogTitle>Suppression des logos</AlertDialogTitle>
            <AlertDialogDescription>
              Progession de la suppression des logos
            </AlertDialogDescription>
          </VisuallyHidden>

          <div className="grid place-items-center gap-8 p-4 text-slate-950">
            <OvalLoader width={150} height={150} />
            <div className="text-center">
              Suppression des logos sur le serveur en cours...
              <br />( {deleteState?.progress} / {deleteState?.total} )
            </div>
          </div>
        </AlertDialogContent>
      </AlertDialog>

      {/* delete logo(s) dialog */}
      <ConfirmDeleteLogosAlertDialog
        open={openConfirmDeleteDialog}
        logos={selectedLogos}
        onConfirm={handleDelete}
        onCancel={() => setOpenConfirmDeleteDialog(false)}
      />
    </>
  );
}
