import { cn } from "@lib/utils.ts";
import { BookOpen } from "lucide-react";
import React from "react";
import { useTranslation } from "react-i18next";

type StepBarNoticeBtnProps = React.ComponentPropsWithoutRef<"div"> & {
  active?: boolean;
};

export default function StepBarNoticeBtn({
  className,
  active = false,
  ...props
}: StepBarNoticeBtnProps) {
  const { t } = useTranslation("step-bar");

  return (
    <div
      className={cn(
        "flex items-center gap-1.5",
        "rounded px-2 py-0.5",
        "cursor-pointer hover:bg-[#4a4a4a]",
        { "bg-accent text-slate-950 hover:bg-accent/75": active },
        className,
      )}
      {...props}
    >
      <BookOpen className="h-5 w-5 translate-y-[1px]" />
      <span className="text-xl font-bold uppercase">{t("notice")}</span>
    </div>
  );
}
