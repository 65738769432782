import { cn } from "@lib/utils.ts";
import React from "react";
import { FaCheck, FaExclamation, FaRegCircleCheck } from "react-icons/fa6";

type MessageProps = React.ComponentPropsWithoutRef<"div"> & {
  type?: "success" | "error" | "warning" | "info";
};

/**
 * Display a message withing a colored box according to the type
 */
export default function Message({
  className,
  type = "info",
  children,
  ...props
}: MessageProps) {
  const messageColorVariants = {
    success: "bg-success/10 border-success text-success",
    error: "bg-error/10 border-error text-error",
    warning: "text-yellow-600 bg-yellow-100",
    info: "text-blue-600 bg-blue-100",
  };

  const messageIconVariants = {
    success: <FaCheck className="h-3.5 w-3.5 fill-success" />,
    error: <FaExclamation className="fill-error" />,
    warning: <FaRegCircleCheck className="text-yellow-600" />,
    info: <FaRegCircleCheck className="text-blue-600" />,
  };

  return (
    <div
      className={cn(
        "flex items-center gap-2",
        "rounded-sm border px-2 py-1.5",
        messageColorVariants[type],
        className,
      )}
      {...props}
    >
      {messageIconVariants[type]}
      {children}
    </div>
  );
}
