import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
} from "@components/ui/alert-dialog.tsx";
import { Button } from "@components/ui/button.tsx";
import { ReactNode, useState } from "react";

function useConfirm(
  title: string | ReactNode,
  message: string | ReactNode,
): [() => JSX.Element, () => Promise<boolean>] {
  const [promise, setPromise] = useState<{
    resolve: (confirm: boolean) => void;
  }>();

  function confirm() {
    return new Promise<boolean>((resolve) => {
      setPromise({ resolve });
    });
  }

  function handleClose() {
    setPromise(undefined);
  }

  function handleConfirm() {
    promise?.resolve(true);
    handleClose();
  }

  function handleCancel() {
    promise?.resolve(false);
    handleClose();
  }

  const ConfirmationAlertDialog = () => (
    <AlertDialog open={!!promise}>
      <AlertDialogContent>
        <AlertDialogTitle className="mb-0">{title}</AlertDialogTitle>

        <AlertDialogDescription>{message}</AlertDialogDescription>

        <AlertDialogFooter>
          <AlertDialogCancel onClick={handleCancel}>Annuler</AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button variant="primary" size="sm" onClick={handleConfirm}>
              Confirmer
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );

  return [ConfirmationAlertDialog, confirm];
}

export default useConfirm;
