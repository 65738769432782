import { cn, sortStr } from "@lib/utils.ts";
import React, { useEffect, useState } from "react";
import { FaAngleDown, FaFont } from "react-icons/fa6";

type FontAccordionProps = React.ComponentPropsWithoutRef<"div"> & {
  title?: string;
  toggled?: boolean;
  fontList: string[];
  activeFont: string;
  text?: string;
  onFontClick: (font: string) => void;
};

export default function FontAccordion({
  className,
  title = "Police",
  toggled: toggledProp = false,
  fontList,
  activeFont,
  text: textProp = "Made by vlaude",
  onFontClick,
  ...props
}: FontAccordionProps) {
  const [toggled, setToggled] = useState<boolean>();
  const [text, setText] = useState<string>(textProp);

  useEffect(() => {
    setToggled(toggledProp);
  }, [toggledProp]);

  useEffect(() => {
    if (textProp) {
      setText(textProp);
    } else {
      setText("Made by vlaude");
    }
  }, [textProp]);

  return (
    <div className={cn("w-full", className)} {...props}>
      <div
        className={cn(
          "flex items-center gap-3",
          "rounded-lg border border-transparent p-2 xl:py-3",
          "cursor-pointer hover:bg-[#4a4a4a]",
          { "border-[#727272] bg-[#4a4a4a]": toggled },
        )}
        onClick={() => setToggled((prevState) => !prevState)}
      >
        <FaFont className="mx-1" />

        {/* title */}
        <div className="line-clamp-1 uppercase">
          {title?.replaceAll("_", " ")}
        </div>

        <FaAngleDown
          className={cn("ml-auto h-4 w-4 duration-200", {
            "-rotate-180": toggled,
          })}
        />
      </div>

      {/* expandable panel */}
      <div
        className={cn(
          "flex flex-col",
          "fancy-scrollbar overflow-auto duration-200",
        )}
        style={{ maxHeight: toggled ? 500 : 0 }}
      >
        <div className="p-2">
          {fontList.sort(sortStr).map((font) => (
            <React.Fragment key={font}>
              <div
                className={cn(
                  "cursor-pointer rounded px-2 py-0 hover:bg-[#4a4a4a]",
                  "border border-transparent",
                  {
                    "border-[#727272] bg-[#4a4a4a]": font === activeFont,
                  },
                )}
                onClick={() => onFontClick(font)}
              >
                {/* font name */}
                <div className="uppercase">{font.replaceAll("_", " ")}</div>
                {/* text preview */}
                <div
                  className="truncate whitespace-nowrap text-2xl"
                  style={{ fontFamily: font }}
                >
                  {text}
                </div>
              </div>
              <hr className="my-1" />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}
