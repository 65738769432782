import { Button } from "@/components/ui/button";
import { DateRangePicker } from "@/components/ui/date-picker-range";
import SearchInput from "@components/ui/search-input.tsx";
import { cn } from "@lib/utils.ts";
import { FilterX } from "lucide-react";
import React from "react";
import { DateRange } from "react-day-picker";

export type CustomModelFilters = {
  searchText: string;
  dateRange?: DateRange | undefined;
};

type CustomModelListFiltersProps = React.ComponentPropsWithoutRef<"div"> & {
  activeFilters: CustomModelFilters;
  onFilter: (filters: CustomModelFilters) => void;
};

export default function CustomModelListFilters({
  className,
  activeFilters,
  onFilter,
  ...props
}: CustomModelListFiltersProps) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFilter({ ...activeFilters, searchText: e.target.value });
  };

  const onDateChange = (dateRange: DateRange | undefined) => {
    onFilter({ ...activeFilters, dateRange });
  };

  const clearFilters = () => {
    onFilter({ searchText: "", dateRange: undefined });
  };

  return (
    <div className={cn("flex w-full items-center gap-4", className)} {...props}>
      {/* search */}
      <SearchInput
        id="searchText"
        className="w-[400px]"
        type="text"
        placeholder="Rechercher par modèle, marque..."
        value={activeFilters.searchText}
        onChange={handleSearch}
      />

      {/* date */}
      <DateRangePicker
        placeholder="Date de création"
        disabled={{ after: today }}
        defaultMonth={lastMonth}
        toMonth={today}
        selected={activeFilters.dateRange}
        onSelect={onDateChange}
      />

      <Button
        className="border border-slate-600/25 bg-white hover:bg-slate-100"
        onClick={clearFilters}
        size="icon"
      >
        <FilterX className="h-4 w-4 stroke-slate-700" />
      </Button>
    </div>
  );
}
