import { CustomModel } from "@/types/custom-model.ts";
import CustomModelCardDropdownActions from "@admin/components/custom-models/custom-model-card-dropdown-actions.tsx";
import { Badge } from "@components/ui/badge.tsx";
import OvalLoader from "@components/ui/oval-loader.tsx";
import CustomModelSvgInjector from "@custom-model-editor/components/custom-model-svg-injector.tsx";
import { cn } from "@lib/utils.ts";
import { compareDesc } from "date-fns";
import React from "react";
import RenderIfVisible from "react-render-if-visible";
import { Link } from "react-router-dom";

type CustomModelListProps = React.ComponentPropsWithoutRef<"div"> & {
  customModels: CustomModel[];
  onDelete: (customModelId: string) => void;
};

export default function CustomModelList({
  className,
  customModels,
  onDelete,
  ...props
}: CustomModelListProps) {
  function sortCustomModels(a: CustomModel, b: CustomModel): number {
    const aDate = a.updatedAt || a.createdAt || new Date();
    const bDate = b.updatedAt || b.createdAt || new Date();
    return compareDesc(aDate, bDate);
  }

  return (
    <div className={cn("", className)} {...props}>
      {customModels.length ? (
        <div className="grid grid-cols-2 gap-4 xl:grid-cols-3">
          {customModels.sort(sortCustomModels).map((cm) => (
            <RenderIfVisible key={cm.id} defaultHeight={300} stayRendered>
              <div className={cn("card relative", "overflow-hidden")}>
                <Link to={`/customize/${cm.id}?mode=read-only`}>
                  <div className="group cursor-pointer">
                    {/* model name */}
                    <div className="flex items-center justify-between">
                      <div className="underline-offset-4 group-hover:underline">
                        {cm.model.name}
                      </div>

                      {/* model brand */}
                      {cm.model.brand ? (
                        <Badge className="bg-slate-500">{cm.model.brand}</Badge>
                      ) : null}
                    </div>

                    <div className="py-2 duration-300 group-hover:scale-110">
                      <CustomModelSvgInjector
                        customModel={cm}
                        interactive={false}
                        Loader={() => (
                          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                            <OvalLoader width={100} height={100} />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </Link>

                <CustomModelCardDropdownActions
                  customModel={cm}
                  triggerClassName="absolute bottom-2 right-2"
                  onDelete={() => onDelete(cm.id)}
                />
              </div>
            </RenderIfVisible>
          ))}
        </div>
      ) : (
        <div className="mt-4 text-center text-lg text-black">
          Aucune customisation
        </div>
      )}
    </div>
  );
}
