import { ShopifyProduct } from "@/types/shopify.ts";
import { cn } from "@lib/utils.ts";
import React from "react";

type ShopifyProductStatusBadgeProps = React.ComponentPropsWithoutRef<"div"> & {
  status: ShopifyProduct["status"];
};

export default function ShopifyProductStatusBadge({
  status,
  ...props
}: ShopifyProductStatusBadgeProps) {
  const badgeClasses: Record<ShopifyProduct["status"], string> = {
    ACTIVE: "bg-[#affebf] text-[#014b40]",
    ARCHIVED: "bg-[#0000000f] text-[#616161]",
    DRAFT: "bg-[#d5ebff] text-[#003a5a]",
  };

  return (
    <div
      className={cn(
        "w-fit rounded-lg px-2 py-0.5 lowercase first-letter:capitalize",
        badgeClasses[status],
      )}
      {...props}
    >
      {status}
    </div>
  );
}
