import AdminMenuLink from "@admin/components/layout/admin-menu-link.tsx";
import shopifyLogo from "@assets/shopify-logo.webp";
import ScrollToTop from "@components/layout/scroll-to-top.tsx";
import Sidebar from "@components/layout/sidebar.tsx";
import { cn } from "@lib/utils.ts";
import { Outlet } from "react-router-dom";

export default function Admin() {
  // function breakTheWorld() {
  //   try {
  //     throw new Error("Boom! 💥");
  //   } catch (err) {
  //     console.log("We break the world!");
  //     Sentry.captureException(err);
  //   }
  // }

  return (
    <>
      <Sidebar>
        <div
          className={cn(
            "mb-3 mt-1",
            "text-center font-['Avant_Garde'] text-lg font-semibold uppercase text-admin",
          )}
        >
          Custom Lab Admin
        </div>

        {/* navigation menu */}
        <div className="flex flex-col gap-2 px-2">
          <AdminMenuLink to="models">Modèles</AdminMenuLink>
          <AdminMenuLink to="customizations">Customisations</AdminMenuLink>
          <AdminMenuLink to="logos">Logos</AdminMenuLink>
          <AdminMenuLink className="flex items-center gap-2" to="shopify">
            <img
              className="h-auto w-[25px]"
              src={shopifyLogo}
              alt="Shopify logo"
            />
            Shopify
          </AdminMenuLink>
          <AdminMenuLink to="settings">Paramètres</AdminMenuLink>
        </div>

        {/*<Button className="mt-auto" onClick={breakTheWorld}>*/}
        {/*  Break the world*/}
        {/*</Button>*/}
      </Sidebar>

      <div className="container mx-auto p-6">
        <Outlet />
        <ScrollToTop />
      </div>
    </>
  );
}
