import { Button } from "@components/ui/button.tsx";
import { cn } from "@lib/utils.ts";
import { Plus } from "lucide-react";
import { Link, LinkProps } from "react-router-dom";

type NewModelLinkProps = Omit<LinkProps, "to">;

export default function NewModelLink({
  className,
  ...props
}: NewModelLinkProps) {
  return (
    <Link className={cn(className)} to="/admin/models/new" {...props}>
      <Button variant="primary" className="text-sm uppercase" size="sm">
        <Plus className="mr-2 h-4 w-4" />
        Nouveau modèle
      </Button>
    </Link>
  );
}
