import { ColorLayer } from "@/types/color-elements.ts";
import { Button } from "@components/ui/button.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/ui/popover.tsx";
import ColorLayerConfigForm from "@custom-model-editor/components/color-layers/color-layer-config-form.tsx";
import { cn } from "@lib/utils.ts";
import { PopoverProps } from "@radix-ui/react-popover";
import { Settings } from "lucide-react";

type ConfigureColorLayerPopoverProps = PopoverProps & {
  className?: string;
  colorLayer: ColorLayer;
};

export default function ConfigureColorLayerPopover({
  className,
  colorLayer,
  ...props
}: ConfigureColorLayerPopoverProps) {
  return (
    <Popover {...props}>
      <PopoverTrigger asChild>
        <Button
          className={cn("text-base uppercase", className)}
          variant="admin"
        >
          <Settings className="mr-2 h-4 w-4" />
          Configurer le calque
        </Button>
      </PopoverTrigger>

      <PopoverContent
        side="right"
        sideOffset={40}
        align="start"
        alignOffset={-50}
        sticky="always"
        className="max-h-[85dvh] overflow-y-auto lg:w-[640px] xl:w-[920px]"
      >
        <ColorLayerConfigForm
          colorLayer={colorLayer}
          className={cn(
            "grid w-full grid-cols-1 gap-x-4 gap-y-8",
            "lg:grid-cols-2",
            "xl:grid-cols-3",
          )}
          patternsColClassName="lg:col-span-2 lg:justify-self-end xl:col-span-1 xl:justify-self-start"
          submitClassName="lg:col-span-2 xl:col-span-3 justify-self-end"
        />
      </PopoverContent>
    </Popover>
  );
}
