import { Logo } from "@/types/custom-element.ts";
import { cn } from "@lib/utils.ts";
import React from "react";
import { useTranslation } from "react-i18next";

type LogoListProps = React.ComponentPropsWithoutRef<"div"> & {
  logos: Logo[];
  onLogoClick?: (logo: Logo) => void;
  selectedLogos?: Logo[];

  logoWrapperClassName?: string;
  logoImgClassName?: string;
};

export default function LogoList({
  className,
  logos,
  selectedLogos,
  onLogoClick,
  logoWrapperClassName,
  logoImgClassName,
  ...props
}: LogoListProps) {
  const { t } = useTranslation("logo-library");

  return (
    <div
      className={cn(
        "grid grid-cols-4 gap-4 md:grid-cols-6 xl:grid-cols-8",
        "text-slate-950",
        { "!grid-cols-1": !logos.length },
        className,
      )}
      {...props}
    >
      {logos.length ? (
        logos
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((logo) => (
            <div
              key={logo.url}
              className={cn(
                "relative grid h-full w-full place-items-center",
                "group cursor-pointer rounded border-4 border-transparent bg-[#323232]/30 p-2",
                {
                  "border-primary bg-[#323232]/15": selectedLogos?.find(
                    (l) => l.name === logo.name,
                  ),
                },
                logoWrapperClassName,
              )}
              onClick={() => onLogoClick?.(logo)}
            >
              <img
                src={logo.url}
                alt={logo.name}
                className={cn(
                  "aspect-square object-contain duration-300 group-hover:scale-110",
                  logoImgClassName,
                )}
              />
            </div>
          ))
      ) : (
        <div className="py-4 text-center">{t("no-logo-found")}</div>
      )}
    </div>
  );
}
