import { ADMIN_ERROR_MESSAGES } from "@/config.ts";
import { firebaseService } from "@lib/firebase.ts";
import React, { useState } from "react";

type FormValues = {
  email: string;
  password: string;
};

export const useAdminLogin = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  async function submitForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (loading) return;

    setLoading(true);
    setError(undefined);

    let user;

    try {
      const userCredential = await firebaseService.signIn(
        formValues.email,
        formValues.password,
      );
      user = userCredential.user;
    } catch (err) {
      console.log("Error while sign in");
      console.error(err);

      // get error message according to error code from firebase
      let errorMsg = "";
      if (typeof err === "object" && err !== null && "code" in err) {
        const { code } = err;
        errorMsg =
          ADMIN_ERROR_MESSAGES[code as keyof typeof ADMIN_ERROR_MESSAGES];
      }

      setError(errorMsg || "Une erreur inconnue est survenue");
    } finally {
      setLoading(false);
    }

    // if user is not verified, send verification email
    try {
      if (!user?.emailVerified) {
        await firebaseService.verifyEmail();
      }
    } catch (err) {
      console.log("Error while verifying email");
      console.error(err);
      setError(
        "Une erreur inconnue est survenue lors de la vérification de l'email",
      );
    }
  }

  return { formValues, setFormValues, loading, error, submitForm };
};
