import { Model } from "@/types/model.ts";
import ModelsCombobox from "@admin/components/models/models-combobox.tsx";
import { Alert, AlertDescription, AlertTitle } from "@components/ui/alert.tsx";
import OvalLoader from "@components/ui/oval-loader.tsx";
import CustomModelSvgInjector from "@custom-model-editor/components/custom-model-svg-injector.tsx";
import { cn } from "@lib/utils.ts";
import { ArrowBigDown, TriangleAlert } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

type ShopifyProductLinkModelPickerProps =
  React.ComponentPropsWithoutRef<"div"> & {
    model?: Model;
    onModelSelect: (model?: Model) => void;
  };

export default function ShopifyProductLinkModelPicker({
  className,
  model,
  onModelSelect,
  ...props
}: ShopifyProductLinkModelPickerProps) {
  return (
    <div className={cn("flex h-full flex-col gap-6", className)} {...props}>
      {model ? (
        <Link to={`/customize?model=${model.id}`} target="_blank">
          <div className="aspect-square max-h-[350px] w-full overflow-hidden rounded-lg border">
            <div className="h-full w-full cursor-pointer duration-200 hover:scale-110">
              <CustomModelSvgInjector
                customModel={{
                  id: `model-to-link-preview`,
                  model,
                  colorLayers: [],
                  elements: [],
                  importedLogos: [],
                }}
                interactive={false}
                Loader={() => (
                  <div className="grid h-full w-full place-items-center">
                    <OvalLoader width={100} height={100} />
                  </div>
                )}
              />
            </div>
          </div>
        </Link>
      ) : (
        <div className="grid aspect-square max-h-[350px] w-full place-items-center rounded-lg border">
          <div className="flex animate-bounce flex-col items-center gap-1 text-slate-950">
            Sélectionne un modèle à associer
            <ArrowBigDown className="stroke-slate-900" />
          </div>
        </div>
      )}

      {/* select new model to link */}
      <div className="self-center">
        <ModelsCombobox onSelect={onModelSelect} side="left" />
      </div>

      {/* model already linked to a product */}
      {model?.shopifyProduct ? (
        <Alert variant="warning" className="-mt-3">
          <TriangleAlert className="h-4 w-4" />
          <AlertTitle>Association impossible</AlertTitle>
          <AlertDescription>
            Ce modèle est déjà associé au produit{" "}
            <Link
              to={model.shopifyProduct.onlineStorePreviewUrl}
              className="whitespace-nowrap text-sky-500 underline underline-offset-2 after:content-['_↗'] hover:opacity-75"
              target="_blank"
            >
              {model.shopifyProduct.handle}
            </Link>
          </AlertDescription>
        </Alert>
      ) : null}
    </div>
  );
}
