import { Logo } from "@/types/custom-element.ts";
import LogoListFilters from "@components/logos/logo-list-filters.tsx";
import LogoList from "@components/logos/logo-list.tsx";
import { Alert, AlertDescription, AlertTitle } from "@components/ui/alert.tsx";
import { Button } from "@components/ui/button.tsx";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "@components/ui/dialog.tsx";
import MxlabLoader from "@components/ui/mxlab-loader.tsx";
import { useElementSize } from "@custom-react-hooks/use-element-size";
import useLogos from "@hooks/use-logos.ts";
import { cn } from "@lib/utils.ts";
import { DialogProps } from "@radix-ui/react-dialog";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { TriangleAlert } from "lucide-react";
import { useTranslation } from "react-i18next";

type LogosLibraryDialogProps = DialogProps & {
  imported?: Logo[];
  onLogoClick?: (logo: Logo) => void;
};

export default function LogosLibraryDialog({
  imported = [],
  onLogoClick,
  ...props
}: LogosLibraryDialogProps) {
  const { filteredLogos, loading, error, activeFilters, handleFilter } =
    useLogos();

  const [filtersRef, { height: filtersHeight }] = useElementSize();

  const { t } = useTranslation("logo-library");

  return (
    <Dialog {...props}>
      <DialogTrigger asChild>
        <Button variant="primary" className="uppercase">
          {t("trigger-btn")}
        </Button>
      </DialogTrigger>
      <DialogContent
        className={cn(
          "h-[90dvh] max-w-[90dvw] xl:max-w-[1080px]",
          "border-none bg-[#323232] p-0",
          "[&_.dialog-close>svg]:!text-slate-50",
        )}
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <VisuallyHidden>
          <DialogTitle>{t("dialog-title")}</DialogTitle>
          <DialogDescription>{t("dialog-description")}</DialogDescription>
        </VisuallyHidden>

        {loading ? (
          <div className="grid place-items-center">
            <MxlabLoader text={t("loading")}></MxlabLoader>
          </div>
        ) : (
          <div>
            <div ref={filtersRef}>
              <LogoListFilters
                className="flex flex-col items-center gap-2 p-2 lg:gap-4 lg:p-4"
                activeFilters={activeFilters}
                onFilter={handleFilter}
                lettersContainerClassName="justify-center mt-0"
                lettersItemClassName="text-slate-50 bg-[#222] hover:bg-[#222]/50"
              />
            </div>
            <div
              className="fancy-scrollbar overflow-auto p-2 shadow-inner lg:p-4"
              style={{ height: `calc(90dvh - ${filtersHeight}px)` }}
            >
              {!error ? (
                <>
                  {imported?.length ? (
                    <>
                      <div className="mb-2 text-lg font-semibold text-slate-50">
                        {t("your-logos-title", { count: imported.length })}
                      </div>
                      <LogoList
                        className="mb-4"
                        logoWrapperClassName="bg-[#444]"
                        logos={imported}
                        onLogoClick={onLogoClick}
                      />
                      <div className="mb-2 text-lg font-semibold text-slate-50">
                        {t("mxlab-logos-title")}
                      </div>
                    </>
                  ) : null}
                  <LogoList
                    className="text-slate-50"
                    logoWrapperClassName="bg-[#444]"
                    logos={filteredLogos}
                    onLogoClick={onLogoClick}
                  />
                </>
              ) : (
                <Alert variant="destructive" className="border-2 bg-[#444]">
                  <TriangleAlert />
                  <AlertTitle>{t("error-title")}</AlertTitle>
                  <AlertDescription>{t("error-msg")}</AlertDescription>
                </Alert>
              )}
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
