import { ColorElement, ColorGroup } from "@/types/color-elements.ts";
import { sortStr } from "@lib/utils.ts";

export function sortColorGroups(a: ColorGroup, b: ColorGroup) {
  return sortStr(a.name, b.name);
}

export function sortColorElements(a: ColorElement, b: ColorElement) {
  return sortStr(a.name, b.name);
}
