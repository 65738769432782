import { Input } from "@components/ui/input.tsx";
import { cn } from "@lib/utils.ts";
import React, { ComponentType } from "react";

type InputIconProps = React.ComponentPropsWithoutRef<"input"> & {
  Icon?: ComponentType<{ className: string }>;
};

export default function InputIcon({
  className,
  Icon,
  ...props
}: InputIconProps) {
  return (
    <div className="relative">
      <Input className={cn("h-[55px]", className)} {...props} />
      {Icon ? (
        <Icon
          className={cn(
            "absolute right-4 top-1/2 -translate-y-1/2",
            "h-5 w-5 fill-[#555]",
          )}
        />
      ) : null}
    </div>
  );
}
