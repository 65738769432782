import { CUSTOM_LAB_COLORS } from "@/config.ts";
import { Color } from "@/types/color-elements.ts";
import { Checkbox } from "@components/ui/checkbox.tsx";
import { ToggleGroup, ToggleGroupItem } from "@components/ui/toggle-group.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@components/ui/tooltip.tsx";
import { cn, whiteOrBlack } from "@lib/utils.ts";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import { Check } from "lucide-react";
import React from "react";

type ColorLayerConfigColorSelectProps = Omit<
  React.ComponentPropsWithoutRef<"div">,
  "onChange"
> & {
  value: Color[];
  onChange: (value: Color[]) => void;
};

export default function ColorLayerConfigColorSelect({
  className,
  value,
  onChange,
  ...props
}: ColorLayerConfigColorSelectProps) {
  function handleChange(values: string[]) {
    onChange(CUSTOM_LAB_COLORS.filter((c) => values.includes(c.name)));
  }

  return (
    <div className={cn(className)} {...props}>
      <label className="mb-2 flex cursor-pointer items-center gap-1 underline-offset-2 hover:underline">
        <Checkbox
          checked={value?.length === CUSTOM_LAB_COLORS.length}
          onCheckedChange={(checked) =>
            handleChange(checked ? CUSTOM_LAB_COLORS.map((c) => c.name) : [])
          }
        />{" "}
        Tout sélectionner
      </label>

      <ToggleGroup
        className={cn("flex-wrap justify-start gap-3")}
        type="multiple"
        value={value.map((c) => c.name)}
        onValueChange={handleChange}
      >
        {CUSTOM_LAB_COLORS.map((color) => (
          <TooltipProvider key={color.name}>
            <Tooltip>
              <TooltipTrigger>
                <ToggleGroupItem className="group p-0" value={color.name}>
                  {/* color with thumbnail */}
                  {color.thumbnailUrl ? (
                    <div className="relative h-[44px] w-[44px] overflow-hidden rounded duration-200 hover:scale-110">
                      <img
                        className="h-full w-full scale-[2] object-cover"
                        src={color.thumbnailUrl}
                        alt={color.name}
                      />
                      <div
                        className={cn(
                          "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ",
                          "opacity-0 group-radix-state-on:opacity-100",
                        )}
                      >
                        <Check color={whiteOrBlack(color.color)} />
                      </div>
                    </div>
                  ) : (
                    // no thumbnail
                    <div
                      className="relative h-[44px] w-[44px] rounded duration-200 hover:scale-110"
                      style={{ backgroundColor: color.color }}
                    >
                      <div
                        className={cn(
                          "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ",
                          "opacity-0 group-radix-state-on:opacity-100",
                        )}
                      >
                        <Check color={whiteOrBlack(color.color)} />
                      </div>
                    </div>
                  )}
                </ToggleGroupItem>
              </TooltipTrigger>

              <TooltipPortal>
                <TooltipContent sideOffset={10}>
                  <p>{color.name}</p>
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          </TooltipProvider>
        ))}
      </ToggleGroup>
    </div>
  );
}
